import { Box, Stack, Typography } from "@mui/material";
import { FILE_NAMES } from "@/constants";

export function EmptyTrackingComments() {
  return (
    <Stack alignItems="center">
      <Box
        component="img"
        data-testid="EmptyTrackingCommentLogoId"
        alt="There is no tracking comment posted at the moment."
        src={`${import.meta.env.REACT_APP_AUTH_REDIRECT_URI}/images/${FILE_NAMES.EMPTY_TRACK_COMMENT_BG}`}
        sx={{ width: "18rem", my: "1rem" }}
      />
      <Typography sx={{ fontSize: "1.5rem", lineHeight: "2rem" }}>No tracking comments</Typography>
      <Typography variant="subtitle1" maxWidth="20rem" textAlign="center" my={1.5}>
        Add the relevant Tracking Comments for long lead approvals or reviews
      </Typography>
    </Stack>
  );
}
