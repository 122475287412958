import { BADGE_POSITION } from "@/constants";
import { colours } from "@/theme/colour";
import { Badge, BadgeOrigin, SxProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface UnreadBadgeProps extends PropsWithChildren {
  visible?: boolean;
  badgeContent?: React.ReactNode;
  sx?: SxProps;
  marginTop?: string | number;
  marginLeft?: string | number;
  "data-testid"?: string;
  anchorOverride?: Partial<BadgeOrigin>;
  badgePosition?: string;
}

export function UnreadBadge({
  children,
  visible = true,
  sx,
  marginTop,
  marginLeft,
  badgeContent,
  "data-testid": dataTestId,
  anchorOverride,
  badgePosition = ""
}: UnreadBadgeProps) {
  const badgeMarginLeft = badgePosition === BADGE_POSITION.DISJOINT_CENTER_LEFT ? "-35%" : marginLeft;
  const badgeMarginTop = badgePosition === BADGE_POSITION.DISJOINT_CENTER_LEFT ? "50%" : marginTop;

  return (
    <Badge
      sx={sx}
      variant={badgeContent ? "standard" : "dot"}
      badgeContent={badgeContent}
      invisible={!visible}
      anchorOrigin={{ vertical: "top", horizontal: "left", ...anchorOverride }}
      slotProps={{
        badge: {
          style: {
            marginLeft: badgeMarginLeft,
            marginTop: badgeMarginTop,
            background: colours.unreadNotification,
            color: badgeContent ? "white" : undefined
          },
          // @ts-expect-error: MUI type doesn't expose this prop, but it does propagate through correctly.
          "data-testid": `unread-indicator-${dataTestId}`
        }
      }}
    >
      {children}
    </Badge>
  );
}
