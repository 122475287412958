import { useState } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { AddInterceptResultDecisionRequest, InterceptResultItem } from "@/interfaces";
import { Decision } from "@/constants";
import { useAddInterceptDecision, useGetInterceptListResultAtom } from "@/hooks";
import { useAuthorization } from "@/context";
import { AppFormInput } from "@/components/fields";

export function DetailPanelContent({ row: rowProp, isHistory }: { row: InterceptResultItem; isHistory: boolean }) {
  const { userId, name } = useAuthorization();
  const addInterceptResultDecision = useAddInterceptDecision();
  const [, getInterceptResultList] = useGetInterceptListResultAtom();
  const [isValidInput, setIsValidInput] = useState(false);

  const defaultValues: AddInterceptResultDecisionRequest = {
    id: rowProp.id,
    decision: Decision.Undecided,
    determinationNotes: "",
    decisionById: userId ?? "",
    decisionByName: name ?? ""
  };

  const schema = Yup.object().shape({
    id: Yup.string().required("Id is a required field."),
    decisionById: Yup.string().required("Decision Maker is a required field."),
    decisionByName: Yup.string().required("Decision Maker is a required field."),
    decision: Yup.mixed<Decision>().required("Decision is required"),
    determinationNotes: Yup.string().required("Determination notes is required")
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues
  } = useForm<AddInterceptResultDecisionRequest>({
    defaultValues,
    resolver: yupResolver(schema)
  });

  const handleNoActionDecision = async () => {
    handleSaveDecisionSubmit(Decision.NoAction);
  };

  const handleReviewARDecision = async () => {
    handleSaveDecisionSubmit(Decision.ReviewAR);
  };

  const handleSaveDecisionSubmit = async (decision: Decision) => {
    setValue("decision", decision);
    const request = getValues();
    const isValid = schema.isValidSync(request);
    if (!isValid) return;
    const isSuccess = await addInterceptResultDecision(request);
    if (isSuccess) {
      // Do something to show success
      getInterceptResultList(isHistory);
      reset();
    }
  };

  return (
    <form>
      <Grid container>
        <Grid container margin={2}>
          <Grid item sm={1} md={2} lg={3}></Grid>
          <Grid item sm={10} md={8} lg={6} justifyContent="center">
            {isHistory ? (
              <Typography sx={{ fontSize: 14 }}>{rowProp.determinationNotes}</Typography>
            ) : (
              <>
                <AppFormInput
                  control={control}
                  label={"Determination Notes*"}
                  name={"determinationNotes"}
                  error={errors.determinationNotes}
                  data-testid="determinationNotesInput"
                  showError={false}
                  multiline={true}
                  rows={3}
                  onChangeHandler={() => {
                    const isValid = schema.isValidSync(getValues());
                    setIsValidInput(isValid);
                  }}
                />
                <Typography variant="subtitle1">An explanation of the determination.</Typography>
              </>
            )}
          </Grid>
          <Grid item sm={1} md={2} lg={3}></Grid>
        </Grid>
        <Grid container margin={1} justifyContent="flex-end">
          {isHistory ? (
            <></>
          ) : (
            <>
              <Button
                variant="text"
                type="submit"
                disabled={!isValidInput}
                onClick={handleSubmit(handleNoActionDecision)}
              >
                No Action
              </Button>
              <Button
                variant="text"
                type="submit"
                disabled={!isValidInput}
                onClick={handleSubmit(handleReviewARDecision)}
              >
                Review AR
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Divider />
    </form>
  );
}
