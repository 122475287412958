import { useMemo, useState } from "react";
import {
  CSSObject,
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
  styled
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";

import { NavigationLink } from "@/interfaces";
import { colours } from "@/theme/colour";
import { toKebabCase } from "@/utils";
import { UnreadBadge } from "../UnreadBadge";

interface NavigationDrawerProps {
  showToggle: boolean;
  navigationLinks: NavigationLink[];
  paddingTop?: number;
}

const drawerExpandWidth = "18rem";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerExpandWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  width: drawerExpandWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const ListItemButton = styled(MuiListItemButton)(({ theme, selected }) => ({
  "& .MuiListItemText-primary": {
    fontSize: "1rem",
    fontWeight: 500,
    color: selected ? theme.palette.primary.main : colours.icon
  },

  "& .MuiListItemIcon-root": {
    color: selected ? theme.palette.primary.main : colours.icon
  }
}));

export const NavigationDrawer = ({ showToggle, navigationLinks, paddingTop }: NavigationDrawerProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(!showToggle);

  const listItemStyled: SxProps = {
    display: "block"
  };

  const listItemButtonStyled: SxProps = {
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5
  };

  const listItemIconStyled: SxProps = {
    justifyContent: "center"
  };

  const listItemTextStyled: SxProps = {
    opacity: open ? 1 : 0
  };

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const handleListItemClick = (path: string) => {
    navigate(path);
  };

  const navigationItems = useMemo(
    () => navigationLinks.sort((a, b) => a.displayOrder - b.displayOrder),
    [navigationLinks]
  );

  return (
    <Drawer id="side-navigation-drawer" variant="permanent" open={open}>
      <DrawerHeader sx={paddingTop ? { paddingTop } : {}} />
      <List>
        {showToggle ? (
          <>
            <ListItem
              onClick={toggleDrawer}
              key="drawer-toggle"
              disablePadding
              sx={listItemStyled}
              data-testid="nav-drawer-toggle"
            >
              <ListItemButton sx={listItemButtonStyled}>
                <ListItemIcon sx={listItemIconStyled}>
                  <MenuIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ) : null}
        {navigationItems.map(({ path, label, icon, badge, badgePosition, disabled, hidden }) =>
          !hidden ? (
            <ListItem key={label} disablePadding sx={listItemStyled} data-testid={`nav-option-${toKebabCase(label)}`}>
              <ListItemButton
                sx={listItemButtonStyled}
                selected={path === location.pathname}
                disabled={disabled}
                onClick={() => {
                  handleListItemClick(path);
                }}
              >
                <Tooltip title={label}>
                  <ListItemIcon sx={listItemIconStyled}>
                    <UnreadBadge visible={badge ?? false} badgePosition={badgePosition}>
                      {icon}
                    </UnreadBadge>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={label} sx={listItemTextStyled} />
              </ListItemButton>
            </ListItem>
          ) : null
        )}
      </List>
    </Drawer>
  );
};
