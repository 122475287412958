import {
  createContext,
  Dispatch,
  PropsWithChildren,
  KeyboardEvent,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useState
} from "react";

import { Commentary } from "@/interfaces";
import {
  useAddCommentary,
  useDeleteCommentary,
  useGetCommentary,
  useMarkNotificationsAsRead,
  useUpdateCommentary
} from "@/hooks";
import { Loading } from "@/components";
import { useARContext } from "./ApprovalRequestContextProvider";
import styled, { StyledComponent } from "@emotion/styled";
import {
  Button,
  Card,
  CardProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { COLLAB_DELETE_COMMENT_MODAL, COLLAB_DELETE_COMMENT_REPLIES_MODAL } from "@/constants";
import { RichTextEditorRef } from "@/components/rich-text-editor/RichTextEditor";
import _ from "lodash";

const CollaborationCard = styled(Card)`
  max-width: 100%;
  border: none;
  box-shadow: none;
  padding: 0rem;

  .MuiCardHeader-root {
    padding: 1rem 0rem 0rem 0rem;
  }

  .MuiCardHeader-avatar {
    width: 4rem;
    height: 2.625rem;
    align-self: start;
    padding: 0rem;
    display: block;
  }

  .MuiCardHeader-title {
    margin: -1.5rem 0rem -1.5rem 0rem;
  }

  .MuiCardContent-root {
    margin-top: -1.5rem;
    padding: 0rem 1rem 0rem 5rem;
  }
`;

export interface CollaborationContextType {
  CollaborationCard: StyledComponent<CardProps>;
  commentaries: Commentary[];
  commentLayoutClassName: string;
  unreadTargetClassName: string;
  addComment: (comment: string, parentCommentaryId?: string) => void;
  updateComment: (id: string, comment: string) => void;
  handleClearContent: (editorRef: RichTextEditorRef | null) => void;
  handleOnBlur: (editorRef: RichTextEditorRef | null) => void;
  handleEscapeKeyPressed: (event: KeyboardEvent<HTMLDivElement>, editorRef: RichTextEditorRef | null) => void;
  resetCommentBox: () => void;
  setSelectedCommentary: Dispatch<SetStateAction<Commentary | null>>;
  focusCommentary: Commentary | null;
  setFocusCommentary: Dispatch<SetStateAction<Commentary | null>>;
  commentBoxProps: CommentBoxProps;
  setCommentBoxProps: Dispatch<SetStateAction<CommentBoxProps>>;
  unreadReplyComments: Record<Commentary["id"], Commentary["id"][]>;
  addTargetsToIntersectionObserver: () => void;
}

export const CollaborationContext = createContext<CollaborationContextType | undefined>(undefined);

export const useCollaborationContext = () => {
  const context = useContext(CollaborationContext);

  if (context === undefined) {
    throw new Error("Cannot use 'CollaborationContext' without a 'CollaborationContextProvider'.");
  }

  return context;
};

interface CommentBoxProps {
  showMenuBar: boolean;
  enableReplyEditor: boolean;
  enableSaveButton: boolean;
  enableUpdateEditor: boolean;
}

export function CollaborationProvider({ children }: PropsWithChildren) {
  const { approvalRequestId } = useARContext();

  const handleClearContent = (editorRef: RichTextEditorRef | null) => {
    editorRef?.clearContent();
    editorRef?.blur();
  };

  const { data: commentaries } = useGetCommentary(approvalRequestId);
  const { mutate: addCommentary } = useAddCommentary(approvalRequestId);
  const { mutate: updateCommentary } = useUpdateCommentary(approvalRequestId);
  const { mutate: deleteCommentary } = useDeleteCommentary(approvalRequestId);
  const [observer, setObserver] = useState<IntersectionObserver>();
  const commentsLayoutClassName = "comments-layout";
  const unreadTargetClassName = "unread-commentary";
  const { markRead } = useMarkNotificationsAsRead(approvalRequestId);

  const [selectedCommentary, setSelectedCommentary] = useState<Commentary | null>(null);
  const [focusCommentary, setFocusCommentary] = useState<Commentary | null>(null);

  const [commentBoxProps, setCommentBoxProps] = useState<CommentBoxProps>({
    showMenuBar: false,
    enableUpdateEditor: false,
    enableReplyEditor: false,
    enableSaveButton: false
  });

  const [unreadReplyComments, setUnreadReplyComments] = useState<Record<Commentary["id"], Commentary["id"][]>>({});

  useEffect(() => {
    const allCommentaries: Commentary[] = [];
    _.each(commentaries, (comment) => {
      allCommentaries.push(...comment.replyComments);
    });

    const indexedUnreadReplyComments: Record<Commentary["id"], Commentary["id"][]> = {};
    allCommentaries
      .filter((comment) => comment.parentCommentaryId !== undefined && comment.isUnread)
      .forEach((comment) => {
        if (indexedUnreadReplyComments[comment.parentCommentaryId!] === undefined)
          indexedUnreadReplyComments[comment.parentCommentaryId!] = [comment.id];
        else
          indexedUnreadReplyComments[comment.parentCommentaryId!] = [
            ...indexedUnreadReplyComments[comment.parentCommentaryId!],
            comment.id
          ];
      });
    setUnreadReplyComments(indexedUnreadReplyComments);
  }, [commentaries]);

  const observerOption = {
    root: document.querySelector(`.${commentsLayoutClassName}`),
    rootMargin: "0px",
    threshold: 1,
    triggerOnce: true,
    initialInView: true,
    trackVisibility: true,
    delay: 2000
  };

  useEffect(() => {
    const readNotificationIds: string[] = [];
    const obs = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          readNotificationIds.push(entry.target.id);
        }
      });
    }, observerOption);

    setObserver(obs);

    const handleBeforeUnload = () => {
      if (obs) {
        obs.disconnect();
        markCommentsRead(readNotificationIds);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      obs.disconnect();
      markCommentsRead(readNotificationIds);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [commentaries]);

  useEffect(() => addTargetsToIntersectionObserver(), [observer]);

  function addTargetsToIntersectionObserver() {
    const targets = document.querySelectorAll(`.${unreadTargetClassName}`);
    targets.forEach((target) => observer?.observe(target));

    return () => {
      targets.forEach((target) => observer?.unobserve(target));
    };
  }

  function markCommentsRead(readNotificationIds: string[]) {
    if (readNotificationIds.length === 0) return;
    const notificationIds: string[] = Array.from(new Set(readNotificationIds));
    if (notificationIds.length > 0)
      markRead({
        notificationIds,
        isDismissUnread: true,
        isDismissInApp: true,
        isDismissActionable: false,
        isDismissRealTime: false
      });
  }

  const handleOnBlur = (editorRef: RichTextEditorRef | null) => {
    if ((editorRef?.getContent(false).length ?? 0) === 0) {
      setCommentBoxProps({ ...commentBoxProps, showMenuBar: false });
    }
  };

  const handleEscapeKeyPressed = (event: KeyboardEvent<HTMLDivElement>, editorRef: RichTextEditorRef | null) => {
    if (event.key === "Escape") {
      handleClearContent(editorRef);
    }
  };

  const resetCommentBox = () => {
    setCommentBoxProps({
      showMenuBar: false,
      enableUpdateEditor: false,
      enableReplyEditor: false,
      enableSaveButton: false
    });
    setFocusCommentary(null);
  };

  const deleteCommentaryDialog = () => {
    if (selectedCommentary === null) return <></>;

    const { TITLE, MESSAGE } =
      selectedCommentary.replyComments.length > 0 ? COLLAB_DELETE_COMMENT_REPLIES_MODAL : COLLAB_DELETE_COMMENT_MODAL;

    const handleDelete = (callback?: () => void) => {
      callback?.();
      setSelectedCommentary(null);
    };

    return (
      <Dialog
        open={selectedCommentary !== null}
        onClose={() => handleDelete()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{MESSAGE}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDelete()}>Cancel</Button>
          <Button
            onClick={() =>
              handleDelete(() => {
                deleteCommentary(selectedCommentary.id);
              })
            }
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const addComment = (comment: string, parentCommentaryId?: string) => {
    addCommentary({ comment: comment, parentCommentaryId: parentCommentaryId });
  };

  const updateComment = (commentaryId: string, comment: string) => {
    updateCommentary({ commentaryId: commentaryId, comment: comment });
  };

  const contextValue: CollaborationContextType = {
    CollaborationCard: CollaborationCard,
    commentaries: commentaries,
    commentLayoutClassName: commentsLayoutClassName,
    unreadTargetClassName: unreadTargetClassName,
    setSelectedCommentary: setSelectedCommentary,
    handleClearContent: handleClearContent,
    handleOnBlur: handleOnBlur,
    handleEscapeKeyPressed: handleEscapeKeyPressed,
    resetCommentBox: resetCommentBox,
    addComment: addComment,
    updateComment: updateComment,
    focusCommentary: focusCommentary,
    setFocusCommentary: setFocusCommentary,
    commentBoxProps: commentBoxProps,
    setCommentBoxProps: setCommentBoxProps,
    unreadReplyComments: unreadReplyComments,
    addTargetsToIntersectionObserver: addTargetsToIntersectionObserver
  };

  return (
    <Suspense fallback={<Loading prefix="Collaboration" />}>
      {deleteCommentaryDialog()}
      <CollaborationContext.Provider value={contextValue}>{children}</CollaborationContext.Provider>
    </Suspense>
  );
}
