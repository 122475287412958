import { useState } from "react";
import { Grid } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";

import { ApprovalRequestStatus } from "@/interfaces";
import { useARFilterOptions } from "@/hooks";
import { DashboardSelectWithGrid } from "@/components/shared";

export interface ARFilterQuery {
  selectedHub: string;
  selectedSite: string;
  selectedProject: string;
}

interface ARFilterToolbarProps {
  onFilterChange: (filterQuery: ARFilterQuery) => void;
  approvalRequestStatusFilter: ApprovalRequestStatus[];
}

export function ARFilterToolbar({ onFilterChange, approvalRequestStatusFilter }: ARFilterToolbarProps) {
  const [selectedHub, setSelectedHub] = useState<string>("all");
  const [selectedSite, setSelectedSite] = useState<string>("all");
  const [selectedProject, setSelectedProject] = useState<string>("all");

  const { data: filterOptions, isSuccess } = useARFilterOptions(
    selectedSite,
    selectedHub,
    selectedProject,
    approvalRequestStatusFilter
  );

  const handleFilterChange = (name: keyof ARFilterQuery, value: string) => {
    const newFilter = {
      selectedHub,
      selectedSite,
      selectedProject
    };

    newFilter[name] = value;

    onFilterChange(newFilter);
  };

  return (
    <GridToolbarContainer>
      <Grid container spacing={3} paddingBottom={5}>
        <DashboardSelectWithGrid
          id="hubId"
          label="Hub"
          options={isSuccess ? filterOptions.hubFilterOptionList : []}
          value={selectedHub}
          onChange={(event) => {
            setSelectedHub(event.target.value);
            handleFilterChange("selectedHub", event.target.value);
          }}
          data-testid="hub-filter-select"
        />

        <DashboardSelectWithGrid
          id="siteId"
          label="Site"
          options={isSuccess ? filterOptions.siteFilterOptionList : []}
          value={selectedSite}
          onChange={(event) => {
            setSelectedSite(event.target.value);
            handleFilterChange("selectedSite", event.target.value);
          }}
          data-testid="site-filter-select"
        />
        <DashboardSelectWithGrid
          id="projectId"
          label="Project"
          options={isSuccess ? filterOptions.projectFilterOptionList : []}
          value={selectedProject}
          onChange={(event) => {
            setSelectedProject(event.target.value);
            handleFilterChange("selectedProject", event.target.value);
          }}
          data-testid="project-filter-select"
        />
      </Grid>
    </GridToolbarContainer>
  );
}
