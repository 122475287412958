import { RouteObject } from "react-router-dom";
import { Typography } from "@mui/material";

import { PATH, ADMIN_SETTINGS_PATH } from "@/constants";
import { AdminSettingsLayout, NavigationLayout } from "@/layouts";
import { ArcGISServiceOverviewPage } from "@/features/admin/components/ArcGISServiceOverviewPage";
import { InterceptionAnalysisConfigurationItem } from "@/features/admin/components/InterceptionAnalysisConfigurationItem";
import { UserHubMatrixConfiguration } from "@/features/admin/components/UserHubMatrixConfiguration";
import HubsConfigurationItem from "@/features/admin/components/HubsConfigurationItem";
import { UserHubMatrixItems } from "@/features/admin/components/UserHubMatrixItems";

export const AdminSettingsNestedRoutes: RouteObject = {
  path: PATH.ADMIN_SETTINGS,
  element: <AdminSettingsLayout />,
  children: [
    {
      path: ADMIN_SETTINGS_PATH.HUBS_AND_SITES,
      element: <HubsConfigurationItem />
    },
    {
      path: ADMIN_SETTINGS_PATH.AR_OWNERS,
      element: <Typography>AR Owners</Typography>
    },
    {
      path: ADMIN_SETTINGS_PATH.ROLES_AND_PERMISSIONS,
      element: <Typography>Roles and Permissions</Typography>
    },
    {
      path: ADMIN_SETTINGS_PATH.NOTIFICATIONS,
      element: <Typography>Notifications</Typography>
    },
    {
      path: ADMIN_SETTINGS_PATH.SENSITIVITY_ANALYSIS_RULES,
      element: <Typography>Sensitivity Analysis Rules</Typography>
    },
    {
      path: ADMIN_SETTINGS_PATH.INTERCEPTION_ANALYSIS,
      element: <InterceptionAnalysisConfigurationItem />
    },
    {
      path: ADMIN_SETTINGS_PATH.ARCGIS_SERVICES,
      element: <ArcGISServiceOverviewPage />
    },
    {
      path: ADMIN_SETTINGS_PATH.USER_MATRIX,
      element: <UserHubMatrixConfiguration />,
      children: [
        {
          path: ADMIN_SETTINGS_PATH.USER_HUB_MATRIX,
          element: <UserHubMatrixItems />
        }
      ]
    }
  ]
};

export const AdminSettingsRoutes: RouteObject = {
  path: PATH.ADMIN_SETTINGS,
  element: <NavigationLayout />,
  children: [AdminSettingsNestedRoutes]
};
