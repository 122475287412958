import { ChangeEvent } from "react";
import { BaseTextFieldProps, Box, TextField } from "@mui/material";

import { DisciplineAuthorisationCommentValue } from "@/interfaces";

interface ConfigurableTextFieldProps extends BaseTextFieldProps {
  configuration: DisciplineAuthorisationCommentValue;
  onUpdate: (update: DisciplineAuthorisationCommentValue) => void;
}
export function ConfigurableTextField({ configuration, onUpdate, ...textFieldProps }: ConfigurableTextFieldProps) {
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onUpdate({
      ...configuration,
      value: event.target.value
    });
  };
  return (
    <Box sx={{ ml: 4 }}>
      <TextField
        defaultValue={configuration.value}
        onChange={onChangeHandler}
        {...textFieldProps}
        size="small"
        fullWidth
        inputProps={{
          "data-testid": `auth-comment-config-${configuration.authorisationCommentField.name}-${configuration.id}`
        }}
      />
    </Box>
  );
}
