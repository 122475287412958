import { RefObject, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Stack, Typography } from "@mui/material";

import {
  ARBasicDetails,
  ARDiscipline,
  DistributeARRequest,
  DistributionType,
  RedistributeARFormFields
} from "@/interfaces";
import { colours } from "@/theme/colour";
import { useDistributeApprovalRequest } from "@/hooks/api/ApprovalRequestHooks";
import { Dialog } from "@/components/dialogs";
import { AppFormInput, AppFormCheckbox } from "@/components/fields";
import { MutateOptions } from "@tanstack/react-query";

interface RedistributeAmendARFormProps {
  approvalRequestId: string;
  dialogOpen: boolean;
  dismissDialog: () => void;
  approvalRequestDisciplines: ARDiscipline[];
  arBasicDetailsValues: RefObject<Partial<ARBasicDetails>>;
  onDistributeMutation: MutateOptions<void, unknown, DistributeARRequest, unknown>;
}

export function RedistributeAmendARForm({
  approvalRequestId,
  dialogOpen,
  dismissDialog,
  approvalRequestDisciplines,
  arBasicDetailsValues,
  onDistributeMutation
}: RedistributeAmendARFormProps) {
  const { control, watch, reset, getValues } = useForm<RedistributeARFormFields>({
    defaultValues: {
      disciplinesToUnauthorise: [],
      reason: ""
    }
  });

  const { mutate: distributeApprovalRequest, isLoading: distributionInProgress } =
    useDistributeApprovalRequest(approvalRequestId);

  const [disciplinesToUnauthorise, reason] = watch(["disciplinesToUnauthorise", "reason"]);

  const distributeButtonDisabled = useMemo(() => {
    if (disciplinesToUnauthorise.length === 0 && reason.trim() === "") {
      return true;
    }

    return false;
  }, [reason, disciplinesToUnauthorise.length]);

  const authorisedDisciplines = approvalRequestDisciplines;

  const reasonFieldRequired = useMemo(() => disciplinesToUnauthorise.length === 0, [disciplinesToUnauthorise.length]);

  const disciplineOptions = useMemo(
    () => authorisedDisciplines.map(({ id, name }) => ({ id, value: name })),
    [authorisedDisciplines]
  );

  const handleDialogClose = () => {
    dismissDialog();
    reset();
  };

  const handleDistributeClick = () => {
    const formValues = getValues();

    distributeApprovalRequest(
      {
        distributionType: DistributionType.Review,
        disciplinesToUnauthorise: formValues.disciplinesToUnauthorise,
        reason: formValues.reason,
        ...arBasicDetailsValues.current
      },
      onDistributeMutation
    );

    reset();
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      title={"Distribute"}
      actions={[
        {
          label: "Cancel",
          onClick: handleDialogClose
        },
        {
          label: "Distribute",
          disabled: distributeButtonDisabled,
          onClick: handleDistributeClick,
          isFormSubmit: true,
          isLoading: distributionInProgress
        }
      ]}
    >
      <Stack rowGap={2}>
        <Typography color={colours.dialog.subtextColour}>Which disciplines require review?</Typography>
        <AppFormCheckbox
          name={"disciplinesToUnauthorise"}
          control={control}
          options={disciplineOptions}
          data-testid="checkBoxInput"
        />
        <AppFormInput
          margin={"normal"}
          control={control}
          label={"Reason"}
          name={"reason"}
          required={reasonFieldRequired}
          data-testid="reason-input"
          multiline={true}
          rows={3}
          maxLength={500}
        />
      </Stack>
    </Dialog>
  );
}
