import { createContext, Dispatch, PropsWithChildren, SetStateAction, Suspense, useContext, useState } from "react";

import { AttachmentLink, AttachmentLinkModalMode } from "@/interfaces";
import { useAddAttachmentLink, useAttachmentLinks, useDeleteAttachmentLink, useUpdateAttachmentLink } from "@/hooks";
import { Loading } from "@/components";
import { useARContext } from "./ApprovalRequestContextProvider";
import { LINK_MESSAGE } from "@/constants";
import { snackBarAtom } from "@/stores";
import { useSetAtom } from "jotai";

type AttachmentLinkModalConfig = {
  mode: AttachmentLinkModalMode;
  modalIsOpen: boolean;
} & (
  | {
      mode: "Add";
    }
  | {
      mode: "Edit";
      attachmentLinkInEdit: AttachmentLink;
    }
);

export interface AttachmentLinkContextType {
  attachmentLinks: AttachmentLink[];
  addAttachmentLink: (name: string, url: string) => void;
  updateAttachmentLink: (attachmentLink: AttachmentLink) => void;
  deleteAttachmentLink: (id: string) => void;
  attachmentLinkModalConfig: AttachmentLinkModalConfig;
  setAttachmentLinkModalConfig: Dispatch<SetStateAction<AttachmentLinkContextType["attachmentLinkModalConfig"]>>;
}

const DEFAULT_ATTACHMENT_LINK_MODAL_CONFIG: AttachmentLinkModalConfig = {
  mode: "Add",
  modalIsOpen: false
};

export const AttachmentLinkContext = createContext<AttachmentLinkContextType | undefined>(undefined);

export const useAttachmentLinkContext = () => {
  const context = useContext(AttachmentLinkContext);

  if (context === undefined) {
    throw new Error("Cannot use 'AttachmentLinkContext' without a 'AttachmentLinkContextProvider'.");
  }

  return context;
};

export function AttachmentLinkContextProvider({ children }: PropsWithChildren) {
  const { approvalRequestId } = useARContext();
  const setSnackBar = useSetAtom(snackBarAtom);
  const [attachmentLinkModalConfig, setAttachmentLinkModalConfig] = useState<
    AttachmentLinkContextType["attachmentLinkModalConfig"]
  >(DEFAULT_ATTACHMENT_LINK_MODAL_CONFIG);

  const { data: attachmentLinks } = useAttachmentLinks(approvalRequestId);
  const { mutate: addAttachmentLinkMutation } = useAddAttachmentLink(approvalRequestId, () => {
    setAttachmentLinkModalConfig(DEFAULT_ATTACHMENT_LINK_MODAL_CONFIG);
    setSnackBar({
      message: LINK_MESSAGE.SAVE_SUCCESSFUL,
      open: true
    });
  });
  const { mutate: updateAttachmentLinkMutation } = useUpdateAttachmentLink(approvalRequestId, () => {
    setSnackBar({
      message: LINK_MESSAGE.EDIT_SUCCESSFUL,
      open: true
    });
  });
  const { mutate: deleteAttachmentLink } = useDeleteAttachmentLink(approvalRequestId);

  const addAttachmentLink = (name: string, url: string) => {
    addAttachmentLinkMutation({
      name,
      url,
      approvalRequestId
    });
  };

  const updateAttachmentLink = (attachmentLink: AttachmentLink) => {
    updateAttachmentLinkMutation(attachmentLink);
    setAttachmentLinkModalConfig(DEFAULT_ATTACHMENT_LINK_MODAL_CONFIG);
  };

  const contextValue: AttachmentLinkContextType = {
    attachmentLinks,
    addAttachmentLink,
    updateAttachmentLink,
    deleteAttachmentLink,
    attachmentLinkModalConfig,
    setAttachmentLinkModalConfig
  };

  return (
    <Suspense fallback={<Loading prefix="Attachment Links" />}>
      <AttachmentLinkContext.Provider value={contextValue}>{children}</AttachmentLinkContext.Provider>
    </Suspense>
  );
}
