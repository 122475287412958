import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
  ApprovalRequestStatus,
  FilterOptions,
  FilterOptionsRequest,
  SMEDashboardFilterOptions,
  SMEDashboardFilterOptionsRequest
} from "@/interfaces";
import { getFilterOptions } from "@/services";
import { SME_DASHBOARD_FILTER_OPTIONS_QUERY_KEY } from "@/constants";
import { apiClient } from "@/api/client";

const baseURL = "/reference";

export function useARFilterOptions(
  siteId: string,
  hubId: string,
  projectId: string,
  approvalRequestStatus: ApprovalRequestStatus[]
): UseQueryResult<FilterOptions> {
  const selectedFilterOptions: FilterOptionsRequest = {
    siteId,
    hubId,
    projectId,
    approvalRequestStatus
  };

  return useQuery(["filterOptions", selectedFilterOptions], async () => {
    return await getFilterOptions(selectedFilterOptions);
  });
}

export function useSMEDashboardFilterOptions(request: SMEDashboardFilterOptionsRequest) {
  return useQuery({
    queryKey: [SME_DASHBOARD_FILTER_OPTIONS_QUERY_KEY, request],
    queryFn: () =>
      apiClient
        .get<SMEDashboardFilterOptions>(`${baseURL}/sme-filter-options`, {
          params: { ...request },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    initialData: {
      hubFilterOptionList: [],
      siteFilterOptionList: [],
      typeFilterOptionList: []
    }
  });
}
