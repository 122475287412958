import { useMutation } from "@tanstack/react-query";

import { useARContext } from "@/context";
import { AR_UNEXPECTED_ERROR_BANNER } from "@/constants";
import { ApprovalRequestSensitivityAnalysis, SensitivityGeometryResponse } from "@/interfaces";
import { apiClient } from "@/api/client";

const basePath = "/ApprovalRequestMap";

export function useSensitivityAnalysis() {
  const { appendAlertBanner, removeAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (request: ApprovalRequestSensitivityAnalysis) =>
      apiClient
        .get<SensitivityGeometryResponse>(`${basePath}/${request.approvalRequestId}/sensitivity`, {
          params: {
            approvalRequestShapeId: request.shapeId
          }
        })
        .then((response) => response.data),
    onSuccess: () => {
      removeAlertBanner([AR_UNEXPECTED_ERROR_BANNER.id]);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}
