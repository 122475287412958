import { Alert, AlertTitle, Stack, styled } from "@mui/material";
import { ApprovalRequestStatus } from "@/interfaces";

interface PermitDraftBannerProps {
  approvalRequestStatus: ApprovalRequestStatus;
}

interface PermitDraftWatermarkProps {
  value?: string;
  repeatCount?: number;
}

const PermitWatermarkStyled = styled(Stack)({
  transformOrigin: "0 100%",
  color: "rgba(0, 0, 0, 0.1)",
  position: "absolute",
  textTransform: "uppercase",
  zIndex: 1,
  width: "500%",
  top: "1rem",
  left: "-3rem",
  fontSize: "9em",
  fontWeight: "bold",
  userSelect: "none"
});

const PermitWatermark = ({ value, repeatCount }: PermitDraftWatermarkProps) => (
  <PermitWatermarkStyled data-testid="draft-permit-banner-watermark">
    {`${value} `.repeat(repeatCount ?? 5)}
  </PermitWatermarkStyled>
);

export function PermitDraftBanner({ approvalRequestStatus }: PermitDraftBannerProps) {
  const hiddenPermitBanner = [ApprovalRequestStatus.Completed, ApprovalRequestStatus.PermitIssued];
  return (
    <>
      {!hiddenPermitBanner.includes(approvalRequestStatus) && (
        <>
          <Alert severity="warning" data-testid="draft-permit-banner" sx={{ width: "110%", margin: "-1rem -1.5rem" }}>
            <AlertTitle>Draft Permit</AlertTitle>
          </Alert>
          <PermitWatermark value="Draft" />
        </>
      )}
    </>
  );
}
