import Button from "@mui/material/Button";
import { Icon, IconButton, ButtonGroup as MuiButtonGroup, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ARActionButtonComponentName, ARActionButtonProps, ApprovalRequestStatus } from "@/interfaces";
import { useMemo, useRef, useState } from "react";
import { PartialRecord } from "@/types";

interface ARHeaderButtonGroupProps {
  status: ApprovalRequestStatus;
  buttonGroup: PartialRecord<ApprovalRequestStatus, ARActionButtonProps[]>;
}

export const ARHeaderButtonGroup = ({ status, buttonGroup }: ARHeaderButtonGroupProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const visibleButtons = useMemo(() => {
    return buttonGroup[status]?.filter((x) => !!x.isVisible) ?? [];
  }, [buttonGroup, status]);

  const memoizedIcons = useMemo(() => {
    return visibleButtons.filter((x) => x.component === ARActionButtonComponentName.Icon);
  }, [visibleButtons]);

  const memoizedButtonGroups = useMemo(() => {
    return visibleButtons.filter((x) => x.component === ARActionButtonComponentName.ButtonGroup);
  }, [visibleButtons]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack spacing={2} direction="row">
      {memoizedIcons.length > 0 &&
        memoizedIcons.map((button, index) => {
          if (button.iconName) {
            return (
              <IconButton
                key={button.name}
                disabled={!button.isEnabled}
                color="primary"
                onClick={button.onClick}
                data-testid={memoizedIcons[index].name + "-button"}
              >
                <Icon>{button.iconName}</Icon>
              </IconButton>
            );
          } else {
            return (
              <Button
                key={button.name}
                variant="text"
                disabled={!button.isEnabled}
                onClick={button.onClick}
                data-testid={memoizedIcons[index].name + "-button"}
              >
                {button.name}
              </Button>
            );
          }
        })}
      {memoizedButtonGroups.length > 0 && (
        <>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            disabled={!memoizedButtonGroups[0].isEnabled}
            onClick={memoizedButtonGroups[0].onClick}
            data-testid={memoizedButtonGroups[0].name + "-button"}
          >
            {memoizedButtonGroups[0].name}
          </Button>
          <MuiButtonGroup ref={anchorRef} style={{ marginLeft: 0 }}>
            {memoizedButtonGroups.length > 1 ? (
              <IconButton
                size="small"
                aria-controls={open ? "button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                onClick={handleToggle}
              >
                <MoreVertIcon data-testid="button-kebab-dropdown" />
              </IconButton>
            ) : null}
          </MuiButtonGroup>
          <Popper
            sx={{
              zIndex: 1
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="ar-button-menu" autoFocusItem>
                      {memoizedButtonGroups.map((option, index) =>
                        index === 0 ? null : (
                          <MenuItem
                            key={option.name}
                            disabled={!option.isEnabled}
                            onClick={(event) => {
                              option.onClick(event);
                            }}
                            data-testid={memoizedButtonGroups[index].name + "-button"}
                          >
                            {option.name}
                          </MenuItem>
                        )
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </Stack>
  );
};
