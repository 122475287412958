import { useEffect, useState } from "react";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { Box, Snackbar, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
  adminTablePageDetailsAtom,
  columnDataAtom,
  selectedRowIdCountAtom,
  selectedRowIdAtom,
  selectedRowIdListAtom,
  editIsActiveAtom,
  adminTableSnackBarAtom
} from "@/stores/adminDataStore";
import { colours } from "@/theme/colour";
import { CustomToolbar } from "./CustomToolbar";

export function AdminTable() {
  //TODO: move it to hooks next iteration
  const [tableDetails, setTableDetails] = useAtom(adminTablePageDetailsAtom);
  const columnData = useAtomValue(columnDataAtom);
  const setSelectedRowIdCount = useSetAtom(selectedRowIdCountAtom);
  const setSelectedRowId = useSetAtom(selectedRowIdAtom);
  const setSelectedRowIdList = useSetAtom(selectedRowIdListAtom);
  const [adminTableSnackBar, setAdminTableSnackBar] = useAtom(adminTableSnackBarAtom);
  const rowData = tableDetails.data;
  const setEditIsActive = useSetAtom(editIsActiveAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setCurrentPage(tableDetails.page - 1);
  }, []);

  useEffect(() => {
    setTableDetails((prevState) => ({
      ...prevState,
      page: currentPage + 1,
      pageSize: pageSize
    }));
  }, [currentPage, pageSize, setTableDetails]);

  const action = (
    <>
      {adminTableSnackBar.handleUndoOnClicked ? (
        <Button color="secondary" size="small" onClick={adminTableSnackBar.handleUndoOnClicked}>
          UNDO
        </Button>
      ) : (
        <></>
      )}

      <IconButton onClick={adminTableSnackBar.handleCloseOnClicked} size="small" aria-label="close" color="inherit">
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <Box sx={{ height: "30rem" }}>
        <DataGrid
          paginationMode="server"
          columnVisibilityModel={{ id: false }}
          data-testid="AdminTableId"
          rows={rowData ?? []}
          components={{ Toolbar: CustomToolbar }}
          columns={columnData}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(ids: GridRowSelectionModel) => {
            setSelectedRowIdCount(0);
            const idsString = ids.toString().split(",");
            if (ids.length > 1) {
              setSelectedRowIdList(idsString);
              setSelectedRowIdCount(ids.length);
            } else if (ids.length === 1) {
              setSelectedRowIdList(idsString);
              setSelectedRowId(ids[0].toString());
              setSelectedRowIdCount(ids.length);
            }
          }}
          paginationModel={{
            page: currentPage,
            pageSize: pageSize
          }}
          loading={tableDetails.isLoading}
          sx={{
            border: 0,
            "& .MuiIconButton-root": {
              color: colours.icon
            },
            "& .MuiIconButton-root.Mui-disabled": {
              color: colours.infoBackground
            }
          }}
          rowCount={tableDetails.total ?? 0}
          onPaginationModelChange={({ page, pageSize }) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
          pageSizeOptions={[5, 10, 25]}
          onRowDoubleClick={(ids, e) => {
            e.preventDefault();
            setSelectedRowId(ids.row.id);
            setEditIsActive(true);
          }}
        />
        <Snackbar
          open={adminTableSnackBar.open}
          message={adminTableSnackBar.message}
          action={adminTableSnackBar.action ?? action}
          autoHideDuration={adminTableSnackBar.hideTime}
          onClose={() => {
            setAdminTableSnackBar({ ...adminTableSnackBar, open: false });
          }}
        />
      </Box>
    </>
  );
}
