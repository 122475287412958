import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, Card } from "@mui/material";

import { ARFormSection } from "@/interfaces";
import { withARAwareReadOnly } from "@/components/hocs";
import { AppFormRichTextInputProps } from "./AppFormProps";
import { RichTextEditor } from "../rich-text-editor/RichTextEditor";
import { HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";

export const AppFormRichTextInput = ({
  name,
  control,
  error,
  showError = true,
  defaultValue,
  readOnly,
  resetError,
  "data-testid": dataTestId,
  formHelperErrorClass,
  isHighlight
}: AppFormRichTextInputProps) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <Card
            variant="outlined"
            className={isHighlight ? HIGHLIGHT_FIELD_CLASS_NAMES.BORDER : ""}
            sx={{ borderColor: error && showError ? "error.main" : "primary" }}
          >
            <RichTextEditor
              onChange={(value) => {
                resetError?.();
                onChange(value.getHTML());
              }}
              defaultValue={defaultValue}
              readOnly={readOnly}
              placeholder="Write a description here..."
              data-testid={dataTestId}
            />
          </Card>
        )}
      />
      <FormHelperText error sx={{ marginBottom: 1 }} className={error ? formHelperErrorClass : undefined}>
        {error?.message && showError ? error.message : " "}
      </FormHelperText>
    </FormControl>
  );
};

export const ARAwareReadOnlyAppFormRichTextInput = withARAwareReadOnly(ARFormSection.BasicForm, AppFormRichTextInput);
