import { BUTTON_LABELS, DELETE_REQUEST_MODAL, REQUESTOR_MODIFY_MODAL } from "@/constants";
import { ApprovalRequestBase, ApprovalRequestStatus } from "@/interfaces";
import { useEffect, useState } from "react";
import { Dialog } from "./Dialog";

interface ARHeaderDialogProps {
  approvalRequest: ApprovalRequestBase;
  deleteApprovalRequest: () => void;
  modifyOnClickHandler: () => void;
  modifyDialogOpen: boolean;
  deleteDialogOpen: boolean;
}

interface DialogAction {
  label: string;
  onClick: () => void;
}

interface DialogConfig {
  open: boolean;
  title: string;
  content: string;
  actions: DialogAction[];
}

export function ARHeaderDialog({
  approvalRequest,
  deleteApprovalRequest,
  modifyOnClickHandler,
  modifyDialogOpen,
  deleteDialogOpen
}: ARHeaderDialogProps) {
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({
    open: false,
    title: "",
    content: "",
    actions: []
  });

  useEffect(() => {
    if (deleteDialogOpen) {
      setDialogConfig({
        open: true,
        title: DELETE_REQUEST_MODAL.TITLE,
        content: DELETE_REQUEST_MODAL.MESSAGE,
        actions: [
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: () => setDialogConfig((prev) => ({ ...prev, open: false }))
          },
          {
            label: BUTTON_LABELS.DELETE,
            onClick: () => {
              deleteApprovalRequest();
              setDialogConfig((prev) => ({ ...prev, open: false }));
            }
          }
        ]
      });
    }
  }, [deleteApprovalRequest, deleteDialogOpen]);

  const content =
    approvalRequest.returnRequestCount > 0 &&
    approvalRequest.approvalRequestStatus === ApprovalRequestStatus.Distributed
      ? REQUESTOR_MODIFY_MODAL.RETURN_PENDING_MESSAGE
      : REQUESTOR_MODIFY_MODAL.NO_RETURN_MESSAGE;

  useEffect(() => {
    if (modifyDialogOpen) {
      setDialogConfig({
        open: true,
        title: REQUESTOR_MODIFY_MODAL.TITLE,
        content: content,
        actions: [
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: () => setDialogConfig((prev) => ({ ...prev, open: false }))
          },
          {
            label: BUTTON_LABELS.MODIFY,
            onClick: () => {
              modifyOnClickHandler();
              setDialogConfig((prev) => ({ ...prev, open: false }));
            }
          }
        ]
      });
    }
  }, [modifyDialogOpen, approvalRequest, modifyOnClickHandler, content]);

  return (
    <Dialog
      open={dialogConfig.open}
      onClose={() => setDialogConfig((prev) => ({ ...prev, open: false }))}
      title={dialogConfig.title}
      content={dialogConfig.content}
      actions={dialogConfig.actions}
    />
  );
}
