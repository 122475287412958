export const UNSAVED_CHANGES_MODAL = {
  TITLE: "Save Changes?",
  MESSAGE: "You are leaving this page and have made changes that are not yet saved.",
  DISMISS_BUTTON: "Discard",
  CONFIRM_BUTTON: "Save"
};

export const UPLOAD_IN_PROGRESS_MODAL = {
  TITLE: "Upload in progress.",
  MESSAGE: "Please wait until all attachments have finished uploading."
};

export const DISCIPLINE_UNSAVED_CHANGES_MODAL = {
  TITLE: "Save Changes?",
  MESSAGE: "You are leaving this area and have made changes that are not yet saved.",
  DISMISS_BUTTON: "Discard",
  CONFIRM_BUTTON: "Save"
};

export const COLLAB_DELETE_COMMENT_MODAL = {
  TITLE: "Delete Message?",
  MESSAGE: "The message cannot be recovered once deleted."
};

export const COLLAB_DELETE_COMMENT_REPLIES_MODAL = {
  TITLE: "Delete Message and Replies?",
  MESSAGE: "The message and all replies will be deleted. They cannot be recovered once deleted."
};

export const DELETE_REQUEST_MODAL = {
  TITLE: "Delete Request?",
  MESSAGE: "The request cannot be recovered once deleted."
};

export const REQUESTOR_MODIFY_MODAL = {
  TITLE: "Modify Request?",
  RETURN_PENDING_MESSAGE:
    "There is a pending return request raised by the SME. To accept the request without further Coordinator review click modify.",
  NO_RETURN_MESSAGE: "The review of the request will stop and the request will return to a draft state."
};
