import { Card, CardHeader, CardContent } from "@mui/material";
import React from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { InterceptLayers } from "../InterceptLayers";
import { interceptionAnalysisTabAtom } from "../../../../stores/adminDataStore";
import { useAtom } from "jotai";
import { ARCSLayers } from "../ARCSLayers";

export interface ConfigurationItemCardProps {
  title: string;
  subheader: string;
}

export function InterceptConfigurationItemCardWithTab(props: ConfigurationItemCardProps) {
  const [tabValue, setTabValue] = useAtom(interceptionAnalysisTabAtom);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader title={props.title} subheader={props.subheader} />
      <CardContent>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Intercept Layers" value="1" />
              <Tab label="ARCS Layer" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <InterceptLayers />
          </TabPanel>
          <TabPanel value="2">
            <ARCSLayers />
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
  );
}
