import { NumericTextField } from "@/components/fields";
import { FormHelperText, Stack, SxProps } from "@mui/material";
import { InputHTMLAttributes } from "react";
import { QuestionAnswerProps } from "./QuestionComponent";

const _MAX_LENGTH = 7;
const _UNIT_AREA = "ha";
const subQuestionStyle: SxProps = {
  maxWidth: 120,
  ml: 6
};

export function QuestionNumericText({
  questionAnswer,
  readOnly,
  handleQuestionAnswerChange,
  formHelperErrorClass,
  error
}: QuestionAnswerProps) {
  return (
    <Stack direction="column" sx={{ display: "flex", p: 1 }}>
      <NumericTextField
        key={`${questionAnswer.questionId}`}
        id={`${questionAnswer.questionId}`}
        inputProps={
          {
            "data-testid": `numericTextField-question-${questionAnswer.questionId}`
          } as InputHTMLAttributes<HTMLInputElement>
        }
        label={questionAnswer.question}
        maxLength={_MAX_LENGTH}
        unit={_UNIT_AREA}
        defaultText={
          questionAnswer.answer.trim() === "" ? (questionAnswer.defaultValue ?? "0.0") : questionAnswer.answer
        }
        isReadOnly={readOnly}
        sx={subQuestionStyle}
        onBlurSubmit={(value) => handleQuestionAnswerChange(questionAnswer, value)}
      />
      <FormHelperText error sx={{ marginBottom: 1, ml: 6 }} className={formHelperErrorClass}>
        {error}
      </FormHelperText>
      <FormHelperText sx={{ marginBottom: 1, ml: 6 }}>{questionAnswer.helpText}</FormHelperText>
    </Stack>
  );
}
