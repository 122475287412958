import { DisciplineAuthorisationCommentValue, IOption } from "@/interfaces";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled
} from "@mui/material";
import { useMemo, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = -50;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontWeight: "bold"
}));
interface ConfigurableChipArrayProps {
  label: string;
  disabled: boolean;
  configuration: DisciplineAuthorisationCommentValue;
  onUpdate: (update: DisciplineAuthorisationCommentValue) => void;
  options: IOption[];
}
export function ConfigurableChipArray({
  label,
  configuration,
  options,
  disabled,
  onUpdate
}: ConfigurableChipArrayProps) {
  const [selectedChips, setSelectedChips] = useState<IOption[]>(
    configuration.value ? (JSON.parse(configuration.value) as IOption[]) : []
  );

  const memoizedMenuOptions: IOption[] = useMemo(() => {
    return options.filter((option) => !selectedChips.some((excluded) => excluded.id === option.id));
  }, [options, selectedChips]);

  const handleChange = (event: SelectChangeEvent<IOption[]>) => {
    event.preventDefault();
    const updatedValue = (event.target.value as IOption[]).sort((a, b) => a.value.localeCompare(b.value));
    onUpdate({
      ...configuration,
      value: JSON.stringify(updatedValue)
    });
    setSelectedChips(updatedValue);
  };

  const handleDelete = (deletedChip: IOption) => (event: MouseEvent) => {
    event.preventDefault();
    const updatedSelectedChips = selectedChips.filter((ec) => ec !== deletedChip);
    onUpdate({
      ...configuration,
      value: JSON.stringify(updatedSelectedChips)
    });
    setSelectedChips(updatedSelectedChips);
  };
  return (
    <Stack direction="row" alignItems="center" sx={{ width: "95%" }}>
      <FormControl
        sx={{ width: "100%" }}
        disabled={disabled}
        onClick={(event) => {
          event.preventDefault();
        }}
      >
        <InputLabel id="authcomment-multiple-chip-label">{label}</InputLabel>
        <Select
          label={label}
          labelId="authcomment-multiple-chip-label"
          id={`auth-comment-config-${configuration.authorisationCommentField.name}-${configuration.id}`}
          multiple
          value={selectedChips as IOption[]}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  listStyle: "none"
                }}
              >
                {selected.map((option) => {
                  return (
                    <ListItem key={option.id} value={option.value}>
                      <Chip
                        size="medium"
                        label={option.value}
                        onDelete={disabled ? () => {} : handleDelete(option)}
                        disabled={disabled}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        deleteIcon={<CancelIcon data-testid={`cancel-option-${option.value}`} />}
                      />
                    </ListItem>
                  );
                })}
              </Box>
            );
          }}
          MenuProps={MenuProps}
          data-testid={`auth-comment-config-${configuration.authorisationCommentField.name}-${configuration.id}`}
        >
          {memoizedMenuOptions.map((option) => (
            <MenuItem key={option.id} value={option}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
