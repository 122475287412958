import * as yup from "yup";

import { ATTACHMENT_LINK_ERRORS } from "@/constants";

export const attachmentLinkNameSchema = yup.object({
  name: yup.string().trim().required(ATTACHMENT_LINK_ERRORS.MANDATORY_FIELD)
});

export const attachmentLinkUrlSchema = yup.object({
  url: yup.string().required(ATTACHMENT_LINK_ERRORS.MANDATORY_FIELD).url(ATTACHMENT_LINK_ERRORS.INVALID_URL)
});

export const attachmentLinkSchema = attachmentLinkNameSchema.concat(attachmentLinkUrlSchema);
