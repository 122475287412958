import { msalInstance } from "@/authentication";
import { useEffect } from "react";

export function UnauthenticatedComponent() {
  useEffect(() => {
    const scopes = import.meta.env.REACT_APP_AUTH_SCOPES?.split(",") || [];
    msalInstance.loginRedirect({ scopes });
  }, []);

  return <></>;
}
