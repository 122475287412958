import { Fragment, useCallback, useEffect, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { Button, Card, Modal, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  ARCGIS_LAYER_TYPES,
  FIRST_PAGE,
  INTERCEPTION_ANALYSIS_COLUMNS,
  INTERCEPT_RULE_LAYER_TYPE_OPTIONS,
  LAYER_STATUS_OPTIONS,
  MAX_PAGE_SIZE
} from "@/constants";
import { AddInterceptRule, ArcGISServiceItem, InterceptRuleFormInput, IOption } from "@/interfaces";
import {
  getPaginatedInterceptRuleList,
  addInterceptRule,
  getArcGisServices,
  getArcGisServiceById,
  getArcGisServiceDetailsByUrl
} from "@/services";
import { convertToGridRows, convertToIOOption } from "@/utils";
import {
  addIsActiveAtom,
  adminTablePageDetailsAtom,
  adminTableSnackBarAtom,
  arcgisServiceOptionsAtom,
  columnDataAtom,
  disableIsActiveAtom,
  editIsActiveAtom,
  enableIsActiveAtom
} from "@/stores";
import { AppFormSelect, AppFormInput } from "@/components/fields";
import { interceptLayersSchema } from "@/validations";
import { AdminTable } from "./shared/AdminTable";

export const InterceptLayers = () => {
  const setColumn = useSetAtom(columnDataAtom);
  const [addIsActive, setAddIsActive] = useAtom(addIsActiveAtom);
  const [editIsActive, setEditIsActive] = useAtom(editIsActiveAtom);
  const setEnableIsActive = useSetAtom(enableIsActiveAtom);
  const setDisableIsActive = useSetAtom(disableIsActiveAtom);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [adminTablePageDetails, setAdminTablePageDetails] = useAtom(adminTablePageDetailsAtom);
  const resetAdminTablePageDetails = useResetAtom(adminTablePageDetailsAtom);
  const [arcGISServiceOptions, setArcGISServiceOptions] = useAtom(arcgisServiceOptionsAtom);
  const resetAdminTableSnackBar = useResetAtom(adminTableSnackBarAtom);
  const [selectedServiceLayerOptions, setSelectedServiceLayerOptions] = useState<IOption[]>([]);
  const [queryFilterURL, setQueryFilterURL] = useState("");
  const setAdminTableSnackBar = useSetAtom(adminTableSnackBarAtom);
  const [showTestQuery, setShowTestQuery] = useState(false);

  const defaultValues: InterceptRuleFormInput = {
    arcGISServiceId: "",
    arcGISFilterQuery: "",
    interceptLayerType: -1,
    recordStatus: 1,
    arcGISLayerId: -1
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues
  } = useForm<InterceptRuleFormInput>({
    defaultValues,
    resolver: yupResolver(interceptLayersSchema)
  });

  const updateAdminPageDetails = useCallback(() => {
    setAdminTablePageDetails((old) => ({ ...old, isLoading: true }));
    getPaginatedInterceptRuleList(adminTablePageDetails.page, adminTablePageDetails.pageSize).then((interceptRules) => {
      setAdminTablePageDetails((old) => ({
        ...old,
        isLoading: false,
        data: convertToGridRows(interceptRules),
        total: interceptRules.length,
        page: adminTablePageDetails.page,
        pageSize: adminTablePageDetails.pageSize
      }));
    });
  }, [adminTablePageDetails.page, adminTablePageDetails.pageSize, setAdminTablePageDetails]);

  const getArcGISService = useCallback(() => {
    getArcGisServices(FIRST_PAGE, MAX_PAGE_SIZE).then(({ items }) => {
      setArcGISServiceOptions(convertToIOOption<ArcGISServiceItem>(items));
    });
  }, [setArcGISServiceOptions]);

  useEffect(() => {
    setAddIsActive(false);
    setEditIsActive(false);
    setEnableIsActive(null);
    setDisableIsActive(null);
    setAddIsActive(false);
    setEditIsActive(false);
    setEnableIsActive(null);
    setDisableIsActive(null);
    setColumn(INTERCEPTION_ANALYSIS_COLUMNS);
    resetAdminTablePageDetails();
    getArcGISService();
  }, [
    getArcGISService,
    resetAdminTablePageDetails,
    setAddIsActive,
    setColumn,
    setDisableIsActive,
    setEditIsActive,
    setEnableIsActive
  ]);

  useEffect(() => {
    updateAdminPageDetails();
  }, [updateAdminPageDetails, adminTablePageDetails.page, adminTablePageDetails.pageSize]);

  const getArcGISLayersByServiceId = async (serviceId: string) => {
    if (serviceId === "") {
      return;
    }

    const service = await getArcGisServiceById(serviceId);
    getArcGisServiceDetailsByUrl(service.url).then(({ layers }) => {
      const filteredLayers = layers.filter((layer) => layer.type !== ARCGIS_LAYER_TYPES.GROUP_LAYER);
      if (filteredLayers.length > 0) {
        setSelectedServiceLayerOptions(convertToIOOption(filteredLayers));
      }
    });
  };

  const handleServiceChange = (event: SelectChangeEvent<unknown>): void => {
    const serviceId = (event as SelectChangeEvent).target.value;
    setValue("arcGISLayerId", defaultValues.arcGISLayerId);
    if (serviceId !== "") {
      getArcGISLayersByServiceId(serviceId);
    }
  };

  const closeModal = () => {
    if (editIsActive) {
      setEditIsActive(false);
    } else {
      setAddIsActive(false);
    }
    setErrorMessage("");
    reset();
    setShowTestQuery(false);
    setSelectedServiceLayerOptions([]);
  };

  const openModal = () => {
    if (editIsActive) {
      return editIsActive !== null ? editIsActive : false;
    } else {
      return addIsActive !== null ? addIsActive : false;
    }
  };

  const getTestId = () => {
    if (editIsActive) {
      return "EditInterceptRuleModalId";
    } else {
      return "AddInterceptRuleModalId";
    }
  };

  const handleCloseOnClicked = () => resetAdminTableSnackBar();

  const createInterceptRule = (request: AddInterceptRule) =>
    addInterceptRule(request)
      .then(() => {
        closeModal();
        updateAdminPageDetails();
        reset();
        setAdminTableSnackBar({
          open: true,
          message: "The Intercept Layer has been created!",
          handleCloseOnClicked: handleCloseOnClicked,
          hideTime: 5000
        });
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail.toString());
      });

  const handleInterceptRuleSubmit = () => {
    const interceptRuleFormData = getValues();

    const selectedLayerOption = selectedServiceLayerOptions.find(
      (layerOption) => parseInt(layerOption.id, 10) === interceptRuleFormData.arcGISLayerId
    );

    const layerNameToSubmit = selectedLayerOption !== undefined ? selectedLayerOption.value : "";

    let interceptFilterQueryToSubmit = interceptRuleFormData.arcGISFilterQuery;
    if (interceptFilterQueryToSubmit === undefined) {
      interceptFilterQueryToSubmit = "";
    }

    const interceptRuleDataToSubmit: AddInterceptRule = {
      arcGISServiceId: interceptRuleFormData.arcGISServiceId,
      arcGISLayerName: layerNameToSubmit,
      arcGISFilterQuery: interceptFilterQueryToSubmit,
      interceptLayerType: interceptRuleFormData.interceptLayerType,
      recordStatus: interceptRuleFormData.recordStatus,
      arcGISLayerId: interceptRuleFormData.arcGISLayerId
    };

    createInterceptRule(interceptRuleDataToSubmit);
  };

  const getArcGisQueryURL = () => {
    const { arcGISServiceId, arcGISLayerId, arcGISFilterQuery } = getValues();

    getArcGisServiceById(arcGISServiceId).then(({ url }) => {
      setQueryFilterURL(`${url}/${arcGISLayerId}/query?where=${arcGISFilterQuery}`);
    });
  };

  function updateShowTestQuery() {
    const formData = getValues();
    if (
      formData.arcGISLayerId !== defaultValues.arcGISLayerId &&
      formData.arcGISServiceId !== defaultValues.arcGISServiceId
    ) {
      setShowTestQuery(true);
    } else setShowTestQuery(false);
  }

  return (
    <Fragment>
      <AdminTable />
      <Modal
        open={openModal()}
        onClose={() => {
          closeModal();
        }}
        data-testid={getTestId()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30rem",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 2
          }}
        >
          <form onSubmit={handleSubmit(handleInterceptRuleSubmit)}>
            <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
              {editIsActive ? "Edit Intercept Layer" : "New Intercept Layer"}
            </Typography>

            <AppFormSelect
              control={control}
              label={"Type"}
              name={"interceptLayerType"}
              error={errors.interceptLayerType}
              data-testid="interceptLayerTypeInput"
              showError={true}
              options={INTERCEPT_RULE_LAYER_TYPE_OPTIONS}
            />
            <AppFormSelect
              control={control}
              label={"Status"}
              name={"recordStatus"}
              error={errors.recordStatus}
              data-testid="recordStatusInput"
              showError={true}
              initialValue={defaultValues.recordStatus}
              options={LAYER_STATUS_OPTIONS}
            />
            <AppFormSelect
              control={control}
              label={"ArcGIS Service"}
              name={"arcGISServiceId"}
              onChangeHandler={(event) => {
                handleServiceChange(event);
                getArcGisQueryURL();
              }}
              error={errors.arcGISServiceId}
              data-testid="arcGISServiceIdInput"
              showError={true}
              options={arcGISServiceOptions}
            />
            <AppFormSelect
              control={control}
              label={"Layer"}
              name={"arcGISLayerId"}
              error={errors.arcGISLayerId}
              data-testid="arcGISLayerIdInput"
              onChangeHandler={() => {
                updateShowTestQuery();
                getArcGisQueryURL();
              }}
              showError={true}
              options={selectedServiceLayerOptions}
            />
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <div style={{ width: "22rem", height: "6rem" }}>
                <AppFormInput
                  control={control}
                  label={"ArcGIS Query Filter"}
                  name={"arcGISFilterQuery"}
                  error={errors.arcGISFilterQuery}
                  data-testid="arcGISFilterQueryInput"
                  onChangeHandler={() => {
                    getArcGisQueryURL();
                    setErrorMessage("");
                  }}
                  showError={false}
                  multiline={true}
                  rows={3}
                  maxRows={3}
                />
              </div>
              <div style={{ paddingLeft: "1rem" }}>
                {showTestQuery ? (
                  <Tooltip title="Opens the ArcGIS Query tool for the current ArcGIS Service and Layer in a new tab">
                    <a href={queryFilterURL} target="_blank" rel="noreferrer">
                      <Typography>Test query</Typography>
                    </a>
                  </Tooltip>
                ) : (
                  <Tooltip title="Layer and Service fields are required before opening the ArcGIS Query tool">
                    <Typography>Test query</Typography>
                  </Tooltip>
                )}
              </div>
            </Stack>

            <Typography py={2} color="error">
              {errorMessage}
            </Typography>
            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" marginTop={2}>
              <Button variant="text" onClick={closeModal} data-testid="ModalCancelButtonId" color="secondary">
                Cancel
              </Button>
              <Button variant="text" type="submit" color="secondary" data-testid="AddArcGisServiceButtonId">
                {editIsActive ? "SAVE" : "ADD"}
              </Button>
            </Stack>
          </form>
        </Card>
      </Modal>
    </Fragment>
  );
};
