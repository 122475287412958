import { RouteObject, createBrowserRouter } from "react-router-dom";

import { APPROVAL_REQUEST_PATH, PATH } from "@/constants";
import { BaseLayout } from "@/layouts";
import { AdminSettingsRoutes } from "./adminSettingsRoutes";
import { DetectedInterceptRoutes } from "./detectedInterceptRoutes";
import { ApprovalRequestTabRoutes } from "./approvalRequestRoutes";
import { HomeRoutes } from "./homePageRoutes";
import { MyRequestsRoutes } from "./myRequestsRoutes";
import { PermitPrintPreview } from "@/components/my-requests/permit/PermitPrintPreview";
import { Playground } from "@/pages/Playground";

const AppRoutes: RouteObject[] = [
  {
    path: PATH.BASE,
    element: <BaseLayout />,
    children: [HomeRoutes, AdminSettingsRoutes, DetectedInterceptRoutes, MyRequestsRoutes, ApprovalRequestTabRoutes]
  },
  {
    path: APPROVAL_REQUEST_PATH.PERMIT_PREVIEW,
    element: <PermitPrintPreview />
  }
];

const DevAppRoutes: RouteObject[] = [
  {
    path: "/playground",
    element: <Playground />
  }
];

function getAppRoutes() {
  if (import.meta.env.DEV) {
    return [...AppRoutes, ...DevAppRoutes];
  }

  return AppRoutes;
}

export const router = createBrowserRouter(getAppRoutes());
