import { apiClient } from "@/api/client";
import { AddInterceptRule, InterceptRuleList } from "@/interfaces";

const basePath = "/interceptRule";

export const getPaginatedInterceptRuleList = (pageNumber: number, pageSize: number) =>
  apiClient
    .get<InterceptRuleList>(`${basePath}`, {
      params: { pageNumber, pageSize }
    })
    .then((response) => response.data.items);

export const addInterceptRule = (request: AddInterceptRule) =>
  apiClient.post(basePath, request).then((response) => {
    return response.status < 300;
  });
