import { useQuery } from "@tanstack/react-query";
import { getDisciplineAuthorisationComments } from "@/services";
import { useARContext } from "@/context";
import { useEffect } from "react";
import { AR_DISCIPLINE_AUTHORISATION_COMMENTS_QUERY_KEY, AR_UNEXPECTED_ERROR_BANNER } from "@/constants";

export const useDisciplineAuthorisationComments = (approvalRequestDisciplineId?: string) => {
  const { appendAlertBanner } = useARContext();

  const { error, ...rest } = useQuery({
    queryKey: [AR_DISCIPLINE_AUTHORISATION_COMMENTS_QUERY_KEY, approvalRequestDisciplineId],
    queryFn: () => getDisciplineAuthorisationComments(approvalRequestDisciplineId!),
    enabled: approvalRequestDisciplineId !== undefined,
    initialData: []
  });

  useEffect(() => {
    if (error) {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  }, [error, appendAlertBanner]);

  return { ...rest, error };
};
