import { CancelTokenSource } from "axios";

export enum AttachmentUploadStatus {
  Successful = "successful",
  Processing = "processing",
  Failed = "failed"
}

export interface Attachment {
  fileName: string;
  contentType: string;
  fileSize: number;
  uploadStatus: AttachmentUploadStatus;
  cancellationToken: CancelTokenSource | null;
  errorMessage: string | null;
  dateChanged: Date;
  createdBy: string;
}

export type AttachmentSet = Record<string, Attachment>;

export interface FileValidationResult {
  isValid: boolean;
  errorMessage: string | null;
}
