import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";

import { HomePageToggleComponent } from "@/components";

export function DetectedInterceptLayout() {
  return (
    <>
      <Grid container paddingY={1}>
        <Grid item xs={4} sm={6} md={3} lg={3}>
          {/* Future Select Component over here */}
        </Grid>
        <Grid item xs={8} sm={6} md={3} lg={3} margin="auto" textAlign="center">
          <HomePageToggleComponent />
        </Grid>
        <Grid item xs={0} sm={0} md={3} lg={3}></Grid>
      </Grid>
      <Grid container>
        <Suspense fallback={<span>Loading..</span>}>
          <Outlet />
        </Suspense>
      </Grid>
    </>
  );
}
