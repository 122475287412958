import { APPROVAL_REQUEST_PATH } from "@/constants";
import { useARContext } from "@/context";

import { generatePath } from "react-router-dom";
import { PermitView } from "./PermitView";

export function PermitTab() {
  const {
    approvalRequestId,
    approvalRequest: { approvalRequestStatus }
  } = useARContext();

  function onClickPrintPermit() {
    const pathToNavigate = generatePath(APPROVAL_REQUEST_PATH.PERMIT_PREVIEW, { approvalRequestId: approvalRequestId });
    window.open(`${window.location.origin}${pathToNavigate}`, "_blank");
  }

  return (
    <PermitView
      approvalRequestId={approvalRequestId ?? ""}
      approvalRequestStatus={approvalRequestStatus}
      isPrintPreview={false}
      onPrintClick={onClickPrintPermit}
    />
  );
}
