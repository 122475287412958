import { Autocomplete, FormControl, FormHelperText, TextField } from "@mui/material";
import { AppFormAutoCompleteProps } from "./AppFormProps";
import { Controller } from "react-hook-form";

export const AppFormAutoComplete = ({
  id,
  name,
  control,
  label,
  isRequired,
  options,
  error,
  size,
  loading,
  defaultValue,
  autoSelect,
  "data-testid": dataTestId
}: AppFormAutoCompleteProps) => {
  return (
    <FormControl fullWidth size={size}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }) => (
          <Autocomplete
            autoSelect={autoSelect}
            size={size}
            id={id}
            defaultValue={defaultValue}
            data-testid={dataTestId}
            options={options}
            getOptionLabel={(option) => option.value!}
            loading={loading}
            onChange={(_e, newValue) => {
              onChange(newValue?.id);
            }}
            renderInput={(params) => (
              <TextField required={isRequired} error={!!error} {...params} label={label} variant="outlined" />
            )}
          />
        )}
      />
      <FormHelperText error sx={{ marginBottom: 1 }}>
        {error?.message ? error?.message : " "}
      </FormHelperText>
    </FormControl>
  );
};
