import { useState, MouseEvent } from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { MenuOutlined, History } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DETECTED_INTERCEPT_PATH } from "@/constants";
import { AppPath } from "@/interfaces";

const padingLeftRight = 15;
const paddingTopBottom = 10;

export function HomePageToggleComponent() {
  const navigate = useNavigate();
  const [currentlyToggled, setCurrentlyToggled] = useState<AppPath>(DETECTED_INTERCEPT_PATH.TABLE);

  const handleToggle = (_: MouseEvent<HTMLElement>, toggledValue: AppPath) => {
    if (toggledValue) {
      setCurrentlyToggled(toggledValue);
      navigate(toggledValue);
    }
  };

  return (
    <>
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={currentlyToggled}
        onChange={handleToggle}
        aria-label="ui-toggle"
        style={{ margin: "auto", textAlign: "center" }}
      >
        <ToggleButton
          value={DETECTED_INTERCEPT_PATH.TABLE}
          aria-label="table"
          style={{
            paddingTop: paddingTopBottom,
            paddingBottom: paddingTopBottom,
            paddingLeft: padingLeftRight,
            paddingRight: padingLeftRight
          }}
        >
          <MenuOutlined />
          <Typography variant="button" ml={1}>
            TABLE
          </Typography>
        </ToggleButton>
        <ToggleButton
          value={DETECTED_INTERCEPT_PATH.HISTORY}
          aria-label="history"
          style={{
            paddingTop: paddingTopBottom,
            paddingBottom: paddingTopBottom,
            paddingLeft: padingLeftRight,
            paddingRight: padingLeftRight
          }}
        >
          <History />
          <Typography variant="button" ml={1}>
            History
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}
