import { useQuery } from "@tanstack/react-query";
import { CLEARING_ALLOCATION_AVAILABLE_BALANCE_QUERY_KEY } from "@/constants";
import { apiClient } from "@/api/client";
import { ClearingAllocationAvailableBalance } from "@/interfaces/api/responses/ClearingAllocation";

const path = "/clearingAllocation";

export function useGetClearingAllocationAvailableBalance(
  referenceNo?: string[],
  onSuccessCallback?: (data: ClearingAllocationAvailableBalance[]) => void
) {
  return useQuery({
    queryKey: [CLEARING_ALLOCATION_AVAILABLE_BALANCE_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<ClearingAllocationAvailableBalance[]>(`${path}`, {
          params: { referenceNo },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    onSuccess: (data) => onSuccessCallback?.(data),
    initialData: [],
    enabled: referenceNo !== undefined
  });
}
