import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ApprovalRequestReturnStatus, Notification, NotificationEvent } from "@/interfaces";
import {
  APPROVAL_REQUEST_QUERY_KEY,
  AR_COMMENTARIES_QUERY_KEY,
  MARK_NOTIFICATION_READ_MUTATION_KEY,
  NOTIFICATIONS_QUERY_KEY,
  SECONDS_IN_MS
} from "@/constants";
import { apiClient } from "@/api/client";
import { updateApprovalRequestReturn } from "@/services";
import { UpdateNotificationsWithStatesRequest } from "@/interfaces/Notifications";

const BASE_NOTIFICATIONS_URL = "/notifications";

export function useNotifications(
  isUnread?: boolean,
  isInApp?: boolean,
  isActionable?: boolean,
  isRealTime?: boolean,
  isPersist?: boolean,
  events?: NotificationEvent[]
) {
  const { data, ...rest } = useQuery({
    queryKey: [NOTIFICATIONS_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<Notification[]>(`${BASE_NOTIFICATIONS_URL}`, {
          params: {
            isUnread: isUnread ?? null,
            isInApp: isInApp ?? null,
            isActionable: isActionable ?? null,
            isRealTime: isRealTime ?? null,
            isPersist: isPersist ?? null,
            events: events ?? null
          },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    initialData: [],
    refetchInterval: 60 * SECONDS_IN_MS
  });

  return { notifications: data, ...rest };
}

export function useMarkNotificationsAsRead(approvalRequestId?: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: [MARK_NOTIFICATION_READ_MUTATION_KEY],
    mutationFn: ({
      notificationIds,
      isDismissUnread,
      isDismissInApp,
      isDismissActionable,
      isDismissRealTime
    }: UpdateNotificationsWithStatesRequest) =>
      apiClient.put<boolean>(`${BASE_NOTIFICATIONS_URL}/mark-read`, {
        notificationIds,
        isDismissUnread,
        isDismissInApp,
        isDismissActionable,
        isDismissRealTime
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY]);
      if (approvalRequestId) queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
    }
  });

  return { markRead: mutate, ...rest };
}

export function useNotificationDecideReturnRequest(approvalRequestId: string, notificationId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (decision: ApprovalRequestReturnStatus) => {
      return updateApprovalRequestReturn({ approvalRequestId, notificationId, decision });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY]);
    },
    onError: () => {
      // TODO: Update when implementing application wide error banner
    }
  });
}
