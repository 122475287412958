import { useEffect } from "react";
import { Grid } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import {
  useGetFieldSiteList,
  useGetFieldSiteTypeList,
  useGetHubList,
  useGetSiteList,
  useSelectedFieldSite,
  useSelectedFieldSiteType,
  useSelectedHubId,
  useSelectedSiteId
} from "@/hooks";
import { DashboardSelectWithGrid } from "@/components/shared";

interface InterceptResultTableToolbarProps {
  isHistory: boolean;
}

export const InterceptResultTableToolbar = ({ isHistory }: InterceptResultTableToolbarProps) => {
  const [selectedHubId, setSelectedHubId] = useSelectedHubId();
  const [selectedSiteId, setSelectedSiteId] = useSelectedSiteId();
  const [selectedFieldSite, setSelectedFieldSite] = useSelectedFieldSite();
  const [selectedFieldSiteType, setSelectedFieldSiteType] = useSelectedFieldSiteType();

  const [hubList, getHubList] = useGetHubList();
  const [siteList, getSiteList] = useGetSiteList();
  const [fieldSiteList, getFieldSiteList] = useGetFieldSiteList();
  const [fieldSiteTypeList, getFieldSiteTypeList] = useGetFieldSiteTypeList();

  useEffect(() => {
    getHubList(isHistory);
    getFieldSiteList(isHistory);
    getFieldSiteTypeList(isHistory);
  }, []);

  useEffect(() => {
    getSiteList(selectedHubId);
  }, [selectedHubId]);

  return (
    <GridToolbarContainer>
      <Grid container spacing={3} paddingBottom={5}>
        <DashboardSelectWithGrid
          id="hubId"
          label="Hub"
          options={hubList}
          value={selectedHubId}
          onChange={(event) => {
            setSelectedHubId(event.target.value);
          }}
        />

        <DashboardSelectWithGrid
          id="siteId"
          label="Site"
          options={siteList}
          value={selectedSiteId}
          onChange={(event) => {
            setSelectedSiteId(event.target.value);
          }}
        />
        <DashboardSelectWithGrid
          id="fieldSiteNo"
          label="Field Site"
          options={fieldSiteList}
          value={selectedFieldSite}
          onChange={(event) => {
            setSelectedFieldSite(event.target.value);
          }}
        />
        <DashboardSelectWithGrid
          id="fieldSiteType"
          label="Field Site Type"
          options={fieldSiteTypeList}
          value={selectedFieldSiteType}
          onChange={(event) => {
            setSelectedFieldSiteType(event.target.value);
          }}
        />
      </Grid>
    </GridToolbarContainer>
  );
};
