import { object, string, number } from "yup";

import { MANDATORY_FIELD } from "@/constants";

export const interceptLayersSchema = object({
  arcGISServiceId: string().required(MANDATORY_FIELD.DEFAULT),
  arcGISLayerId: number().required(MANDATORY_FIELD.DEFAULT).min(0, "ArcGIS Layer is a required field."),
  interceptLayerType: number().required(MANDATORY_FIELD.DEFAULT).min(0, "Type is a required field."),
  recordStatus: number().required(MANDATORY_FIELD.DEFAULT).min(0, "Status is a required field."),
  arcGISFilterQuery: string().optional()
});
