import { atomWithReset } from "jotai/utils";

import { GetInterceptResultList, InterceptResultItem, IOption, PaginatedList } from "@/interfaces";
import { DEFAULT_PAGE_SIZE, FIRST_PAGE } from "@/constants";

export const hubListAtom = atomWithReset<IOption[]>([] as IOption[]);
export const selectedHubIdAtom = atomWithReset<string>("");

export const siteListAtom = atomWithReset<IOption[]>([] as IOption[]);
export const selectedSiteIdAtom = atomWithReset<string>("");

export const fieldSiteListAtom = atomWithReset<IOption[]>([] as IOption[]);
export const selectedFieldSiteAtom = atomWithReset<string>("");

export const fieldSiteTypeListAtom = atomWithReset<IOption[]>([] as IOption[]);
export const selectedFieldSiteTypeAtom = atomWithReset<string>("");

export const interceptResultListAtom = atomWithReset<PaginatedList<InterceptResultItem>>({
  items: [] as InterceptResultItem[],
  pageNumber: FIRST_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  totalPages: 0,
  totalRecords: 0
} as PaginatedList<InterceptResultItem>);

export const interceptResultListRequestAtom = atomWithReset<GetInterceptResultList>({
  pageNumber: 1,
  pageSize: 10,
  sortField: ""
} as GetInterceptResultList);
