import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme
} from "@mui/material";
import { useARContext } from "@/context";
import { useInternalDependencies } from "@/hooks";
import { InternalDependency } from "@/interfaces";
import { useEffect, useState } from "react";
import { colours } from "@/theme/colour";
import { Loading } from "@/components/Loading";

interface SelectTrackingCommentDependencyProps {
  selectedDependencies: InternalDependency[];
  handleDependencyChange: (selectedDependencies: InternalDependency[]) => void;
  readOnly?: boolean;
  error?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(
  internalDependency: InternalDependency,
  selectedDependencies: Record<string, InternalDependency>,
  theme: Theme
) {
  if (selectedDependencies[internalDependency.internalDependencyId])
    return {
      backgroundColor: colours.selected,
      fontWeight: theme.typography.fontWeightMedium
    };

  return {
    fontWeight: theme.typography.fontWeightRegular
  };
}

function indexByDependencyId(internalDependencies: InternalDependency[]): Record<string, InternalDependency> {
  const indexedInternalDependencies: Record<string, InternalDependency> = {};

  if (internalDependencies === undefined || internalDependencies === null) return indexedInternalDependencies;

  internalDependencies.forEach((dependency) => {
    indexedInternalDependencies[dependency.internalDependencyId] = dependency;
  });

  return indexedInternalDependencies;
}

export function SelectTrackingCommentDependency({
  selectedDependencies,
  handleDependencyChange,
  readOnly,
  error
}: SelectTrackingCommentDependencyProps) {
  const theme = useTheme();
  const { approvalRequestId } = useARContext();
  const { data: internalDependencies, isLoading, isSuccess } = useInternalDependencies(approvalRequestId);
  const [internalDependencyOptions, setInternalDependencyOptions] = useState<Record<string, InternalDependency>>({});
  const selectedDependencyOptions = indexByDependencyId(selectedDependencies);

  useEffect(() => {
    if (internalDependencies === undefined) return;
    setInternalDependencyOptions(indexByDependencyId(internalDependencies));
  }, [internalDependencies]);

  const updateCurrentInternalDependencySelections = (
    recentlySelectedDependencies: InternalDependency[],
    currentlySelectedDependencies: Record<string, InternalDependency>
  ): InternalDependency[] => {
    recentlySelectedDependencies.forEach((updatedDependency) => {
      const { internalDependencyId } = updatedDependency;
      if (currentlySelectedDependencies[internalDependencyId]) {
        delete currentlySelectedDependencies[internalDependencyId];
      } else {
        currentlySelectedDependencies[internalDependencyId] = updatedDependency;
      }
    });

    return Object.values(currentlySelectedDependencies);
  };

  const handleChange = (event: SelectChangeEvent<InternalDependency[]>) => {
    const {
      target: { value: selectedInternalDependencyOptions }
    } = event;

    if (typeof selectedInternalDependencyOptions === "string") return;
    if (internalDependencyOptions === undefined) return;

    const recentlySelectedInternalDependencies: InternalDependency[] = selectedInternalDependencyOptions
      .filter((selectedInternalDependency) => selectedInternalDependency.internalDependencyType === undefined)
      .map((id) => internalDependencyOptions[String(id)]);

    if (recentlySelectedInternalDependencies === undefined) return;
    const updatedSelectedInternalDependencies = updateCurrentInternalDependencySelections(
      recentlySelectedInternalDependencies,
      selectedDependencyOptions
    );

    handleDependencyChange(updatedSelectedInternalDependencies);
  };

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess ? (
        <>
          {Object.keys(internalDependencyOptions).length === 0 ? (
            <div>No dependencies found.</div>
          ) : (
            <FormControl sx={{ m: 1, minWidth: 300, maxWidth: 500 }} error={Boolean(error)}>
              <InputLabel id="internal-dependency-multiple-chip-label" size="small">
                Dependencies
              </InputLabel>
              <Select
                labelId="internal-dependency-multiple-chip-label"
                id="internal-dependency-multiple-chip"
                data-testid="tracking-comment-dependency-select-box"
                multiple
                readOnly={readOnly}
                value={selectedDependencies}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Dependencies" />}
                size="small"
                renderValue={(selectedDependencies) => (
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                    data-testid="selected-internal-dependency-multiple-chip-Box"
                  >
                    {selectedDependencies.map((dependency) => (
                      <Chip
                        size="small"
                        key={dependency.internalDependencyId}
                        label={
                          internalDependencyOptions[dependency.internalDependencyId].aliasName ??
                          internalDependencyOptions[dependency.internalDependencyId].displayName
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Object.values(internalDependencyOptions).map((dependencyOption) => (
                  <MenuItem
                    key={dependencyOption.internalDependencyId}
                    value={dependencyOption.internalDependencyId}
                    style={getStyles(dependencyOption, selectedDependencyOptions, theme)}
                  >
                    {dependencyOption.displayName}
                  </MenuItem>
                ))}
              </Select>
              {<FormHelperText data-testid="tracking-comment-dependency-validation-message">{error}</FormHelperText>}
            </FormControl>
          )}
        </>
      ) : null}
    </>
  );
}
