export const DISCIPLINE_STATUSES = {
  NOT_USED: "Not Used",
  NOT_DISTRIBUTED: "Not Distributed",
  NOT_STARTED: "New",
  WIP: "In Progress",
  AUTHORISED: "Authorised"
};

export const DISCIPLINE_MESSAGE = {
  SAVE_SUCCESSFUL: "The request has been saved",
  SAVE_AUTH_SUCCESSFUL: "The Authorisation Comment has been saved"
};

export const AUTH_COMMENT_BUTTON_LABELS = {
  SAVE: "APPLY",
  CANCEL: "CANCEL",
  RESTORE: "RESTORE"
};

export const AUTH_COMMENT_CONTENT_REGEX = new RegExp(
  "({{)([0-9A-Z_]+((_[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12})?)+(_({)([0-9])(}))?)(}})",
  "g"
);
