import { Divider, Stack } from "@mui/material";
import { ApprovalRequestDetails, AuthorisationComments, TrackingComments } from "./my-requests/discipline-tabs";

export function DisciplineTab() {
  return (
    <Stack spacing="1.5rem">
      <ApprovalRequestDetails />
      <Divider />
      <TrackingComments />
      <Divider />
      <AuthorisationComments />
    </Stack>
  );
}
