import { Box, Button, Stack, Typography } from "@mui/material";

import { useAttachmentLinkContext } from "@/context";
import { AttachmentLinkDisplay } from "@/features/my-requests/components";
import { AttachmentLinkModal } from "./AttachmentLinkModal";

export function AttachmentLink() {
  const { setAttachmentLinkModalConfig } = useAttachmentLinkContext();

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" my="1rem" data-testid="attachment-links-section-header">
        <Typography variant="h6">Links</Typography>
        <Button
          variant="text"
          onClick={() => {
            setAttachmentLinkModalConfig({
              mode: "Add",
              modalIsOpen: true
            });
          }}
        >
          Add
        </Button>
      </Stack>
      <AttachmentLinkDisplay />
      <AttachmentLinkModal />
    </Box>
  );
}
