import { Stack } from "@mui/material";
import { HubConfiguration } from "./HubConfiguration";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { Loading } from "@/components";

export interface UserHubMatrixConfigurationProps {
  hubId: string;
}

export function UserHubMatrixConfiguration() {
  return (
    <Stack direction="row" spacing={2}>
      <Stack sx={{ width: "20%" }}>
        <HubConfiguration />
      </Stack>
      <Suspense fallback={<Loading prefix="User Matrix Configuration" />}>
        <Stack sx={{ flexGrow: 1 }}>
          <Outlet />
        </Stack>
      </Suspense>
    </Stack>
  );
}
