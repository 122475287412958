import { useEffect, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import * as React from "react";
import { Button, Card, Modal, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { IOption, AddArcGisLayerRequest, ArcGISServiceItem, ArcGisLayerFormInput } from "@/interfaces";
import {
  getArcGisLayers,
  createArcGisLayer,
  getArcGisServices,
  getArcGisServiceById,
  getArcGisServiceDetailsByUrl
} from "@/services";
import { convertToGridRows, convertToIOOption } from "@/utils";
import {
  addIsActiveAtom,
  adminTablePageDetailsAtom,
  adminTableSnackBarAtom,
  arcgisServiceOptionsAtom,
  columnDataAtom,
  deleteIsActiveAtom,
  disableIsActiveAtom,
  editIsActiveAtom,
  enableIsActiveAtom,
  selectedRowIdCountAtom
} from "@/stores";
import { ARCSLayersSchema } from "@/validations";

import {
  ARCGIS_LAYER_TYPES,
  ARCS_LAYERS_COLUMNS,
  FIRST_PAGE,
  LAYER_STATUS_OPTIONS,
  LAYER_TYPE_OPTIONS,
  MAX_PAGE_SIZE,
  TITLE
} from "@/constants";
import { AppFormSelect } from "@/components/fields";
import { AdminTable } from "./shared/AdminTable";

export function ARCSLayers() {
  const setColumn = useSetAtom(columnDataAtom);
  const [editIsActive, setEditIsActive] = useAtom(editIsActiveAtom);
  const [addIsActive, setAddIsActive] = useAtom(addIsActiveAtom);
  const setEnableIsActive = useSetAtom(enableIsActiveAtom);
  const setDisableIsActive = useSetAtom(disableIsActiveAtom);
  const resetAdminTablePageDetails = useResetAtom(adminTablePageDetailsAtom);
  const [adminTablePageDetails, setAdminTablePageDetails] = useAtom(adminTablePageDetailsAtom);
  const selectedRowIdCount = useAtomValue(selectedRowIdCountAtom);
  const setDeleteIsActive = useSetAtom(deleteIsActiveAtom);
  const [arcGISServiceOptions, setArcGISServiceOptions] = useAtom(arcgisServiceOptionsAtom);
  const resetAdminTableSnackBar = useResetAtom(adminTableSnackBarAtom);
  const [selectedServiceLayerOptions, setSelectedServiceLayerOptions] = React.useState<IOption[]>([]);
  const setAdminTableSnackBar = useSetAtom(adminTableSnackBarAtom);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const updateAdminPageDetails = () => {
    setAdminTablePageDetails((old) => ({ ...old, isLoading: true }));
    getArcGisLayers(adminTablePageDetails.page, adminTablePageDetails.pageSize).then(({ items, totalRecords }) => {
      setAdminTablePageDetails((old) => ({
        ...old,
        isLoading: false,
        data: convertToGridRows(items),
        total: totalRecords
      }));
    });
  };

  const getArcGISService = () => {
    getArcGisServices(FIRST_PAGE, MAX_PAGE_SIZE).then(({ items }) => {
      setArcGISServiceOptions(convertToIOOption<ArcGISServiceItem>(items));
    });
  };

  useEffect(() => {
    setAddIsActive(false);
    setEditIsActive(false);
    setEnableIsActive(null);
    setDisableIsActive(null);
    setColumn(ARCS_LAYERS_COLUMNS);
    resetAdminTablePageDetails();
    setDeleteIsActive(false);
    setErrorMessage("");
    resetAdminTableSnackBar();
    getArcGISService();
  }, []);

  useEffect(() => {
    if (selectedRowIdCount === 0) {
      return;
    }
    if (!editIsActive) {
      return;
    }
  }, [selectedRowIdCount, editIsActive]);

  useEffect(() => {
    if (adminTablePageDetails.isLoading) {
      return;
    }

    updateAdminPageDetails();
  }, [adminTablePageDetails.page, adminTablePageDetails.pageSize]);

  const defaultValues: ArcGisLayerFormInput = {
    requestType: -1,
    arcGisServiceId: "",
    arcGisLayerId: -1,
    recordStatus: 1
  };

  const handleCloseOnClicked = () => resetAdminTableSnackBar();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues
  } = useForm<ArcGisLayerFormInput>({
    defaultValues,
    resolver: yupResolver(ARCSLayersSchema)
  });

  const openModal = () => {
    if (editIsActive) {
      return editIsActive !== null ? editIsActive : false;
    } else {
      return addIsActive !== null ? addIsActive : false;
    }
  };

  const closeModal = () => {
    if (editIsActive) {
      setEditIsActive(false);
    } else {
      setAddIsActive(false);
    }
    setErrorMessage("");
    reset();
  };

  const getTestId = () => {
    if (editIsActive) {
      return "EditInterceptLayerModalId";
    } else {
      return "AddInterceptLayerModalId";
    }
  };

  const addArcGISLayer = async (request: AddArcGisLayerRequest) => {
    await createArcGisLayer(request)
      .then(() => {
        updateAdminPageDetails();
        closeModal();
        reset();
        setAdminTableSnackBar({
          open: true,
          message: "The Intercept Layer has been created!",
          handleCloseOnClicked: handleCloseOnClicked,
          hideTime: 5000
        });
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail.toString());
      });
  };

  const handleInterceptLayerSubmit = async () => {
    const interceptLayerInput = getValues();

    const layerNameOption = selectedServiceLayerOptions.find(
      (layerOption) => parseInt(layerOption.id, 10) === interceptLayerInput.arcGisLayerId
    );
    const layerNameToSubmit = layerNameOption !== undefined ? layerNameOption.value : "";

    const interceptLayerDataToSubmit: AddArcGisLayerRequest = {
      arcGisLayerId: interceptLayerInput.arcGisLayerId,
      requestType: interceptLayerInput.requestType,
      arcGisServiceId: interceptLayerInput.arcGisServiceId,
      recordStatus: interceptLayerInput.recordStatus,
      arcGisLayerName: layerNameToSubmit
    };

    addArcGISLayer(interceptLayerDataToSubmit);
  };

  const getArcGISLayersByServiceId = async (serviceId: string) => {
    if (serviceId === "") {
      return;
    }

    const service = await getArcGisServiceById(serviceId);
    getArcGisServiceDetailsByUrl(service.url).then(({ layers }) => {
      const filteredLayers = layers.filter((layer) => layer.type !== ARCGIS_LAYER_TYPES.GROUP_LAYER);
      if (filteredLayers.length > 0) {
        setSelectedServiceLayerOptions(convertToIOOption(filteredLayers));
      }
    });
  };

  const handleServiceChange = (event: SelectChangeEvent<unknown>): void => {
    const serviceId = (event as SelectChangeEvent).target.value;
    setValue("arcGisLayerId", defaultValues.arcGisLayerId);
    if (serviceId !== "") {
      getArcGISLayersByServiceId(serviceId);
    }
  };

  return (
    <>
      <AdminTable />
      <Modal
        open={openModal()}
        onClose={() => {
          closeModal();
        }}
        data-testid={getTestId()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30rem",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 2
          }}
        >
          <form onSubmit={handleSubmit(handleInterceptLayerSubmit)}>
            <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
              {editIsActive ? `Edit ${TITLE.AppTitle} Layer Rule` : `New ${TITLE.AppTitle} Layer Rule`}
            </Typography>
            <AppFormSelect
              control={control}
              label={"Type"}
              name={"requestType"}
              error={errors.requestType}
              data-testid="requestTypeInput"
              showError={true}
              options={LAYER_TYPE_OPTIONS}
            />
            <AppFormSelect
              control={control}
              label={"Status"}
              name={"recordStatus"}
              error={errors.recordStatus}
              data-testid="recordStatusInput"
              showError={true}
              initialValue={defaultValues.recordStatus}
              options={LAYER_STATUS_OPTIONS}
            />
            <AppFormSelect
              control={control}
              label={"ArcGIS Service"}
              name={"arcGisServiceId"}
              error={errors.arcGisServiceId}
              onChangeHandler={(event) => {
                handleServiceChange(event);
              }}
              data-testid="arcGisServiceIdInput"
              showError={true}
              options={arcGISServiceOptions}
            />
            <AppFormSelect
              control={control}
              label={"Layer"}
              name={"arcGisLayerId"}
              error={errors.arcGisLayerId}
              data-testid="arcGisLayerIdInput"
              showError={true}
              options={selectedServiceLayerOptions}
            />
            <Typography color="error">{errorMessage}</Typography>
            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" marginTop={2}>
              <Button variant="text" onClick={closeModal} data-testid="ModalCancelButtonId" color="secondary">
                Cancel
              </Button>
              <Button variant="text" type="submit" color="secondary" data-testid="AddArcGisServiceButtonId">
                {editIsActive ? "SAVE" : "ADD"}
              </Button>
            </Stack>
          </form>
        </Card>
      </Modal>
    </>
  );
}
