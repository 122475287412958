import { Stack } from "@mui/material";

import { RichTextEditor } from "@/components/rich-text-editor";

interface ConfigurableTextContentProps {
  "data-testid": string;
  content: string;
}
export function ConfigurableTextContent({ "data-testid": dataTestId, content }: ConfigurableTextContentProps) {
  return (
    <Stack sx={{ width: "95%" }} data-testid={dataTestId}>
      <RichTextEditor defaultValue={content} readOnly={true} hideMenuBar={true} />
    </Stack>
  );
}
