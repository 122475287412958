import { Stack } from "@mui/material";
import { HubUserConfiguration } from "./HubUserConfiguration";
import { SMEConfiguration } from "./SMEConfiguration";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN_SETTINGS_PATH } from "@/constants";

export interface UserMatrixConfigurationProps {
  hubId: string;
}

export function UserHubMatrixItems() {
  const { hubId } = useParams();
  const navigate = useNavigate();

  if (!hubId) {
    console.error("Unable to find `hubId` in URL path params.");
    navigate(ADMIN_SETTINGS_PATH.USER_MATRIX);
    return null;
  }
  return (
    <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
      <Stack>
        <HubUserConfiguration hubId={hubId} />
      </Stack>
      <Stack>
        <SMEConfiguration hubId={hubId} />
      </Stack>
    </Stack>
  );
}
