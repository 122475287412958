export enum NotificationEvent {
  InterceptDetected = 1,
  InterceptRequiresAction = 2,
  ARSubmitted = 3,
  ARReturnRequested = 4,
  ARReturned = 5,
  ARDistributed = 6,
  ARAmended = 7,
  ARPermitDrafted = 8,
  ARPermitIssued = 9,
  ARCollaborationMention = 10
}

export enum NotificationType {
  InApp = 1,
  Unread = 2,
  Actionable = 3,
  RealTime = 4
}

export interface Notification {
  id: string;
  approvalRequestId: string;
  approvalRequestReferenceNo: string;
  approvalRequestTitle: string;
  event: NotificationEvent;
  type: NotificationType;
  senderName: string;
  senderId: string;
  title: string;
  content: string;
  isUnread: boolean;
  isInApp: boolean;
  isActionable: boolean;
  isRealTime: boolean;
  isPersist: boolean;
  timestamp: Date;
  commentaryId: string;
  actionUrl: string;
}

export interface UpdateNotificationsWithStatesRequest {
  notificationIds: Notification["id"][];
  isDismissUnread: boolean;
  isDismissInApp: boolean;
  isDismissActionable: boolean;
  isDismissRealTime: boolean;
}
