import { Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { useAtom } from "jotai";

import {
  addIsActiveAtom,
  editIsActiveAtom,
  deleteIsActiveAtom,
  selectedRowIdCountAtom,
  enableIsActiveAtom,
  disableIsActiveAtom
} from "@/stores";
import { AdminTableAlert } from "./AdminTableAlert";

export function CustomToolbar() {
  const [addIsActive, setAddIsActive] = useAtom(addIsActiveAtom);
  const [editIsActive, setEditIsActive] = useAtom(editIsActiveAtom);
  const [deleteIsActive, setDeleteIsActive] = useAtom(deleteIsActiveAtom);
  const [enableIsActive, setEnableIsActive] = useAtom(enableIsActiveAtom);
  const [disableIsActive, setDisableIsActive] = useAtom(disableIsActiveAtom);
  const [selectedRowIdCount] = useAtom(selectedRowIdCountAtom);

  const setEdit = () => {
    setEditIsActive(true);
  };

  return (
    <>
      <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
        {enableIsActive !== null ? (
          <Button
            variant="text"
            color="secondary"
            data-testid="OpenEnableModalId"
            onClick={() => {
              setEnableIsActive(true);
            }}
            disabled={selectedRowIdCount === 0 ? false : true}
          >
            Enable
          </Button>
        ) : (
          <></>
        )}

        {disableIsActive !== null ? (
          <Button
            variant="text"
            color="secondary"
            data-testid="OpenDisableModalId"
            onClick={() => {
              setDisableIsActive(true);
            }}
            disabled={selectedRowIdCount === 0 ? false : true}
          >
            Disable
          </Button>
        ) : (
          <></>
        )}

        {addIsActive !== null ? (
          <Button
            variant="text"
            color="secondary"
            data-testid="OpenAddModalId"
            onClick={() => {
              setAddIsActive(true);
            }}
          >
            Add
          </Button>
        ) : (
          <></>
        )}

        {editIsActive !== null ? (
          <Button
            variant="text"
            color="secondary"
            data-testid="OpenEditModalId"
            onClick={() => {
              setEdit();
            }}
            disabled={selectedRowIdCount === 1 ? false : true}
          >
            Edit
          </Button>
        ) : (
          <></>
        )}

        {deleteIsActive !== null ? (
          <Button
            variant="text"
            color="secondary"
            data-testid="OpenDeleteModalId"
            disabled={selectedRowIdCount !== 0 ? false : true}
            onClick={() => {
              setDeleteIsActive(true);
            }}
          >
            Delete
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
      <AdminTableAlert />
    </>
  );
}
