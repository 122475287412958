export const SUB_HEADERS = {
  hubs: "Defines hubs for group owners. A group owner is responsible for their group within one or more hubs.",
  arcgis_service: "",
  interception_analysis: ""
};

export const TITLES = {
  hubs: "Hub",
  arcgis_service: "ArcGIS Services",
  interception_analysis: "Interception Analysis"
};

export const HUBS_COLUMNS = [
  { field: "id", headerName: "Id" },
  { field: "name", headerName: "Name", flex: 1 }
];

export const INTERCEPTION_ANALYSIS_COLUMNS = [
  { field: "id", headerName: "Id" },
  { field: "interceptLayerType", headerName: "Type", flex: 0.5 },
  { field: "recordStatus", headerName: "Status", width: 100 },
  { field: "arcGISService", headerName: "ArcGIS Service", flex: 0.5 },
  { field: "arcGisLayerName", headerName: "Layer", flex: 0.5 }
];

export const ARCS_LAYERS_COLUMNS = [
  { field: "id", headerName: "Id" },
  { field: "requestType", headerName: "Type", flex: 1 },
  { field: "recordStatus", headerName: "Status", flex: 0.5 },
  { field: "arcGISService", headerName: "ArcGIS Service", flex: 1 },
  { field: "arcGisLayerName", headerName: "Layer", flex: 1 }
];

export const ARCGIS_SERVICES_COLUMNS = [
  { field: "id", headerName: "Id" },
  { field: "name", headerName: "Name", flex: 0.3 },
  { field: "url", headerName: "URL", flex: 1 }
];

export const ADMIN_FORM = {
  SAVE_USER_HUB_MATRIX_SUCCESSFUL: "The Approvals Delivery Team has been saved",
  ADD_DISCIPLINE_USER_MATRIX_SUCCESSFUL: "SME added successfully",
  UPDATE_DISCIPLINE_USER_MATRIX_SUCCESSFUL: "SME updated successfully"
};
