import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Stack } from "@mui/material";
import { AppFormRadioProps } from "./AppFormProps";
import { Controller } from "react-hook-form";
import { InputHTMLAttributes } from "react";

export const AppFormRadio = ({
  name,
  control,
  onChangeHandler,
  error,
  resetError,
  options,
  size,
  "data-testid": dataTestId
}: AppFormRadioProps) => {
  return (
    <FormControl fullWidth size={size}>
      <Controller
        key={name}
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            row
            name={name}
            value={value}
            onChange={(event) => {
              const value = event.target.value;
              onChange(value);
              onChangeHandler?.(event, name);
              resetError?.();
            }}
          >
            <Stack direction="column" sx={{ ml: 1 }} pb={3}>
              {options.map((option) => {
                return (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={
                      <Radio
                        inputProps={
                          {
                            "data-testid": `discipline-sme-radio`
                          } as InputHTMLAttributes<HTMLInputElement>
                        }
                      />
                    }
                    label={option.value}
                    data-testid={dataTestId}
                  />
                );
              })}
            </Stack>
          </RadioGroup>
        )}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};
