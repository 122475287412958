import { useQuery } from "@tanstack/react-query";
import { AR_DISCIPLINE_QUERY_KEY, ASSIGNED_AR_COUNTS_TO_SME_QUERY_KEY } from "@/constants";
import { getDisciplinesByApprovalRequestId } from "@/services";
import { apiClient } from "@/api/client";
import { AssignedARCountsByStatus } from "@/interfaces";

const basePath = "/approvalRequestDiscipline";

export function useARDisciplines(id?: string) {
  return useQuery({
    queryKey: [AR_DISCIPLINE_QUERY_KEY, id],
    queryFn: () => getDisciplinesByApprovalRequestId({ approvalRequestId: id! }),
    enabled: id !== undefined,
    initialData: []
  });
}

export function useAssignedARCounts() {
  return useQuery({
    queryKey: [ASSIGNED_AR_COUNTS_TO_SME_QUERY_KEY],
    queryFn: () =>
      apiClient.get<AssignedARCountsByStatus>(`${basePath}/assigned/count`).then((response) => response.data),
    initialData: {
      notUsed: {
        approvalRequestCount: 0,
        notificationCount: 0
      },
      notDistributed: {
        approvalRequestCount: 0,
        notificationCount: 0
      },
      notStarted: {
        approvalRequestCount: 0,
        notificationCount: 0
      },
      wip: {
        approvalRequestCount: 0,
        notificationCount: 0
      },
      authorised: {
        approvalRequestCount: 0,
        notificationCount: 0
      }
    }
  });
}
