import { apiClient } from "@/api/client";
import { LAMSRole } from "@/interfaces";
import { GraphUser } from "@/interfaces/api/requests/index";

const userPath = "/user";
const usersPath = "/users";

export const getGraphUsersWithRole = (roles: LAMSRole[]) =>
  apiClient
    .get<GraphUser[]>(userPath, {
      params: { roles },
      paramsSerializer: {
        indexes: null
      }
    })
    .then((response) => response.data);

export const getGraphUsersBySearch = (searchTerm: string) =>
  apiClient
    .get<GraphUser[]>(`${usersPath}/GetBySearch`, {
      params: { searchTerm }
    })
    .then((response) => response.data);
