export const LINK_MESSAGE = {
  SAVE_SUCCESSFUL: "Link successfully added",
  EDIT_SUCCESSFUL: "Link has been edited"
};

export const HUB_MESSAGE = {
  SAVE_SUCCESSFUL: "Hub successfully added",
  EDIT_SUCCESSFUL: "Hub has been edited",
  DELETE_SUCCESSFUL: "The hub(s) has been deleted!"
};
