import { SnackbarConfig, LoaderConfig } from "@/interfaces";
import { ARFilterQuery } from "@/components/toolbars/ARFilterToolbar";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const EMPTY_PAGINATED_RESPONSE = {
  items: [],
  pageNumber: 1,
  pageSize: 10,
  totalPages: 1,
  totalRecords: 0,
  hasNextPage: false,
  hasPreviousPage: false
};

export const DEFAULT_SNACKBAR_CONFIG: SnackbarConfig = {
  message: "",
  open: false
};

export const DEFAULT_LOADER_CONFIG: LoaderConfig = {
  message: "",
  open: false
};

export const DEFAULT_AR_FILTER_QUERY: ARFilterQuery = {
  selectedHub: "all",
  selectedSite: "all",
  selectedProject: "all"
};

export const DEFAULT_SME_DASHBOARD_FILTER_QUERY = {
  selectedHub: "all",
  selectedSite: "all",
  selectedApprovalRequestType: "all"
};

export const EMPTY_RTE_CONTENT = "<p></p>";
