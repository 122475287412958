import { DisciplineAuthorisationCommentValue } from "@/interfaces";
import { ConfigurableEditableTextContent } from "./ConfigurableEditableTextContent";
import { ConfigurableSelect } from "./ConfigurableSelect";
import { ConfigurableDatePicker } from "./ConfigurableDatePicker";
import { ConfigurableTextField } from "./ConfigurableTextField";
import { ConfigurableChipArray } from "./ConfigurableChipArray";
import { ConfigurableForm } from "./ConfigurableForm";
import { NVCPClearingAllocationTable } from "../specialise-fields/NVCPClearingAllocationTable";

interface ConfigurableFieldComponentProps {
  key: string;
  disciplineAuthCommentValue: DisciplineAuthorisationCommentValue;
  onUpdate: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  onDelete: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  isEditable: boolean;
  onActionHandler: (isEdit: boolean) => void;
  disabled: boolean;
  childValueConfigurations?: DisciplineAuthorisationCommentValue[];
  isViewMode?: boolean;
}
export function ConfigurableFieldComponent({
  key,
  disciplineAuthCommentValue,
  onUpdate,
  onDelete,
  onActionHandler,
  isEditable,
  disabled,
  childValueConfigurations,
  isViewMode
}: ConfigurableFieldComponentProps) {
  const onUpdateChildAuthComment = (updatedValue: DisciplineAuthorisationCommentValue) => {
    const existingChildValues = disciplineAuthCommentValue.childDisciplineAuthorisationCommentValues;
    const updatedDisciplineAuthCommentValue: DisciplineAuthorisationCommentValue = {
      ...disciplineAuthCommentValue,
      childDisciplineAuthorisationCommentValues: existingChildValues?.map((value) => {
        return value.id === updatedValue.id ? updatedValue : value;
      })
    };
    onUpdate(updatedDisciplineAuthCommentValue);
  };

  switch (disciplineAuthCommentValue?.authorisationCommentField?.componentType) {
    case "input":
    case "number":
      return (
        <ConfigurableTextField
          key={key}
          label={disciplineAuthCommentValue.authorisationCommentField.label}
          type={disciplineAuthCommentValue?.authorisationCommentField?.componentType}
          configuration={disciplineAuthCommentValue}
          onUpdate={onUpdate}
          disabled={disabled}
        />
      );
    case "date":
      return (
        <ConfigurableDatePicker
          key={key}
          label={disciplineAuthCommentValue.authorisationCommentField.label}
          configuration={disciplineAuthCommentValue}
          onUpdate={onUpdate}
          disabled={disabled}
        />
      );
    case "select":
      return (
        <ConfigurableSelect
          key={key}
          configuration={disciplineAuthCommentValue}
          onUpdate={onUpdate}
          options={disciplineAuthCommentValue?.authorisationCommentField.options}
          disabled={disabled}
        />
      );
    case "editableText":
      return (
        <ConfigurableEditableTextContent
          key={key}
          configuration={disciplineAuthCommentValue}
          onUpdate={onUpdate}
          isEditable={isEditable}
          onActionHandler={onActionHandler}
          disabled={disabled}
        />
      );
    case "chipArray":
      return (
        <ConfigurableChipArray
          label={disciplineAuthCommentValue.authorisationCommentField.label}
          key={key}
          configuration={disciplineAuthCommentValue}
          onUpdate={onUpdate}
          options={disciplineAuthCommentValue?.authorisationCommentField.options}
          disabled={disabled}
        />
      );
    case "form":
      return (
        <ConfigurableForm
          disciplineAuthCommentValue={disciplineAuthCommentValue}
          childValueConfigurations={childValueConfigurations}
          disabled={disabled}
          onDelete={onDelete}
          onUpdateChildAuthComment={onUpdateChildAuthComment}
          isEditable={isEditable}
          onActionHandler={onActionHandler}
        />
      );
    case "nvcp-table":
      return (
        <NVCPClearingAllocationTable
          disciplineAuthCommentValue={disciplineAuthCommentValue}
          disabled={disabled}
          onUpdate={onUpdate}
          isEditable={isEditable}
          onActionHandler={onActionHandler}
          isViewMode={isViewMode}
        />
      );
    default:
      return null;
  }
}
