import Geometry from "@arcgis/core/geometry/Geometry";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import { intersect, union, buffer } from "@arcgis/core/geometry/geometryEngine.js";
import { Symbol as EsriSymbol } from "@arcgis/core/symbols";
import { find } from "lodash";

import { GeometryDto, LayerBuffer } from "@/interfaces";

export function drawShapes(graphicsLayer: GraphicsLayer, geometries: Geometry | Geometry[], symbol?: EsriSymbol) {
  if (Array.isArray(geometries)) {
    geometries.forEach((geometry) => drawShapes(graphicsLayer, geometry, symbol));
    return;
  }

  graphicsLayer.add(
    new Graphic({
      geometry: geometries,
      symbol
    })
  );
}

export function getGeometryOverlap(
  baseGeometries: Geometry[],
  intersectingGeometries: Graphic[],
  layerBuffers: LayerBuffer[]
) {
  const results: Geometry[] = [];
  const convertedBaseGeometry = union(baseGeometries);

  const addIntersectionResult = (intersectionResults: Geometry | Geometry[]) => {
    if (Array.isArray(intersectionResults)) {
      intersectionResults.forEach((intersection) => results.push(intersection));
      return;
    }
    results.push(intersectionResults);
  };

  intersectingGeometries.forEach((intersectingGeometry) => {
    const layerBuffer = find(layerBuffers, { name: intersectingGeometry.attributes?.name });

    if (layerBuffer && layerBuffer.buffer && layerBuffer.buffer > 0) {
      const bufferedBaseGeometry = buffer(convertedBaseGeometry, layerBuffer.buffer);
      addIntersectionResult(intersect(intersectingGeometry.geometry, bufferedBaseGeometry as Geometry));
    } else {
      addIntersectionResult(intersect(intersectingGeometry.geometry, convertedBaseGeometry));
    }
  });

  return results;
}

export function concatGraphics(geometries: GeometryDto[] | Geometry[]) {
  return geometries.map((geometry) => Graphic.fromJSON(geometry));
}

export function getLayerBuffers(geometries: GeometryDto[]): LayerBuffer[] {
  const layerHasBuffer = (layerBuffer: LayerBuffer) => {
    return layerBuffer.name && layerBuffer.buffer && layerBuffer.buffer > 0;
  };
  return geometries
    .map((shapeGeometry) => {
      return {
        name: shapeGeometry.attributes?.name,
        buffer: shapeGeometry.buffer
      };
    })
    .filter(layerHasBuffer);
}

export function refreshMap(mapView: MapView) {
  mapView.extent = mapView.extent;
}
