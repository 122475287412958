import { Box, Button, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { APPROVAL_REQUEST_PATH, FILE_NAMES } from "@/constants";
import { formatDate } from "@/utils";
import { useApprovalRequestPermit } from "@/hooks";
import { PermitDraftBanner, PermitDiscipline } from "./";
import { ApprovalRequestStatus } from "@/interfaces";
import { generatePath } from "react-router-dom";
import { useSetAtom } from "jotai";
import { snackBarAtom } from "@/stores";
import { PERMIT_MESSAGE } from "@/constants/permitMessages";
import { SnackBarComponent } from "@/components/SnackBarComponent";
import { PDF_WIDTH_IN_PX } from "@/constants/numbers";

const PERMIT_TITLES = {
  STANDARD_CONDITIONS: "Standard Conditions"
};

interface PermitViewProps {
  approvalRequestId: string;
  approvalRequestStatus: ApprovalRequestStatus;
  isPrintPreview: boolean;
  onPrintClick: () => void;
}

export function PermitView({
  approvalRequestId,
  approvalRequestStatus,
  isPrintPreview,
  onPrintClick
}: PermitViewProps) {
  const setSnackBar = useSetAtom(snackBarAtom);
  const { data: permitData } = useApprovalRequestPermit(approvalRequestId);

  async function onClickCopyLink() {
    const permitViewPath = generatePath(APPROVAL_REQUEST_PATH.PERMIT_PREVIEW, { approvalRequestId: approvalRequestId });
    await navigator.clipboard.writeText(`${window.location.origin}${permitViewPath}`);
    setSnackBar({
      message: PERMIT_MESSAGE.LINK_COPY_SUCCESSFUL,
      open: true
    });
  }

  return (
    <>
      <Stack
        data-testid="permit-content"
        rowGap="1rem"
        sx={{ position: "absolute", width: isPrintPreview ? PDF_WIDTH_IN_PX : "max-width" }}
        p={isPrintPreview ? "1.5rem" : "0rem"}
      >
        <PermitDraftBanner approvalRequestStatus={approvalRequestStatus} />
        <Box
          className="permit-preview-button-box"
          display="flex"
          justifyContent="center"
          alignItems="center"
          visibility={approvalRequestStatus === ApprovalRequestStatus.PermitIssued ? "visible" : "hidden"}
        >
          <Button variant="contained" startIcon={<PrintIcon />} onClick={onPrintClick} sx={{ zIndex: 5 }}>
            {isPrintPreview ? "Print" : "Print Preview"}
          </Button>
          <Button
            variant="contained"
            startIcon={<ContentCopyIcon />}
            onClick={onClickCopyLink}
            sx={{ zIndex: 5, margin: 2 }}
          >
            Copy Link
          </Button>
        </Box>
        <Stack>
          <Stack>
            <Grid container xs={12}>
              <Grid item xs={4.5} display="flex" alignItems="center">
                <Box
                  component="img"
                  src={`${import.meta.env.REACT_APP_AUTH_REDIRECT_URI}/images/${FILE_NAMES.COMPANY_LOGO}`}
                  height={100}
                  width={250}
                  alt="Rio Tinto Permit"
                  data-testid="permitLogo"
                />
              </Grid>
              <Grid item xs={4.5} display="flex" alignItems="center" sx={{ justifyContent: "space-between" }}>
                <Typography fontSize="2rem">Permit</Typography>
              </Grid>
              <Grid item xs={3} display="flex" alignItems="center">
                <Typography fontSize="2rem">{permitData?.referenceNo}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Grid container xs={12}>
            <Grid item xs={9} display="flex" alignItems="center">
              <Typography fontSize="1.5rem">{permitData?.title}</Typography>
            </Grid>
            <Grid item xs={3} display="flex" alignItems="center">
              <Typography fontSize="1.5rem">Version {permitData?.approvalRequestVersion}</Typography>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item container xs={4.5}>
              <Grid item xs={12}>
                <Typography fontWeight={100}>Site/Project</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize="1.5rem">{`${permitData?.site}, ${permitData?.project}`}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={4.5}>
              <Grid item xs={12}>
                <Typography fontWeight={100}>Clearing (ha)</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize="1.5rem">{permitData?.clearing}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={3}>
              <Grid item xs={12}>
                <Typography fontWeight={100}>Expiry</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize="1.5rem">{formatDate(permitData?.expiryDate)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} justifyContent="space-between">
            <Grid item xs={8.5}>
              <Box
                component="img"
                src={`${import.meta.env.REACT_APP_AUTH_REDIRECT_URI}/images/${FILE_NAMES.PERMIT_Map}`}
                alt="Rio Tinto Permit Map"
                data-testid="permitMap"
                sx={{ aspectRatio: "1 / 1" }}
                width="100%"
              />
              <i>* For full due diligence undertaken for this permit, please refer in LAMS for full detail.</i>
            </Grid>
            <Grid item container xs={3} display="flex" alignContent="flex-start" rowGap="1.5rem">
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography fontWeight={100}>Authorised by</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize="1.5rem">{permitData?.authorisedBy}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography fontWeight={100}>Coordinator</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize="1.5rem">
                    {permitData?.coordinator ?? "<Permit Coordinator goes here>"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography fontWeight={100}>Permit Owner</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize="1.5rem">{permitData?.permitOwner}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <List sx={{ listStyleType: "disc", ml: "2rem", p: 0 }}>
                  {permitData?.permitOwnerCompliance.map((string, index) => (
                    <ListItem
                      data-testid={`permitOwnerCompliance-${index}`}
                      key={index}
                      sx={{ display: "list-item", pl: 0 }}
                    >
                      {string}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography>{permitData?.queriesStatement}</Typography>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography fontSize={16} fontWeight={500} padding={0}>
                {PERMIT_TITLES.STANDARD_CONDITIONS}
              </Typography>
            </Grid>
            <Grid item xs={12} rowGap="1rem">
              <List dense sx={{ listStyleType: "disc", ml: "2rem" }}>
                {permitData?.standardConditions.map((string, index) => (
                  <ListItem
                    key={index}
                    data-testid={`permitStandardConditionList-${index}`}
                    sx={{ display: "list-item", pl: 0 }}
                  >
                    {string}
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Stack direction="column" rowGap="2rem">
            {permitData?.disciplineActivities.map((disciplineActivity, index) => (
              <PermitDiscipline
                disciplineName={disciplineActivity.name}
                disciplinePrimaryApproverName={disciplineActivity.primaryApproverName}
                disciplineAuthorisedDate={disciplineActivity.authorisedDate}
                authorisationComments={disciplineActivity.authorisationComments}
                key={index}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <SnackBarComponent />
    </>
  );
}
