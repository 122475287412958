import { useState } from "react";
import MapView from "@arcgis/core/views/MapView";
import AreaMeasurement2DViewModel from "@arcgis/core/widgets/AreaMeasurement2D/AreaMeasurement2DViewModel";
import DistanceMeasurement2DViewModel from "@arcgis/core/widgets/DistanceMeasurement2D/DistanceMeasurement2DViewModel";
import { CircularProgress } from "@mui/material";

import { ApprovalRequestStatus } from "@/interfaces";
import { refreshMap } from "@/utils/mapHelpers";
import {
  MapAnalysis,
  MapUploadIcon,
  MapDistance,
  MapDownload,
  MapRecenter,
  MapArea,
  MapFullScreen,
  MapReset,
  MapZoom
} from "@/assets/icons";
import "./MapToolbox.css";

interface MapToolboxProps {
  mapView: MapView;
  approvalRequestStatus: ApprovalRequestStatus;
  isRequestor: boolean;
  inProgressButtons: string[];
  viewExtent: __esri.Extent | null;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  getSensitivityIssues: () => void;
}

export function MapToolbox({
  inProgressButtons,
  mapView,
  approvalRequestStatus,
  isRequestor,
  setFullScreen,
  getSensitivityIssues,
  viewExtent
}: MapToolboxProps) {
  const [clickedButtons, setClickedButtons] = useState<string[]>([]);
  const mapUploadAllowedStatus = [ApprovalRequestStatus.Draft, ApprovalRequestStatus.New];
  const isMapUploadDisabled = !mapUploadAllowedStatus.includes(approvalRequestStatus) || !isRequestor;
  const activeMeasureStatus = ["ready", "measuring"];
  const noAction = () => {};
  const enableFullScreen = () => setFullScreen(true);
  const disableFullScreen = () => setFullScreen(false);
  const zoomToARShape = () => mapView.goTo(viewExtent);

  const [areaMeasurementTool] = useState<AreaMeasurement2DViewModel>(
    new AreaMeasurement2DViewModel({
      view: mapView,
      unit: "metric"
    })
  );
  const [distanceMeasurementTool] = useState<DistanceMeasurement2DViewModel>(
    new DistanceMeasurement2DViewModel({
      view: mapView,
      unit: "metric"
    })
  );

  const getDisabledActions = (enableLabel: string): string[] => {
    const disabledActions = [];
    if (
      enableLabel === "Area" &&
      clickedButtons.includes("Distance") &&
      activeMeasureStatus.includes(distanceMeasurementTool.state)
    ) {
      disabledActions.push("Distance");
    }

    if (
      enableLabel === "Distance" &&
      clickedButtons.includes("Area") &&
      activeMeasureStatus.includes(areaMeasurementTool.state)
    ) {
      disabledActions.push("Area");
    }

    return disabledActions;
  };

  const enableAreaMeasurement = () => areaMeasurementTool.start();
  const disableAreaMeasurement = () => {
    areaMeasurementTool.clear();
    refreshMap(mapView);
  };

  const enableDistanceMeasurement = () => distanceMeasurementTool.start();
  const disableDistanceMeasurement = () => {
    distanceMeasurementTool.clear();
    refreshMap(mapView);
  };

  const panelActionProps = [
    {
      groupLabel: "Shape Files",
      groupItems: [
        {
          icon: MapUploadIcon,
          label: "Upload",
          enabledAction: noAction,
          disabledAction: noAction,
          isDisabled: isMapUploadDisabled
        },
        { icon: MapDownload, label: "Download", enabledAction: noAction, disabledAction: noAction }
      ]
    },
    {
      groupLabel: "View",
      groupItems: [
        {
          icon: MapZoom,
          label: "Zoom to Extents",
          enabledAction: zoomToARShape,
          disabledAction: noAction
        },
        { icon: MapRecenter, label: "Re-center", enabledAction: noAction, disabledAction: noAction },
        {
          icon: MapFullScreen,
          label: "Full Screen",
          enabledAction: enableFullScreen,
          disabledAction: disableFullScreen
        }
      ]
    },
    {
      groupLabel: "Measure",
      groupItems: [
        {
          icon: MapDistance,
          label: "Distance",
          enabledAction: enableDistanceMeasurement,
          disabledAction: disableDistanceMeasurement
        },
        { icon: MapArea, label: "Area", enabledAction: enableAreaMeasurement, disabledAction: disableAreaMeasurement }
      ]
    },
    {
      groupLabel: "Validation",
      groupItems: [
        {
          icon: MapAnalysis,
          label: "Sensitivity Analysis",
          enabledAction: getSensitivityIssues,
          disabledAction: noAction
        },
        { icon: MapReset, label: "Reset", enabledAction: noAction, disabledAction: noAction }
      ]
    }
  ];

  const nonHighlightButtons = ["Upload", "Zoom to Extents", "Sensitivity Analysis"];
  const buttonClicked = (
    label: string,
    buttonDisabled: boolean = false,
    enabledAction: () => void,
    disabledAction: () => void
  ) => {
    if (buttonDisabled) {
      return;
    }

    if (nonHighlightButtons.includes(label)) {
      enabledAction();
    } else if (!clickedButtons.includes(label)) {
      const disabledButtons = getDisabledActions(label);
      setClickedButtons([...clickedButtons.filter((enabledLabel) => !disabledButtons.includes(enabledLabel)), label]);
      enabledAction();
    } else {
      setClickedButtons(clickedButtons.filter((enabledLabel) => enabledLabel !== label));
      disabledAction();
    }
  };

  const buttonContent = (
    label: string,
    IconSvg: React.FunctionComponent<React.ComponentProps<"svg">>,
    enabledAction: () => void,
    disabledAction: () => void,
    buttonDisabled?: boolean,
    buttonInProgress?: boolean
  ) => (
    <div
      className={
        "panel-button" +
        (clickedButtons.includes(label) ? " panel-button-selected" : "") +
        (buttonDisabled || buttonInProgress ? " cursor-not-allowed" : "")
      }
      onClick={() => buttonClicked(label, buttonDisabled || buttonInProgress, enabledAction, disabledAction)}
      data-testid={`map-tool-${label}`}
    >
      <span className="panel-button-icon">
        {buttonDisabled ? (
          <IconSvg fill="grey" />
        ) : buttonInProgress ? (
          <CircularProgress className="circularProgress" size="2rem" />
        ) : label === "Upload" ? (
          <label htmlFor="fileInput">
            <IconSvg fill="black" cursor="pointer" />
          </label>
        ) : (
          <IconSvg fill={clickedButtons.includes(label) ? "#005BD1DE" : "black"} />
        )}
      </span>
      <span className={"panel-button-text" + (buttonDisabled || buttonInProgress ? " color-grey" : " color-black")}>
        {label === "Upload" && !buttonDisabled && !buttonInProgress ? (
          <label htmlFor="fileInput" className="cursor-pointer">
            {label}
          </label>
        ) : (
          label
        )}
      </span>
    </div>
  );

  return (
    <div id="command-panel">
      {panelActionProps.map(({ groupLabel, groupItems }, index) => (
        <>
          {index === 0 ? "" : <div className="panel-divider" />}
          <div className="panel-group">
            <div className="panel-group-buttons">
              {groupItems.map(({ label, icon, enabledAction, disabledAction, isDisabled }) => (
                <>
                  {buttonContent(
                    label,
                    icon,
                    enabledAction,
                    disabledAction,
                    isDisabled,
                    inProgressButtons.includes(label)
                  )}
                </>
              ))}
            </div>
            <div className="panel-group-text">{groupLabel}</div>
          </div>
        </>
      ))}
    </div>
  );
}
