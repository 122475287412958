import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, ClickAwayListener, Divider, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fade from "@mui/material/Fade";
import { colours } from "@/theme/colour";
import { useMarkNotificationsAsRead } from "@/hooks";
import { UnreadBadge } from "../UnreadBadge";
import { NotificationCard } from "./NotificationCard";
import { useNotificationsContext } from "@/context/NotificationsContextProvider";
export function NotificationPopper() {
  const navigate = useNavigate();

  const { navBarPopperAlertNotificationCount, navBarPopperNotifications } = useNotificationsContext();
  const { markRead } = useMarkNotificationsAsRead();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (actionUrl: string) => {
    navigate(actionUrl);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <IconButton
          data-testid="Notifications-popup-icon"
          size="large"
          edge="end"
          aria-label="notifications"
          aria-haspopup="true"
          style={{ color: colours.iconBackground }}
          onClick={handleClick}
        >
          <UnreadBadge
            visible={navBarPopperAlertNotificationCount > 0}
            badgeContent={navBarPopperAlertNotificationCount}
            anchorOverride={{ horizontal: "right" }}
          >
            <NotificationsIcon />
          </UnreadBadge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          sx={{ mt: 1, p: 0, maxHeight: 650 }}
          MenuListProps={{ sx: { p: 0 } }}
        >
          {navBarPopperNotifications.length > 0 ? (
            <Stack>
              <Stack direction="row" p={2} justifyContent="space-between">
                <Typography>All notifications</Typography>
                <ArrowDropDownIcon />
                <MoreVertIcon sx={{ ml: "auto" }} />
              </Stack>
              <Divider />
              {navBarPopperNotifications.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    if (item.isInApp || item.isActionable)
                      markRead({
                        notificationIds: [item.id],
                        isDismissUnread: false,
                        isDismissInApp: item.isInApp,
                        isDismissActionable: item.isActionable,
                        isDismissRealTime: false
                      });
                    handleClose();
                    handleMenuClick(item.actionUrl);
                  }}
                  sx={{ p: "1.5rem" }}
                >
                  <NotificationCard {...item} />
                </MenuItem>
              ))}
            </Stack>
          ) : (
            <Stack direction="row" px={2} py={2} width={400}>
              <Typography>No Notifications Available</Typography>
            </Stack>
          )}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
}
