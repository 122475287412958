/* eslint-disable*/
/* @typescript-eslint/no-explicit-any */
import { Box, CircularProgress, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { loadModules } from "esri-loader";

import { ARCGIS_CONFIG, ARCGIS_LAYERS, ARCGIS_HIGHLIGHT_LAYERS } from "@/constants";
import { useInterceptResult } from "@/hooks";
import { InterceptResult, HighlightLayerProperties } from "@/types";
import "./WebMapComponent.css";

export const WebMapComponent = () => {
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [defaultMapExtent, setDefaultMapExtent] = useState<any>(null);
  const [mapView, setMapView] = useState<any>(null);
  const [webMap, setWebMap] = useState<any>(null);
  const [interceptResult] = useInterceptResult();

  const { REACT_APP_ARCGIS_PORTAL_URL, REACT_APP_ARCGIS_WEBMAP_ITEM_ID, REACT_APP_ARCGIS_IDENTIFY_URL } = import.meta
    .env;

  const availableLayerIds = [
    ARCGIS_LAYERS.ApprovalRequest,
    ARCGIS_LAYERS.SurveyOnly,
    ARCGIS_LAYERS.ApprovalMaintenancePermit,
    ARCGIS_LAYERS.ApprovalPermitAreas,
    ARCGIS_LAYERS.ApprovalPermitDrillingAreas,
    ARCGIS_LAYERS.ApprovalPermitExpiredAreas,
    ARCGIS_LAYERS.ExclusionSites,
    ARCGIS_LAYERS.RestrictionSites
  ];

  useEffect(() => {
    loadModules(
      [
        "esri/config",
        "esri/rest/identify",
        "esri/rest/support/IdentifyParameters",
        "esri/views/MapView",
        "esri/WebMap",
        "esri/widgets/Expand",
        "esri/widgets/LayerList",
        "esri/widgets/Locate",
        "esri/widgets/Zoom"
      ],
      {
        css: true
      }
    )
      .then(([config, identify, IdentifyParameters, MapView, WebMap, Expand, LayerList, Locate, Zoom]) => {
        config.portalUrl = REACT_APP_ARCGIS_PORTAL_URL ?? "";

        const webmap = new WebMap({
          portalItem: {
            id: REACT_APP_ARCGIS_WEBMAP_ITEM_ID
          }
        });
        webmap.load().then(() => {
          const view = new MapView({
            container: mapRef.current,
            map: webmap,
            ui: {
              components: []
            }
          });

          view.when(() => {
            setDefaultMapExtent(view.extent);
            setMapLoaded(true);

            view.ui.add(
              new Locate({
                view: view
              }),
              ARCGIS_CONFIG.WidgetPosition
            );

            view.ui.add(
              new Zoom({
                view: view
              }),
              ARCGIS_CONFIG.WidgetPosition
            );

            view.ui.add(
              new Expand({
                content: new LayerList({ view: view }),
                view: view
              }),
              ARCGIS_CONFIG.WidgetPosition
            );
          });

          view.on("click", (event: any) => {
            identifyListener(view, identify, new IdentifyParameters(), event.mapPoint);
          });

          setWebMap(webmap);
          setMapView(view);
        });
      })
      .catch((err: any) => {
        console.error("LoadModule: ", err);
      });
  }, []);

  useEffect(() => {
    if (!webMap || !mapView || !interceptResult) {
      return;
    }

    loadModules(["esri/Graphic", "esri/layers/GraphicsLayer", "esri/rest/query"], {
      css: true
    })
      .then(([Graphic, GraphicsLayer, query]) => {
        zoomToARExtent(interceptResult, query);
        highlightLayer(interceptResult, GraphicsLayer, Graphic, query);
      })
      .catch((err: any) => {
        console.error("LoadModule: ", err);
      });
  }, [interceptResult]);

  const zoomToARExtent = (interceptResult: InterceptResult, query: any) => {
    setMapLoaded(false);

    query
      .executeForExtent(`${REACT_APP_ARCGIS_IDENTIFY_URL}/${interceptResult.arLayerId}`, {
        where: `AR_Number='${interceptResult.ARNumber}'`,
        returnGeometry: true
      })
      .then((result: any) => {
        if (!result) {
          return;
        }

        mapView
          .goTo(
            {
              easing: "ease-in-out",
              target: result.count > 0 ? result.extent : defaultMapExtent
            },
            { animation: true }
          )
          .catch((error: any) => {
            console.error("Error zooming into map extent.", error);
          });
      })
      .catch((error: any) => {
        console.error("Error querying for zoom extent: ", error);
      })
      .finally(() => {
        setMapLoaded(true);
      });
  };

  const highlightLayer = (interceptResult: InterceptResult, GraphicsLayer: any, Graphic: any, query: any) => {
    if (!interceptResult) {
      return;
    }

    setMapLoaded(false);

    Object.values(ARCGIS_HIGHLIGHT_LAYERS).forEach((layerProperties) => {
      let existingLayer = undefined;
      do {
        existingLayer = webMap.findLayerById(layerProperties.layerId);
        webMap.layers.remove(existingLayer);
      } while (existingLayer);
    });

    const arFeatures = query
      .executeQueryJSON(`${REACT_APP_ARCGIS_IDENTIFY_URL}/${interceptResult.arLayerId}`, {
        where: `AR_Number='${interceptResult.ARNumber}'`,
        returnGeometry: true
      })
      .catch((error: any) => {
        console.error("Error querying approval geometry: ", error);
      });

    const interceptFeatures = query
      .executeQueryJSON(`${REACT_APP_ARCGIS_IDENTIFY_URL}/${interceptResult.interceptLayerId}`, {
        where: `Field_Site_No='${interceptResult.fieldSiteNumber}' AND Site_Type='${interceptResult.fieldSiteType}' AND Site_Category='${interceptResult.fieldSiteCategory}'`,
        returnGeometry: true
      })
      .catch((error: any) => {
        console.error("Error querying intercept geometry: ", error);
      });

    Promise.all([arFeatures, interceptFeatures])
      .then((responses: [any, any]) => {
        responses.forEach((response, index) => {
          if (!response?.features || (Array.isArray(response.features) && response.features.length === 0)) {
            return;
          }

          addHighlightLayer(
            index === 0 ? ARCGIS_HIGHLIGHT_LAYERS.APPROVALS : ARCGIS_HIGHLIGHT_LAYERS.INTERCEPTS,
            response.features,
            Graphic,
            GraphicsLayer
          );
        });
      })
      .finally(() => {
        setMapLoaded(true);
      });
  };

  const identifyListener = (mapView: any, identify: any, identifyParams: any, mapPoint: any) => {
    identifyParams.tolerance = 3;
    identifyParams.layerIds = availableLayerIds;
    identifyParams.layerOption = "popup";
    identifyParams.width = mapView.width;
    identifyParams.height = mapView.height;
    identifyParams.geometry = mapPoint;
    identifyParams.mapExtent = mapView.extent;

    identify
      .identify(REACT_APP_ARCGIS_IDENTIFY_URL, identifyParams)
      .then(function (response: any) {
        const results = response.results;
        if (results.length <= 0) return null;
        return results.map((result: any) => {
          const feature = result.feature;
          const layerName = result.layerName;
          const layerId = result.layerId;

          if (!availableLayerIds.includes(layerId)) {
            return null;
          }

          feature.attributes.layerName = layerName;
          feature.popupTemplate = popUpTemplate(layerId);
          return feature;
        });
      })
      .then((response: any) => {
        if (response !== null)
          if (response[0]) {
            popUpContentOpen(mapView, response, mapPoint);
          }
      })
      .catch((error: any) => {
        console.error("Error", error);
      });
  };

  const popUpContentOpen = (mapView: any, featuresContent: any, location: any) => {
    mapView.popup.open({
      features: featuresContent,
      location: location
    });
  };

  const popUpTemplate = (layerId: number) => {
    if ([ARCGIS_LAYERS.ApprovalRequest, ARCGIS_LAYERS.SurveyOnly].includes(layerId))
      return {
        title: "{layerName}: {AR_Number}",
        content: [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "AR_Number",
                label: "AR_Number"
              },
              {
                fieldName: "Purpose",
                label: "Purpose"
              },
              {
                fieldName: "Site",
                label: "Site"
              },
              {
                fieldName: "Project",
                label: "Project"
              },
              {
                fieldName: "Owner",
                label: "Owner"
              },
              {
                fieldName: "Facility",
                label: "Facility"
              },
              {
                fieldName: "Area_ha",
                label: "Area_ha"
              },
              {
                fieldName: "Clearing_Amount_ha",
                label: "Clearing_Amount_ha"
              },

              {
                fieldName: "AR_Request_Link",
                label: "AR_Request_Link"
              },
              {
                fieldName: "StatusName",
                label: "StatusName"
              },
              {
                fieldName: "created_user",
                label: "created_user"
              },
              {
                fieldName: "created_date",
                label: "created_date"
              },
              {
                fieldName: "last_edited_user",
                label: "last_edited_user"
              },
              {
                fieldName: "last_edited_date",
                label: "last_edited_date"
              }
            ]
          }
        ]
      };
    else if (
      [
        ARCGIS_LAYERS.ApprovalMaintenancePermit,
        ARCGIS_LAYERS.ApprovalPermitAreas,
        ARCGIS_LAYERS.ApprovalPermitDrillingAreas,
        ARCGIS_LAYERS.ApprovalPermitExpiredAreas
      ].includes(layerId)
    )
      return {
        title: "{layerName}: {AR_Number}",
        content: [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "AR_Number",
                label: "AR_Number"
              },
              {
                fieldName: "Purpose",
                label: "Purpose"
              },
              {
                fieldName: "Site",
                label: "Site"
              },
              {
                fieldName: "Project",
                label: "Project"
              },
              {
                fieldName: "Owner",
                label: "Owner"
              },
              {
                fieldName: "Expiry_Date ",
                label: "Expiry_Date "
              },
              {
                fieldName: "Clearing_Amount_ha",
                label: "Clearing_Amount_ha"
              },

              {
                fieldName: "AR_Permit_Link ",
                label: "AR_Permit_Link "
              },
              {
                fieldName: "Issued_Date ",
                label: "Issued_Date "
              },
              {
                fieldName: "created_user",
                label: "created_user"
              },
              {
                fieldName: "created_date",
                label: "created_date"
              },
              {
                fieldName: "last_edited_user",
                label: "last_edited_user"
              },
              {
                fieldName: "last_edited_date",
                label: "last_edited_date"
              }
            ]
          }
        ]
      };
    else if ([ARCGIS_LAYERS.ExclusionSites, ARCGIS_LAYERS.RestrictionSites].includes(layerId))
      return {
        title: "{layerName}: {Site_type}",
        content: [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "Site_type",
                label: "Site_type"
              },
              {
                fieldName: "Field_Site_No",
                label: "Field_Site_No"
              },
              {
                fieldName: "Site_Category",
                label: "Site_Category"
              },
              {
                fieldName: "Rationale",
                label: "Rationale"
              },
              {
                fieldName: "Approval_Process",
                label: "Approval_Process"
              },
              {
                fieldName: "Reason",
                label: "Reason"
              },
              {
                fieldName: "Site_Owner",
                label: "Site_Owner"
              },
              {
                fieldName: "Site_Assessor_for_ARs",
                label: "Site_Assessor_for_ARs"
              },

              {
                fieldName: "MapInfo_Source_Layer",
                label: "MapInfo_Source_Layer"
              },
              {
                fieldName: "created_user",
                label: "created_user"
              },
              {
                fieldName: "created_date",
                label: "created_date"
              },
              {
                fieldName: "last_edited_user",
                label: "last_edited_user"
              },
              {
                fieldName: "last_edited_date",
                label: "last_edited_date"
              }
            ]
          }
        ]
      };
  };

  const addHighlightLayer = (
    layerProperties: HighlightLayerProperties,
    features: any[],
    Graphic: any,
    GraphicsLayer: any
  ) => {
    const { layerId, layerTitle, fillColour, outlineColour, outlineWidth } = layerProperties;
    const graphicsLayer = new GraphicsLayer({
      id: layerId,
      title: layerTitle
    });

    graphicsLayer.removeAll();

    features.forEach((feature: any) => {
      const graphic = new Graphic({
        geometry: feature.geometry,
        symbol: {
          type: "simple-fill",
          color: fillColour,
          outline: {
            color: outlineColour,
            width: outlineWidth
          }
        }
      });

      graphicsLayer.add(graphic);
    });

    webMap.layers.add(graphicsLayer);
  };

  return (
    <Stack className="mapContainer">
      {!mapLoaded ? <CircularProgress className="circularProgress" /> : null}
      <Box className="mapView" ref={mapRef}></Box>
    </Stack>
  );
};
