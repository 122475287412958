import { apiClient } from "@/api/client";
import { FilterOptionsRequest } from "@/interfaces/api/requests/FilterOptionRequest";
import { FilterOptions, ReferenceDataTrackingComment } from "@/interfaces/api/responses";

const baseURL = "/reference";

export const getTrackingCommentsRefData = (disciplineId: string) =>
  apiClient
    .get<ReferenceDataTrackingComment[]>(`${baseURL}/tracking-comments`, { params: { disciplineId } })
    .then((response) => response.data);

export const getFilterOptions = (request: FilterOptionsRequest) =>
  apiClient
    .get<FilterOptions>(`${baseURL}/filter-options`, {
      params: { ...request },
      paramsSerializer: {
        indexes: null
      }
    })
    .then((response) => response.data);
