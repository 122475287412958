import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";

import { msalInstance, scopes } from "@/authentication";
import { Notification, NotificationEvent } from "@/interfaces";
import { useAuthorization } from "./AuthorizationProvider";
import { useQueryClient } from "@tanstack/react-query";
import { NOTIFICATIONS_QUERY_KEY } from "@/constants";
import { useNotificationsContext } from "./NotificationsContextProvider";

enum NotificationMessageEvents {
  Message = "Message",
  ApprovalRequestAmended = "ApprovalRequestAmended",
  ApprovalRequestNotAmended = "ApprovalRequestNotAmended",
  SMEReturnInProgress = "SMEReturnInProgress",
  SMEReturnActioned = "SMEReturnActioned",
  RefreshNotifications = "RefreshNotifications"
}

export interface PushNotificationsContextType {
  notifications: Notification[];
  isBeingAmended: (approvalRequestId: string) => boolean;
  isBeingReturned: (approvalRequestId: string) => boolean;
}

export const PushNotificationsContext = createContext<PushNotificationsContextType>({
  notifications: [],
  isBeingAmended: () => false,
  isBeingReturned: () => false
});

export const usePushNotificationsContext = () => useContext(PushNotificationsContext);

export function PushNotificationsProvider({ children }: PropsWithChildren) {
  const { userId } = useAuthorization();
  const queryClient = useQueryClient();
  const { pushNotifications } = useNotificationsContext();
  const [pushNotificationsStarted, setPushNotificationsStarted] = useState<boolean>(false);
  const [amendedARs, setAmendedARs] = useState<string[]>([]);
  const [returnedARs, setReturnedARs] = useState<string[]>([]);
  const connection = useMemo(
    () =>
      new HubConnectionBuilder()
        .withUrl(`${import.meta.env.REACT_APP_BACKEND_SOCKETS_URL}/notifications`, {
          accessTokenFactory: async () => (await msalInstance.acquireTokenSilent({ scopes })).accessToken
        })
        .build(),
    []
  );

  const isBeingAmended = (approvalRequestId: string) => amendedARs.includes(approvalRequestId);

  const isBeingReturned = (approvalRequestId: string) => returnedARs.includes(approvalRequestId);

  const addSubscriptions = useCallback(() => {
    connection.on(NotificationMessageEvents.ApprovalRequestAmended, (approvalRequestId: string) => {
      setAmendedARs((old) => [...old, approvalRequestId]);
    });

    connection.on(NotificationMessageEvents.ApprovalRequestNotAmended, (approvalRequestId: string) => {
      setAmendedARs((old) => [...old.filter((ar) => ar !== approvalRequestId)]);
    });

    connection.on(NotificationMessageEvents.SMEReturnInProgress, (approvalRequestId: string) => {
      setReturnedARs((old) => [...old, approvalRequestId]);
    });

    connection.on(NotificationMessageEvents.SMEReturnActioned, (approvalRequestId: string) => {
      setReturnedARs((old) => [...old.filter((ar) => ar !== approvalRequestId)]);
    });

    connection.on(NotificationMessageEvents.Message, (message: string) => {
      console.info("Server: ", message);
    });

    connection.on(NotificationMessageEvents.ApprovalRequestAmended, (approvalRequestId: string) => {
      queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY]);
      console.info("Invalidate: ", approvalRequestId);
    });

    connection.on(NotificationMessageEvents.SMEReturnInProgress, (approvalRequestId: string) => {
      queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY]);
      console.info("Invalidate: ", approvalRequestId);
    });
  }, [connection]);

  useEffect(() => {
    const initialAmendedARs = pushNotifications.reduce((acc, curr) => {
      if (curr.isRealTime && curr.event === NotificationEvent.ARAmended) {
        acc.push(curr.approvalRequestId);
      }
      return acc;
    }, [] as string[]);
    setAmendedARs(initialAmendedARs);

    const initialReturnRequestARs = pushNotifications.reduce((acc, curr) => {
      if (curr.isRealTime && curr.event === NotificationEvent.ARReturnRequested) {
        acc.push(curr.approvalRequestId);
      }
      return acc;
    }, [] as string[]);
    setReturnedARs(initialReturnRequestARs);
  }, [pushNotifications]);

  useEffect(() => {
    if (!connection || !userId || pushNotificationsStarted || connection.state !== HubConnectionState.Disconnected) {
      return;
    }

    connection
      .start()
      .then(() => {
        console.info("Connection established.");
        addSubscriptions();
        connection
          .invoke("startPushNotifications", userId)
          .then(() => {
            console.info("Push notification subscription initialised.");
            setPushNotificationsStarted(true);
          })
          .catch((error) => {
            console.error("Push notification subscription failed: ", error);
          });
      })
      .catch((error) => {
        console.error("SignalR connection initialisation failed: ", error);
      });

    return () => {
      if (!connection || !userId || !pushNotificationsStarted || connection.state === HubConnectionState.Disconnected) {
        return;
      }

      connection.invoke("StopPushNotifications", userId).then(() => {
        console.info("Cleaning up, terminating connection.");
        connection.stop();
      });
    };
  }, [addSubscriptions, connection, pushNotificationsStarted, userId]);

  const contextValue = {
    notifications: pushNotifications,
    isBeingAmended,
    isBeingReturned
  };

  return <PushNotificationsContext.Provider value={contextValue}>{children}</PushNotificationsContext.Provider>;
}
