import { ApprovalRequestStatus } from "@/interfaces";
import { PermitView } from "./PermitView";
import { useParams } from "react-router-dom";
import { useApprovalRequestById } from "@/hooks";

export function PermitPrintPreview() {
  const { approvalRequestId } = useParams();
  const { data: approvalRequest } = useApprovalRequestById(approvalRequestId);
  return (
    <PermitView
      approvalRequestId={approvalRequestId ?? ""}
      approvalRequestStatus={approvalRequest?.approvalRequestStatus ?? ApprovalRequestStatus.DraftPermit}
      isPrintPreview={true}
      onPrintClick={() => {
        print();
      }}
    />
  );
}
