import ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { MULTI_TENANCY, TENANCIES } from "./constants/appConstant";

async function enableMocking() {
  if (import.meta.env.REACT_APP_ENABLE_MSW !== "true") {
    return;
  }

  const { worker } = await import("@/mocks/browser");
  return worker.start();
}

function setTenancy() {
  const url = new URL(window.location.href);
  const tenancy = url.searchParams.get(MULTI_TENANCY.PARAMETER);
  if (tenancy) {
    window.localStorage.setItem(MULTI_TENANCY.STORAGE, getValidTenancy(tenancy));
  }
}

function getValidTenancy(tenancy: string | null): string {
  if (tenancy) {
    const tenancyUpperCase = tenancy.toUpperCase();

    if (TENANCIES.includes(tenancyUpperCase)) {
      return tenancyUpperCase;
    }
  }

  return MULTI_TENANCY.DEFAULT;
}

enableMocking().then(() => {
  setTenancy();
  const root = ReactDOM.createRoot(document.getElementById("root")!);
  root.render(<App />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
