import { useEffect, useState } from "react";
import { useBlocker, Blocker, BlockerFunction } from "react-router-dom";
import { Button, Card, Modal, Stack, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { navigationBlockerAtom } from "@/stores";

interface BlockNavigationModalActionButton {
  label: string;
  onClick: (blocker: Blocker) => void;
}

export interface BlockNavigationModalProps {
  title: string;
  message: string;
  isBlocked: boolean | BlockerFunction;
  actionButtons?: BlockNavigationModalActionButton[];
}

const onUnloadHandler = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  return null;
};

export function BlockNavigationModal() {
  const { title, message, isBlocked, actionButtons } = useAtomValue(navigationBlockerAtom);
  const blocker = useBlocker(isBlocked);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setOpen(true);
    }
  }, [blocker, setOpen]);

  useEffect(() => {
    if (isBlocked) {
      window.addEventListener("beforeunload", onUnloadHandler);
    } else {
      window.removeEventListener("beforeunload", onUnloadHandler);
    }
  }, [isBlocked]);

  return (
    <Modal
      data-testid="block-navigation-modal-id"
      open={open}
      aria-labelledby="navigation-blocker-modal-title"
      aria-describedby="navigation-blocker-modal-description"
    >
      <Card
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30rem",
          bgcolor: "background.paper",
          border: "1px solid #000",
          boxShadow: 24,
          p: 2
        }}
      >
        <Typography id="navigation-blocker-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="navigation-blocker-modal-description" sx={{ marginTop: 2, width: "100%" }}>
          {message}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" marginTop={2}>
          {actionButtons ? (
            actionButtons.map(({ label, onClick }, index) => {
              return (
                <Button
                  key={`${label}-${index}`}
                  variant="text"
                  onClick={() => {
                    onClick(blocker);
                    setOpen(false);
                  }}
                >
                  {label}
                </Button>
              );
            })
          ) : (
            <Button
              variant="text"
              onClick={() => {
                setOpen(false);
                blocker.reset?.();
              }}
            >
              Ok
            </Button>
          )}
        </Stack>
      </Card>
    </Modal>
  );
}
