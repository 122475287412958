import axios from "axios";
import { APIException } from "@/interfaces";
import { handleAxiosError } from "./apiHelper";
import { msalInstance, scopes } from "@/authentication";
import { convertNullToUndefined } from "@/utils";
import { IS_TEST_MODE, MULTI_TENANCY } from "@/constants";

export const apiClient = axios.create({
  baseURL: import.meta.env.REACT_APP_BACKEND_URL
});

const getTenancy = () => window.localStorage.getItem(MULTI_TENANCY.STORAGE) ?? MULTI_TENANCY.DEFAULT;

apiClient.interceptors.request.use(async (config) => {
  if (IS_TEST_MODE) {
    return config;
  }

  const token = await msalInstance
    .acquireTokenSilent({ scopes })
    .then((response) => response.accessToken)
    .catch((error) => {
      console.error("MSAL Error: ", error);
    });

  config.headers.Authorization = `Bearer ${token}`;
  config.headers[MULTI_TENANCY.HEADER] = getTenancy();

  return config;
});

apiClient.interceptors.response.use((response) => {
  if (response.data) {
    response.data = convertNullToUndefined(response.data);
  }
  return response;
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError<APIException>(error)) {
      if (import.meta.env.DEV) {
        handleAxiosError(error);
      }

      throw error.response?.data;
    }

    throw error;
  }
);
