import Typography from "@mui/material/Typography";
import { Grid, Link, Paper, Stack, Switch } from "@mui/material";
import { UserAvatar } from "./UserAvatar";
import { useAuthorization } from "@/context";
import { UserRole } from "@/interfaces";
import { PartialRecord } from "@/types";

function PermissionToggles() {
  const { isRequestor, isCoordinator, isSME, isEndorser, isAdmin, setRole } = useAuthorization();

  const roleToggles: PartialRecord<UserRole, string> = {
    [UserRole.Requestor]: "Requestor",
    [UserRole.Coordinator]: "Coordinator",
    [UserRole.TabOwner]: "SME",
    [UserRole.Endorser]: "Endorser",
    [UserRole.Administrator]: "Admin"
  };

  const checkedStates: PartialRecord<UserRole, boolean> = {
    [UserRole.Requestor]: isRequestor,
    [UserRole.Coordinator]: isCoordinator,
    [UserRole.TabOwner]: isSME,
    [UserRole.Endorser]: isEndorser,
    [UserRole.Administrator]: isAdmin
  };

  return (
    <Stack spacing={1} px={4} py={2}>
      {Object.entries(roleToggles).map(([role, name], index) => {
        const checked = checkedStates[role as UserRole];
        return (
          <Stack key={`${name}-${index}`} direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ p: 1 }}>{name}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Switch
                data-testid={`${name}ToggleSwitch`}
                checked={checked}
                onChange={(_, checked) => {
                  setRole(role as UserRole, checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <div data-testid={`is-${name.toLowerCase()}-mode`}>
                {checked ? <Typography>On</Typography> : <Typography>Off</Typography>}
              </div>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}

export function ProfileCard() {
  const { username, name, signOut } = useAuthorization();
  // TODO: Currently token doesn't include the organisation info,
  // in the future, we should include an organisation attribute into token.
  // Interim solution is hardcoded it.
  const organisation = "RTIO";
  const { REACT_APP_VIEW_PROFILE_URL } = import.meta.env;

  return (
    <Paper>
      <Grid container flexDirection={"column"} paddingLeft="1.5em" columnSpacing={3} display={"flex"}>
        <Stack direction="row" p={2} alignItems="center" justifyContent="space-between">
          <Typography fontStyle="bold">{organisation}</Typography>
          <Link data-testid="SignOutButtonId" onClick={signOut} style={{ cursor: "pointer" }}>
            <Typography> Sign Out</Typography>
          </Link>
        </Stack>
        <Grid item flexDirection={"row"} padding="2em" width="25em" paddingLeft="5em" display={"flex"}>
          <Grid item alignItems={"center"}>
            <UserAvatar avatarWidth={"2.5em"} avatarHeight={"2.5em"} fontSize={"2.5rem"} />
          </Grid>

          <Grid item paddingLeft={"1em"}>
            <Grid sx={{ flexDirection: "column" }}>
              <Typography fontSize={25}>
                <b>{`${name}`}</b>
              </Typography>
              <Typography fontSize={10}>{username}</Typography>
              <a
                data-testid="ViewAccountButtonId"
                style={{ paddingTop: "2em" }}
                target="_blank"
                rel="noreferrer"
                href={REACT_APP_VIEW_PROFILE_URL}
              >
                <Typography fontSize={10}> View account</Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <PermissionToggles />
      </Grid>
    </Paper>
  );
}
