import { Dispatch, SetStateAction, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { DisciplineTrackingComment } from "@/interfaces";

interface AddTrackingCommentNoteProp {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  disciplineTrackingComment: DisciplineTrackingComment;
  addTrackingCommentNote: (newNote: string) => void;
}

export function AddTrackingCommentNote({
  open,
  setOpen,
  disciplineTrackingComment,
  addTrackingCommentNote
}: AddTrackingCommentNoteProp) {
  const [addNote, setAddNote] = useState<string>("");
  const cancelClickHandler = () => {
    setAddNote("");
    setOpen(false);
  };
  const addClickHandler = () => {
    addTrackingCommentNote(addNote);
    setAddNote("");
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={cancelClickHandler} fullWidth={true} maxWidth="md" sx={{ padding: "1.5rem" }}>
      <DialogTitle>Add Note</DialogTitle>
      <DialogContentText sx={{ pl: "1.5rem" }}>{disciplineTrackingComment.comment}</DialogContentText>
      <DialogContent>
        <TextField
          fullWidth
          id="outlined-multiline-tracking-comment-note"
          label="Note"
          multiline
          rows={5}
          value={addNote}
          inputProps={{ maxLength: 500 }}
          onChange={(event) => {
            setAddNote(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelClickHandler}>Cancel</Button>
        <Button disabled={addNote === null || addNote.trim() === ""} onClick={addClickHandler}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
