import { FormControlLabel, Grid, Radio, RadioGroup, SxProps, Typography } from "@mui/material";
import { InputHTMLAttributes } from "react";
import { QuestionAnswerProps } from "./QuestionComponent";

const spacingNormal: SxProps = {
  ml: 2
};

const flexItemStyles: SxProps = {
  display: "flex",
  p: 1
};

const disabledTypeStyles: SxProps = {
  color: "rgba(0, 0, 0, 0.38)"
};

export function QuestionRadioButton({ questionAnswer, readOnly, handleQuestionAnswerChange }: QuestionAnswerProps) {
  function QuestionRadio({ value, displayOption }: { value: string; displayOption?: boolean }) {
    return (
      <Grid item sx={spacingNormal}>
        <FormControlLabel
          value={value}
          control={
            <Radio
              inputProps={
                {
                  "data-testid": `radio-question-${value}-${questionAnswer.questionId}`
                } as InputHTMLAttributes<HTMLInputElement>
              }
            />
          }
          label=""
          disabled={questionAnswer.isDisplayOnly || readOnly || (displayOption ?? false)}
        />
      </Grid>
    );
  }

  return (
    <RadioGroup
      sx={{ display: "flex", flexWrap: "nowrap" }}
      row
      value={
        questionAnswer.answer && questionAnswer.answer !== ""
          ? questionAnswer.answer
          : (questionAnswer.defaultValue ?? "")
      }
      id={`question_${questionAnswer.questionId}`}
      key={`${questionAnswer.questionId}`}
      onChange={(event) => handleQuestionAnswerChange(questionAnswer, event.target.value)}
    >
      <QuestionRadio value="yes" />
      <QuestionRadio value="no" />
      <QuestionRadio value="partial" displayOption={questionAnswer.componentName !== "radioPartial"} />
      <Grid item sx={flexItemStyles} ml={6}>
        <Typography
          variant="body1"
          sx={{
            ...(questionAnswer.isDisplayOnly ? disabledTypeStyles : {})
          }}
        >
          {questionAnswer.question}
        </Typography>
      </Grid>
    </RadioGroup>
  );
}
