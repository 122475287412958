import { Button, Card, CardHeader, CardActions, Stack, useTheme } from "@mui/material";
import { Editor } from "@tiptap/core/dist/packages/core/src/Editor";

import { useAuthorization, useCollaborationContext } from "@/context";
import { RichTextEditor, RichTextEditorRef } from "@/components/rich-text-editor/RichTextEditor";
import { Comment } from "./Comment";
import { createRef, useState } from "react";
import { UnreadBadge } from "@/components/UnreadBadge";
import { BADGE_POSITION } from "@/constants";
import { UserAvatar } from "@/components/navigation";

interface CommentAvatarBadgeProps {
  userId: string;
  userName: string;
  showBadge: boolean;
  isUnreadReplyComment: boolean;
  notificationId?: string;
}

export function CommentAvatarBadge({
  userId,
  userName,
  showBadge,
  isUnreadReplyComment,
  notificationId
}: CommentAvatarBadgeProps) {
  const theme = useTheme();
  const { unreadTargetClassName } = useCollaborationContext();
  return (
    <Stack
      id={notificationId ?? ""}
      className={notificationId || isUnreadReplyComment ? unreadTargetClassName : ""}
      sx={{ pl: "35%" }}
    >
      <UnreadBadge visible={showBadge} badgePosition={BADGE_POSITION.DISJOINT_CENTER_LEFT}>
        <UserAvatar
          avatarWidth={"2.625rem"}
          avatarHeight={"2.625rem"}
          fontSize={theme.typography.fontSize.toString()}
          avatarName={userName}
          avatarUserId={userId}
          showTooltip={true}
        />
      </UnreadBadge>
    </Stack>
  );
}

export function CollaborationTab() {
  const {
    commentaries,
    commentLayoutClassName,
    CollaborationCard,
    handleClearContent,
    handleOnBlur,
    handleEscapeKeyPressed,
    addComment,
    setCommentBoxProps,
    commentBoxProps
  } = useCollaborationContext();
  const { userId, name } = useAuthorization();
  const [isSaveEnable, setIsSaveEnable] = useState<boolean>(false);
  const editorRef = createRef<RichTextEditorRef>();

  const renderCommentButtons = () => {
    return (
      <>
        <Button
          variant="outlined"
          sx={{ minWidth: "5rem" }}
          disabled={!isSaveEnable}
          onClick={() => {
            addComment(editorRef.current?.getContent(true) ?? "");
            handleClearContent(editorRef.current);
          }}
          data-testid="save-new-comment-button"
        >
          Save
        </Button>
        <Button
          variant="outlined"
          sx={{ minWidth: "5rem" }}
          onClick={() => handleClearContent(editorRef.current)}
          data-testid="cancel-new-comment-button"
        >
          Cancel
        </Button>
      </>
    );
  };

  return (
    <Stack className={commentLayoutClassName}>
      <CollaborationCard>
        <CardHeader
          avatar={
            <CommentAvatarBadge userId={userId!} userName={name!} showBadge={false} isUnreadReplyComment={false} />
          }
          title={
            <Stack sx={{ pt: "1.5rem", pb: "1.5rem", flexDirection: "row" }}>
              <Card variant="outlined" sx={{ minHeight: "10rem", width: "100%" }}>
                <RichTextEditor
                  ref={editorRef}
                  placeholder="Add a comment. Use @ to mention a person."
                  hideMenuBar={!commentBoxProps.showMenuBar}
                  onFocus={() => setCommentBoxProps({ ...commentBoxProps, showMenuBar: true })}
                  onBlur={() => handleOnBlur(editorRef.current)}
                  onChange={(editor: Editor) => setIsSaveEnable(editor.getText().trim().length > 0)}
                  onKeyDown={(event) => handleEscapeKeyPressed(event, editorRef.current)}
                  hasMention={true}
                  data-testid="input-comment-editor"
                />
              </Card>
            </Stack>
          }
        />
        <CardActions
          sx={{
            display: commentBoxProps.showMenuBar ? "flex" : "none",
            float: "right",
            my: "1rem",
            pr: "0rem"
          }}
        >
          {commentBoxProps.showMenuBar && renderCommentButtons()}
        </CardActions>
      </CollaborationCard>
      {commentaries.map((commentary) => (
        <Comment commentary={commentary}></Comment>
      ))}
    </Stack>
  );
}
