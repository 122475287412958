import { Card, CardHeader, CardContent } from "@mui/material";
import { AdminTable } from "./AdminTable";

export interface ConfigurationItemCardProps {
  title: string;
  subheader: string;
}

export function ConfigurationItemCard(props: ConfigurationItemCardProps) {
  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader title={props.title} subheader={props.subheader} />
      <CardContent>
        <AdminTable />
      </CardContent>
    </Card>
  );
}
