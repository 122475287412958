import { Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { UserRole } from "@/interfaces";
import { AuthorizedComponent, Loading } from "@/components";
import { SettingItemList } from "@/features/admin/components/SettingsItemList";
import { Suspense } from "react";

export function AdminSettingsLayout() {
  return (
    <Box sx={{ display: "flex", columnGap: "1.5rem", padding: "1.5rem" }}>
      <AuthorizedComponent mode="whitelist" roleCriteria={[UserRole.Administrator]}>
        <SettingItemList />
        <Suspense fallback={<Loading prefix="Admin Configuration" />}>
          <Stack sx={{ flexGrow: 1 }}>
            <Outlet />
          </Stack>
        </Suspense>
      </AuthorizedComponent>
    </Box>
  );
}
