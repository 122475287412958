import { createContext, PropsWithChildren, useContext, useMemo } from "react";

import { Notification, NotificationEvent } from "@/interfaces";
import { useNotifications } from "@/hooks";

export interface NotificationsContextType {
  allNotifications: Notification[];
  unreadNotifications: Notification[];
  unreadNotificationsCount: number;
  navBarPopperNotifications: Notification[];
  navBarPopperAlertNotificationCount: number;
  pushNotifications: Notification[];
}

export const NotificationsContext = createContext<NotificationsContextType>({
  allNotifications: [],
  unreadNotifications: [],
  unreadNotificationsCount: 0,
  navBarPopperNotifications: [],
  navBarPopperAlertNotificationCount: 0,
  pushNotifications: []
});

export const useNotificationsContext = () => useContext(NotificationsContext);

export function NotificationsProvider({ children }: PropsWithChildren) {
  const { notifications: allNotifications } = useNotifications(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  );
  const unreadNotifications: Notification[] = useMemo(
    () => allNotifications.filter((n) => n.isUnread),
    [allNotifications]
  );
  const navBarPopperNotifications: Notification[] = useMemo(
    () => allNotifications.filter((n) => n.isInApp || n.isActionable || n.isPersist),
    [allNotifications]
  );

  const navBarPopperAlertNotificationCount = navBarPopperNotifications.filter(
    (n) => n.isInApp || n.isActionable
  ).length;

  const pushNotifications = useMemo(
    () =>
      allNotifications.filter(
        (n) =>
          n.isRealTime && (n.event === NotificationEvent.ARAmended || n.event === NotificationEvent.ARReturnRequested)
      ),
    [allNotifications]
  );

  const contextValue = {
    allNotifications,
    unreadNotifications,
    unreadNotificationsCount: unreadNotifications.length,
    navBarPopperNotifications,
    navBarPopperAlertNotificationCount,
    pushNotifications
  };

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
}
