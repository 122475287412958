import { IOption } from "@/interfaces";

export const LAYER_STATUS_OPTIONS: IOption[] = [
  { id: "1", value: "Enabled" },
  { id: "2", value: "Disabled" }
];

export const LAYER_TYPE_OPTIONS: IOption[] = [
  { id: "1", value: "Approval Request" },
  { id: "2", value: "Permit" }
];

export const INTERCEPT_RULE_LAYER_TYPE_OPTIONS: IOption[] = [
  { id: "1", value: "Exclusion" },
  { id: "2", value: "Restriction" }
];
