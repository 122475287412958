import { Grid, Typography } from "@mui/material";

import { AR_SHOW_DATE_LOCATORS } from "@/constants";
import { formatDate } from "@/utils";

interface ARShowDateProps {
  permitExpiryDate: Date | null;
}

export function ARShowDate({ permitExpiryDate }: ARShowDateProps) {
  return (
    <>
      <Typography>Date</Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item>
          <Typography data-testid={AR_SHOW_DATE_LOCATORS.PERMIT_EXPIRY_DATE_LABEL} color="grey">
            Permit Expiry
          </Typography>
        </Grid>
        <Grid item data-testid={AR_SHOW_DATE_LOCATORS.PERMIT_EXPIRY_DATE_TEXT}>
          {formatDate(permitExpiryDate)}
        </Grid>
      </Grid>
    </>
  );
}
