import { useEffect, useState } from "react";
import { Grid, SxProps, Typography } from "@mui/material";
import { FieldError, FieldErrorsImpl, Merge, useFormContext } from "react-hook-form";
import { ARFormSection, ARFormValues, QAAnswerItem, QuestionAnswer } from "@/interfaces";
import { useARContext } from "@/context";
import { ARDivider } from "@/components";
import { withARAwareReadOnly } from "@/components/hocs";
import { filterAndMapToQAAnswerItem, isConditionalARQuestion } from "@/utils";
import { ChangeType } from "@/types";
import { QuestionComponent } from "./QuestionComponent";

// This complicated type is taken from the generated type from hook-form.
// It substitutes the actual object structure from the stored data type.

type QAValidationErrors = Merge<
  FieldError,
  (Merge<FieldError, FieldErrorsImpl<ChangeType<QAAnswerItem, string>>> | undefined)[]
>;

const titleStyle: SxProps = {
  fontSize: "1.2em",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
};

const spacingNormal: SxProps = {
  ml: 2
};

const spacingLarge: SxProps = {
  ml: 5
};

interface ARQuestionAnswersProps {
  errors?: QAValidationErrors;
  readOnly?: boolean;
  resetError?: () => void;
  formHelperErrorClass?: string;
  questionHightlightId?: string;
}

export function ARQuestionAnswers({
  readOnly,
  resetError,
  questionHightlightId,
  formHelperErrorClass,
  errors
}: ARQuestionAnswersProps) {
  const { questionsAnswers, setAllQuestionsAnswered } = useARContext();
  const { setValue } = useFormContext<ARFormValues>();

  const [approvalRequestQuestionAnswers, setApprovalRequestQuestionAnswers] =
    useState<QuestionAnswer[]>(questionsAnswers);

  useEffect(() => {
    setApprovalRequestQuestionAnswers(questionsAnswers);
  }, [questionsAnswers]);

  useEffect(() => {
    if (!approvalRequestQuestionAnswers) {
      setAllQuestionsAnswered(true);
      return;
    }
    const qaItems = filterAndMapToQAAnswerItem(approvalRequestQuestionAnswers);

    const allQuestionAnswered = qaItems.every((item) => {
      return (!item.isConditional && item.answer !== "") || item.isConditional;
    });
    setAllQuestionsAnswered(allQuestionAnswered);
  }, [approvalRequestQuestionAnswers, setAllQuestionsAnswered]);

  const handleQuestionAnswerChange = (questionAnswer: QuestionAnswer, targetAnswer: string) => {
    resetError?.();
    const updatedArQuestionAnswers: QuestionAnswer[] = approvalRequestQuestionAnswers.map((ans) => {
      if (ans.questionId === questionAnswer.questionId) {
        return {
          ...ans,
          answer: targetAnswer,
          subQuestionAnswers: ans.subQuestionAnswers.map((subAns) => {
            return {
              ...subAns,
              isConditional: isConditionalARQuestion(targetAnswer),
              answer: isConditionalARQuestion(targetAnswer) ? (subAns.defaultValue ?? "") : subAns.answer
            };
          })
        };
      } else if (questionAnswer.parentId && ans.questionId === questionAnswer.parentId) {
        return {
          ...ans,
          subQuestionAnswers: ans.subQuestionAnswers.map((subAns) => {
            if (subAns.questionId === questionAnswer.questionId)
              return {
                ...subAns,
                answer: targetAnswer
              };
            return subAns;
          })
        };
      }
      return ans;
    });
    setApprovalRequestQuestionAnswers(updatedArQuestionAnswers);
    setValue("approvalRequestAnswers", filterAndMapToQAAnswerItem(updatedArQuestionAnswers), { shouldDirty: true });
  };

  return approvalRequestQuestionAnswers && approvalRequestQuestionAnswers.length > 0 ? (
    <>
      <ARDivider />
      <Typography sx={titleStyle} gutterBottom variant="h6">
        Questions
      </Typography>
      <br />
      <Grid container sx={spacingNormal}>
        <Grid container xs={12}>
          <Grid item sx={spacingNormal}>
            <Typography>Yes</Typography>
          </Grid>
          <Grid item sx={spacingLarge}>
            <Typography>No</Typography>
          </Grid>
          <Grid item sx={spacingLarge}>
            <Typography>Partial</Typography>
          </Grid>
        </Grid>
        {approvalRequestQuestionAnswers.map((questionAnswer) => {
          return (
            <>
              <QuestionComponent
                questionAnswer={questionAnswer}
                handleQuestionAnswerChange={handleQuestionAnswerChange}
                readOnly={readOnly ?? false}
                questionHightlightId={questionHightlightId}
                formHelperErrorClass={formHelperErrorClass}
                error={errors?.[questionAnswer.displayOrder - 1]?.answer?.message}
              />

              {questionAnswer.subQuestionAnswers?.map(
                (subQuestionAnswer) =>
                  (!subQuestionAnswer.isConditional || subQuestionAnswer.displayLevel === 2) && (
                    <QuestionComponent
                      questionAnswer={subQuestionAnswer}
                      handleQuestionAnswerChange={handleQuestionAnswerChange}
                      readOnly={readOnly !== undefined && readOnly ? readOnly : subQuestionAnswer.isConditional}
                      questionHightlightId={questionHightlightId}
                      formHelperErrorClass={formHelperErrorClass}
                      error={errors?.[subQuestionAnswer.displayOrder - 1]?.answer?.message}
                    />
                  )
              )}
            </>
          );
        })}
      </Grid>
    </>
  ) : null;
}

export const ARAwareReadOnlyQuestionAnswers = withARAwareReadOnly(ARFormSection.Question, ARQuestionAnswers);
