import { Grid, Paper, SxProps } from "@mui/material";

import { useAuthorization } from "@/context";
import { SMEDashboard } from "@/components/my-requests/dashboards";
import { ExistingApprovalRequestType, NewApprovalRequestType } from "@/features/my-requests/components";

const paperContainerStyle: SxProps = {
  marginTop: { sm: "0.5em", md: "1.5em", lg: "2em" },
  marginLeft: { sm: "0.5em", md: "1.5em", lg: "2em" },
  marginRight: { sm: "0.5em", md: "1.5em", lg: "2em" },
  padding: { sm: "0", md: "1em", lg: "1.5em" }
};

export const MyRequestsPage = () => {
  const { isSME } = useAuthorization();

  return (
    <Grid container>
      {isSME ? (
        <Grid item sm={12}>
          <Paper elevation={0} sx={paperContainerStyle}>
            <SMEDashboard />
          </Paper>
        </Grid>
      ) : (
        <>
          <Grid item sm={12}>
            <Paper elevation={0} sx={paperContainerStyle}>
              <NewApprovalRequestType />
            </Paper>
          </Grid>
          <Grid item sm={12}>
            <Paper elevation={0} sx={paperContainerStyle}>
              <ExistingApprovalRequestType />
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
};
