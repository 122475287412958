import { useNavigate } from "react-router-dom";
import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { IconButton, Stack } from "@mui/material";

import { FILE_NAMES, PATH, TITLE } from "@/constants";
import { colours } from "@/theme/colour";
import { NotificationPopper } from "@/components/notifications/NotificationPopper";
import { ProfilePopper } from "./ProfilePopper";

const StyledAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export function Navbar() {
  const navigate = useNavigate();

  return (
    <Stack id="top-navigation-bar" direction="row" sx={{ height: "4rem", width: "100%;" }}>
      <CssBaseline />
      <StyledAppBar position="fixed" sx={{ backgroundColor: colours.appBar }}>
        <Toolbar>
          <IconButton
            data-testid="HomePageButtonId"
            size="large"
            edge="start"
            aria-label="home"
            style={{ color: colours.iconBackground }}
            sx={{ mr: 2 }}
            onClick={() => {
              navigate(PATH.BASE);
            }}
          >
            <img src={`${import.meta.env.REACT_APP_AUTH_REDIRECT_URI}/images/${FILE_NAMES.APP_BAR}`} alt="Rio Tinto" />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            noWrap
            sx={{
              flexGrow: 1
            }}
            data-testid="NavbarTitleId"
          >
            {TITLE.AppTitle}
          </Typography>
          <Stack direction="row" columnGap={2} alignItems="center" justifyContent="center">
            <NotificationPopper />
            <ProfilePopper />
          </Stack>
        </Toolbar>
      </StyledAppBar>
    </Stack>
  );
}
