import { HighlightLayerProperties } from "@/types";

export const APP_FORM_SELECT_CASCADE = {
  ApprovalRequest: "Approvals Requests",
  PermitRequest: "Permits",
  SurveyRequest: "Survey Only Requests",
  ArcGISService: "ArcGIS Service",
  Layer: "Layer"
};

export const ERROR_MESSAGES = {
  MandatoryPermitLayer: "Permit Layer is required",
  MandatoryService: "ArcGIS Service is required",
  MandatoryLayer: "Layer is mandatory when ArcGIS Service is selected"
};

export const ARCGIS_CONFIG = {
  WidgetPosition: "bottom-right"
};

export const ARCGIS_LAYERS = {
  ApprovalRequest: 0,
  SurveyOnly: 1,
  ApprovalMaintenancePermit: 2,
  ApprovalPermitAreas: 3,
  ApprovalPermitDrillingAreas: 4,
  ApprovalPermitExpiredAreas: 5,
  ExclusionSites: 6,
  RestrictionSites: 7,
  PartIVApproval: 8,
  PartIVApprovalConcept: 9,
  PartIVApprovalConceptPoint: 10,
  PartIVApprovalConceptLine: 11,
  PartIVApprovalConceptPolygon: 12,
  NVCPAreas: 13,
  PrescribedPremisesBoundaries: 14
};

export const ARCGIS_HIGHLIGHT_LAYERS: Record<string, HighlightLayerProperties> = {
  APPROVALS: {
    layerId: "approval_highlight_layer",
    layerTitle: "Approval Highlight Layer",
    fillColour: [0, 255, 255, 0.05],
    outlineColour: [0, 206, 209, 1],
    outlineWidth: "3px"
  },
  INTERCEPTS: {
    layerId: "intercept_highlight_layer",
    layerTitle: "Intercept Highlight Layer",
    fillColour: [255, 0, 0, 0.2],
    outlineColour: [255, 0, 0, 1],
    outlineWidth: "3px"
  }
};

export const ARCGIS_LAYER_TYPES = {
  GROUP_LAYER: "Group Layer"
};
