import { apiClient } from "@/api/client";
import {
  AddArcGISServiceRequest,
  ArcGISServiceItem,
  ArcGISServiceList,
  UpdateArcGISServiceRequest,
  ValidateNameRequest,
  ValidateUrlRequest,
  ArcGISServiceLayers
} from "@/interfaces";

const basePath = "/arcGISService";

export const updateArcGisServiceRequest = (arcgisService: UpdateArcGISServiceRequest) =>
  apiClient.put(basePath, arcgisService);

export const getArcGisServices = (pageNumber: number, pageSize: number) =>
  apiClient
    .get<ArcGISServiceList>(`${basePath}`, {
      params: {
        pageNumber,
        pageSize
      }
    })
    .then((response) => response.data);

export const getArcGisServiceById = (id: string) =>
  apiClient.get<ArcGISServiceItem>(`${basePath}/${id}`).then((response) => response.data);

export const getArcGisServiceDetailsByUrl = (serviceUrl: string) =>
  apiClient
    .get<ArcGISServiceLayers>(`${basePath}/layer`, {
      params: { serviceUrl }
    })
    .then((response) => response.data);

export const createArcGisServiceRequest = (arcgisService: AddArcGISServiceRequest) =>
  apiClient.post(basePath, arcgisService);

export const validateDuplicateName = (name: ValidateNameRequest) =>
  apiClient.post(`${basePath}/validateArcGisDuplicateName`, name);

export const validateDuplicateUrl = (url: ValidateUrlRequest) =>
  apiClient.post(`${basePath}/validateArcGisDuplicateUrl`, url);

export const validateValidUrl = (url: ValidateUrlRequest) => apiClient.post(`${basePath}/validateArcGisValidUrl`, url);
