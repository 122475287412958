import { Box } from "@mui/material";

import { RichTextEditor } from "@/components/rich-text-editor";

interface ConfigurableListContentProps {
  listItems: string[];
}
export function ConfigurableListContent({ listItems }: ConfigurableListContentProps) {
  return (
    <Box component="ul" pl="1.5rem">
      {listItems.map((item, index) => (
        <li key={index}>
          <RichTextEditor defaultValue={item} readOnly={true} hideMenuBar={true} />
          {item}
        </li>
      ))}
    </Box>
  );
}
