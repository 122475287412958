import { DatePicker, DatePickerProps } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";

interface DateInputFieldProps extends DatePickerProps<Dayjs> {
  error?: string;
}

export function DateInputField({ error, sx, slotProps, ...props }: DateInputFieldProps) {
  return (
    <DatePicker
      {...props}
      sx={{
        ...sx,
        mb: !error ? 1 : undefined,
        alignSelf: "flex-start"
      }}
      slotProps={{
        ...slotProps,
        textField: {
          ...slotProps?.textField,
          error: !!error,
          helperText: error
        }
      }}
    />
  );
}
