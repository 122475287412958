import { atom, useAtom } from "jotai";
import { interceptResultAtom } from "@/stores";
import { InterceptResult } from "@/types";

// Web Map Extent Derived Atom
const SetInterceptResult = atom(
  (get) => get(interceptResultAtom),
  (_get, set, interceptResult?: InterceptResult) => {
    set(interceptResultAtom, interceptResult);
  }
);

export const useInterceptResult = () => {
  return useAtom(SetInterceptResult);
};
