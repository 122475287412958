import { snackBarAtom } from "@/stores";
import { Close } from "@mui/icons-material";
import { IconButton, Snackbar } from "@mui/material";
import { useAtomValue } from "jotai";
import { useResetAtom } from "jotai/utils";

export function SnackBarComponent() {
  const { open, message } = useAtomValue(snackBarAtom);
  const resetSnackBarAtom = useResetAtom(snackBarAtom);

  return (
    <Snackbar
      open={open}
      message={message}
      onClose={() => {
        resetSnackBarAtom();
      }}
      action={
        <IconButton
          onClick={() => {
            resetSnackBarAtom();
          }}
          size="small"
          aria-label="close"
          color="inherit"
        >
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
}
