import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, Grid, Typography } from "@mui/material";
import { GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid-pro";

import { ARFormValues, ApprovalRequestWorkCategoryItem } from "@/interfaces";
import { useARContext } from "@/context";

interface ARWorkCategoryToolbarProps {
  approvalRequestId: string;
  isAbleToAddMoreRow: boolean;
  arWorkCategoryList: ApprovalRequestWorkCategoryItem[];
  setARWorkCategoryList: Dispatch<SetStateAction<ApprovalRequestWorkCategoryItem[]>>;
  readOnly: boolean;
}

export const ARWorkCategoryToolbar = ({
  approvalRequestId,
  isAbleToAddMoreRow,
  arWorkCategoryList,
  setARWorkCategoryList,
  readOnly
}: ARWorkCategoryToolbarProps) => {
  const apiRef = useGridApiContext();

  const { allQuestionsAnswered } = useARContext();
  const { setValue } = useFormContext<ARFormValues>();

  const [isAddButtonReady, setIsAddButtonReady] = useState(true);

  const handleOnAddRowClick = useCallback(() => {
    setIsAddButtonReady(false);
    const newList = [...arWorkCategoryList];

    const newRow = {
      answer: 0,
      approvalRequestId: approvalRequestId,
      displayOrder: Math.max(...newList.map((x) => x.displayOrder), 0) + 1,
      isMandatory: false
    } as ApprovalRequestWorkCategoryItem;

    newList.push(newRow);
    setARWorkCategoryList(newList);
    setValue("approvalRequestWorkCategories", newList, {
      shouldDirty: true
    });

    // setTimeout to solve race condition on keydown, startCellEditMode not trigger issue
    // https://stackoverflow.com/a/66950184
    setTimeout(() => {
      // Focus on newly added line
      if (apiRef.current.getCellMode(newRow.displayOrder, "workCategoryId") === "view")
        apiRef.current.startCellEditMode({
          id: newRow.displayOrder,
          field: "workCategoryId"
        });
      setIsAddButtonReady(true);
    }, 1);
  }, [apiRef, approvalRequestId, arWorkCategoryList, setARWorkCategoryList, setValue]);

  return (
    <GridToolbarContainer>
      <Grid container spacing={3} paddingBottom={5}>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography variant="h6">Work Categorisation</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            size="medium"
            disabled={!isAbleToAddMoreRow || !allQuestionsAnswered || !isAddButtonReady || readOnly}
            onClick={handleOnAddRowClick}
            data-testid={"button-add-workcategory"}
          >
            ADD
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};
