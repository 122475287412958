import { PropsWithChildren, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { UserRole } from "@/interfaces";
import { PATH } from "@/constants";
import { useAuthorization } from "@/context";

interface AuthorizedComponentProps {
  mode: "whitelist" | "blacklist";
  roleCriteria: UserRole[];
}

export function AuthorizedComponent({ children, mode, roleCriteria }: PropsWithChildren<AuthorizedComponentProps>) {
  const navigate = useNavigate();
  const { roles } = useAuthorization();

  const blocked = useMemo(
    () => mode === "blacklist" && roleCriteria.some((role) => roles.includes(role)),
    [mode, roleCriteria, roles]
  );

  const allowed = useMemo(
    () => mode === "whitelist" && roleCriteria.some((role) => roles.includes(role)),
    [mode, roleCriteria, roles]
  );

  if (!blocked || allowed || roleCriteria.length === 0) {
    return children;
  }

  navigate(PATH.BASE);
  return null;
}
