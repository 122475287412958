import Table from "@tiptap/extension-table";

export const PreserveTableAttributes = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      id: {
        renderHTML: (attributes) => {
          return {
            id: attributes.id
          };
        }
      },
      class: {
        renderHTML: (attributes) => {
          return {
            class: attributes.class
          };
        }
      }
    };
  }
});
