import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  AMEND_PERMIT_MUTATION_KEY,
  APPROVAL_REQUEST_MAP_QUERY_KEY,
  APPROVAL_REQUEST_PATH,
  APPROVAL_REQUEST_QUERY_KEY,
  AR_COMMENTARIES_QUERY_KEY,
  AR_DISCIPLINE_QUERY_KEY,
  AR_LIST_QUERY_KEY,
  AR_STATUS_TIMELINE_QUERY_KEY,
  AR_SUBMIT_MAP_ERROR_BANNER,
  AR_UNEXPECTED_ERROR_BANNER,
  ASSIGNED_ARS_TO_SME_QUERY_KEY,
  ATTACHMENT_COUNT_QUERY_KEY,
  DISTRIBUTE_APPROVAL_REQUEST_MUTATION_KEY,
  EMPTY_PAGINATED_RESPONSE,
  NOTIFICATIONS_QUERY_KEY,
  PAST_APPROVAL_REQUEST_QUERY_KEY,
  PATH,
  PERMIT_QUERY_KEY,
  QA_ANSWERS_QUERY_KEY,
  RETURN_DRAFT_PERMIT_MUTATION_KEY,
  SEARCH_RESULT_QUERY_KEY,
  WC_ANSWERS_QUERY_KEY,
  WC_LIST_QUERY_KEY,
  WC_SUBCATEGORY_LIST_QUERY_KEY
} from "@/constants";
import {
  AddApprovalRequestMap,
  AmendPermitRequest,
  ApprovalRequestBase,
  ApprovalRequestList,
  ApprovalRequestStatus,
  ApprovalRequestVersioned,
  ARSearchResult,
  ARStatusTimeline,
  CreateNewARRequest,
  DistributeARRequest,
  GetARListRequest,
  GetARWorkCategoryListByResponse,
  GetSMEListRequest,
  GetWorkCategoryListResponse,
  GetWorkSubCategoryListResponse,
  MapItem,
  PaginatedList,
  QuestionAnswer,
  ReturnApprovalRequest,
  ReturnDraftPermitRequest,
  SMEAssignedApprovalRequestList,
  UpdateApprovalRequest,
  WCAnswerItem
} from "@/interfaces";
import { createNewApprovalRequest, returnApprovalRequest } from "@/services";
import { useARContext } from "@/context";
import { generatePath, useNavigate } from "react-router-dom";
import { apiClient } from "@/api/client";
import { isConditionalARQuestion } from "@/utils";

const approvalRequest = "/approvalRequest";
const approvalRequestReturn = "/approvalRequestReturn";
const smeApprovalRequest = "/approvalRequestDiscipline";

const reviewStatuses = [ApprovalRequestStatus.Submitted, ApprovalRequestStatus.Distributed];

export function useApprovalRequestById(id?: string) {
  const navigate = useNavigate();

  return useQuery({
    queryKey: [APPROVAL_REQUEST_QUERY_KEY, id],
    queryFn: () => apiClient.get<ApprovalRequestBase>(`${approvalRequest}/${id}`).then((response) => response.data),
    enabled: id !== undefined,
    onError: () => {
      navigate(PATH.BASE, { replace: true });
    }
  });
}

export function usePastApprovalRequestById(id: string, version?: string, onError?: () => void) {
  return useQuery({
    queryKey: [PAST_APPROVAL_REQUEST_QUERY_KEY, id, version],
    queryFn: () =>
      apiClient
        .get<ApprovalRequestVersioned>(`${approvalRequest}/${id}/version/${version}`)
        .then((response) => response.data),
    enabled: version !== undefined,
    onError: () => {
      onError?.();
    }
  });
}

export function useQuestionAndAnswers(approvalRequestId?: string, approvalRequestTypeId?: string) {
  return useQuery({
    queryKey: [QA_ANSWERS_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient
        .get<QuestionAnswer[]>(`${approvalRequest}/questionAnswer`, {
          params: {
            approvalRequestId,
            approvalRequestTypeId,
            isEnabled: true
          }
        })
        .then((response) => {
          const result = response.data as QuestionAnswer[];
          return result.map((question) => {
            return {
              ...question,
              subQuestionAnswers: question.subQuestionAnswers.map((sub) => {
                return {
                  ...sub,
                  isConditional: isConditionalARQuestion(question.answer)
                };
              })
            };
          });
        }),
    enabled: approvalRequestId !== undefined && approvalRequestTypeId !== undefined,
    initialData: []
  });
}

export function useWorkCategories(approvalRequestTypeId?: string) {
  return useQuery({
    queryKey: [WC_LIST_QUERY_KEY, approvalRequestTypeId],
    queryFn: () =>
      apiClient
        .get<GetWorkCategoryListResponse>(`/WorkCategory`, {
          params: {
            approvalRequestTypeId,
            isEnabled: true
          }
        })
        .then((response) => response.data.optionList),
    enabled: approvalRequestTypeId !== undefined,
    initialData: []
  });
}

export function useWorkSubCategories(workCategoryId?: string) {
  return useQuery({
    queryKey: [WC_SUBCATEGORY_LIST_QUERY_KEY, workCategoryId],
    queryFn: () =>
      apiClient
        .get<GetWorkSubCategoryListResponse>("/WorkSubCategory", {
          params: {
            workCategoryId,
            isEnabled: true
          }
        })
        .then((response) => response.data.workSubCategoryList),
    initialData: []
  });
}

export function useWorkCategoryAnswers(
  approvalRequestId?: string,
  onSuccessCallback?: (wcAnswers: WCAnswerItem[]) => void
) {
  return useQuery({
    queryKey: [WC_ANSWERS_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient
        .get<GetARWorkCategoryListByResponse>("/ApprovalRequestWorkCategory", {
          params: { approvalRequestId }
        })
        .then((response) => response.data.approvalRequestWorkCategoryList),
    onSuccess: (data) => {
      onSuccessCallback?.(data);
    },
    enabled: approvalRequestId !== undefined,
    initialData: []
  });
}

export function useUpdateApprovalRequest(approvalRequestId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: UpdateApprovalRequest) =>
      apiClient.put<ApprovalRequestBase>(`${approvalRequest}/${request.id}`, request).then((response) => response.data),
    onSuccess: (response) => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([QA_ANSWERS_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([WC_ANSWERS_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([APPROVAL_REQUEST_MAP_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([PAST_APPROVAL_REQUEST_QUERY_KEY, approvalRequestId, response.currentVersion]);
      queryClient.invalidateQueries([AR_STATUS_TIMELINE_QUERY_KEY, approvalRequestId]);
      reviewStatuses.includes(response.approvalRequestStatus) &&
        queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY]);
      queryClient.invalidateQueries([PERMIT_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useDistributeApprovalRequest(approvalRequestId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [DISTRIBUTE_APPROVAL_REQUEST_MUTATION_KEY, approvalRequestId],
    mutationFn: (request: DistributeARRequest) =>
      apiClient.put(`${approvalRequest}/${approvalRequestId}/distribute`, request).then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_STATUS_TIMELINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY]);
    }
  });
}

export function useDeleteApprovalRequest() {
  return useMutation({
    mutationFn: (approvalRequestId: string) =>
      apiClient.delete<boolean>(`${approvalRequest}/draft/${approvalRequestId}`).then((response) => response.data)
  });
}

export function useReturnApprovalRequest(
  approvalRequestId: string,
  onSuccessCallback: (data: ApprovalRequestBase) => void
) {
  const queryClient = useQueryClient();
  const { appendAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (returnApprovalRequestDetails: ReturnApprovalRequest) =>
      returnApprovalRequest(returnApprovalRequestDetails),
    onSuccess: (data) => {
      onSuccessCallback(data);
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_STATUS_TIMELINE_QUERY_KEY, approvalRequestId]);
      reviewStatuses.includes(data.approvalRequestStatus) &&
        queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY]);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}

export function useReturnDraftPermit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [RETURN_DRAFT_PERMIT_MUTATION_KEY],
    mutationFn: (request: ReturnDraftPermitRequest) => apiClient.post(`${approvalRequestReturn}/draft-permit`, request),
    onSuccess: (_, { approvalRequestId }) => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_STATUS_TIMELINE_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useCreateApprovalRequest() {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (request: CreateNewARRequest) => createNewApprovalRequest(request),
    onSuccess: (response) => {
      const path = generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: response.id });
      navigate(path);
    }
  });
}

export function useGetARSearch(
  searchTerm?: string,
  peopleSearchTerm?: string,
  hubId?: string,
  siteId?: string,
  businessUnitId?: string,
  projectId?: string,
  approvalRequestTypeId?: string,
  clearingOption?: string,
  pageNumber: number = 1,
  pageSize: number = 50
) {
  return useQuery({
    queryKey: [SEARCH_RESULT_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<PaginatedList<ARSearchResult>>(`${approvalRequest}/search`, {
          params: {
            searchTerm,
            peopleSearchTerm,
            hubId,
            siteId,
            businessUnitId,
            projectId,
            approvalRequestTypeId,
            clearingOption,
            pageNumber,
            pageSize
          }
        })
        .then((response) => response.data)
  });
}

export function useApprovalRequestList(params: GetARListRequest) {
  return useQuery({
    queryKey: [AR_LIST_QUERY_KEY, params],
    queryFn: () =>
      apiClient
        .get<ApprovalRequestList>(approvalRequest, {
          params: { ...params },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    initialData: EMPTY_PAGINATED_RESPONSE
  });
}

export function useSMEAssignedARsList(params: GetSMEListRequest) {
  return useQuery({
    queryKey: [ASSIGNED_ARS_TO_SME_QUERY_KEY, params],
    queryFn: () =>
      apiClient
        .get<SMEAssignedApprovalRequestList>(`${smeApprovalRequest}/assigned/paginated`, {
          params: { ...params },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    initialData: EMPTY_PAGINATED_RESPONSE
  });
}

export function useGetApprovalRequestStatusTimeline(approvalRequestId: string) {
  return useQuery({
    queryKey: [AR_STATUS_TIMELINE_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient
        .get<ARStatusTimeline[]>(`${approvalRequest}/${approvalRequestId}/statusTimeline`)
        .then((response) => response.data)
  });
}

export function useAmendPermit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [AMEND_PERMIT_MUTATION_KEY],
    mutationFn: (request: AmendPermitRequest) => apiClient.post(`${approvalRequestReturn}/amend-permit`, request),
    onSuccess: (_, { approvalRequestId }) => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_STATUS_TIMELINE_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useGetApprovalRequestMap(approvalRequestId?: string) {
  return useQuery({
    queryKey: [APPROVAL_REQUEST_MAP_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient.get<MapItem>(`${approvalRequest}/${approvalRequestId}/map`).then((response) => response.data),
    enabled: approvalRequestId !== undefined
  });
}

export function useAddApprovalRequestMap(approvalRequestId: string, onSuccessCallback?: () => void) {
  const queryClient = useQueryClient();
  const { appendAlertBanner, removeAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (request: AddApprovalRequestMap) =>
      apiClient.post<MapItem>(`${approvalRequest}/${approvalRequestId}/map`, request.fileData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }),
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([APPROVAL_REQUEST_MAP_QUERY_KEY, approvalRequestId]);
      removeAlertBanner([AR_SUBMIT_MAP_ERROR_BANNER.id]);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}

export function useAttachmentCount(approvalRequestId: string) {
  return useQuery({
    queryKey: [ATTACHMENT_COUNT_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient
        .get<number>(`${approvalRequest}/${approvalRequestId}/attachment/count`)
        .then((response) => response.data),
    initialData: 0
  });
}
