import { RouteObject } from "react-router-dom";

import { HomePage, MyRequestsPage } from "@/pages";
import { PATH } from "@/constants";
import { NavigationLayout } from "@/layouts";

export const MyRequestsRoutes: RouteObject = {
  path: PATH.MY_REQUESTS,
  element: <NavigationLayout />,
  errorElement: <HomePage />,
  children: [
    {
      index: true,
      element: <MyRequestsPage />
    }
  ]
};
