import { Box } from "@mui/system";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { BlockNavigationModal, Loading, Navbar, SnackBarComponent } from "@/components";
import { Stack } from "@mui/material";

export function BaseLayout() {
  return (
    <Stack className="layout-wrapper">
      <BlockNavigationModal />
      <Navbar />
      <Box className="content-container">
        <Suspense fallback={<Loading prefix="Application" />}>
          <Outlet />
        </Suspense>
        <SnackBarComponent />
      </Box>
    </Stack>
  );
}
