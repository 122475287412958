import { apiClient } from "@/api/client";
import { ATTACHMENT_LINKS_QUERY_KEY } from "@/constants";
import { AttachmentLink, CreateAttachmentLinkRequest } from "@/interfaces";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const baseAttachmentLinksURL = "/approvalRequest";

export function useAttachmentLinks(approvalRequestId?: string) {
  // TODO: Add an actual API call and replace initial data with empty array.
  return useQuery({
    queryKey: [ATTACHMENT_LINKS_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient
        .get<AttachmentLink[]>(`${baseAttachmentLinksURL}/${approvalRequestId}/links`)
        .then((response) => response.data),
    enabled: approvalRequestId !== undefined,
    initialData: []
  });
}

export function useAddAttachmentLink(approvalRequestId: string, onSuccessCallback?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: CreateAttachmentLinkRequest) =>
      apiClient.post<AttachmentLink>(`${baseAttachmentLinksURL}/${request.approvalRequestId}/link`, request),
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([ATTACHMENT_LINKS_QUERY_KEY, approvalRequestId]);
    }
  });
}
export function useUpdateAttachmentLink(approvalRequestId: string, onSuccessCallback?: () => void) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (attachmentLink: AttachmentLink) =>
      apiClient.put<AttachmentLink>(
        `${baseAttachmentLinksURL}/${approvalRequestId}/link/${attachmentLink.id}`,
        attachmentLink
      ),
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([ATTACHMENT_LINKS_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useDeleteAttachmentLink(approvalRequestId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (attachmentLinkId: string) =>
      apiClient.delete<AttachmentLink>(`${baseAttachmentLinksURL}/${approvalRequestId}/link/${attachmentLinkId}`),
    onSuccess: () => {
      queryClient.invalidateQueries([ATTACHMENT_LINKS_QUERY_KEY, approvalRequestId]);
    }
  });
}
