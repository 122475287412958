import { useState, useEffect } from "react";
import { createRoot, Root } from "react-dom/client";
import { ArcgisMap } from "@arcgis/map-components-react";
import { Grid } from "@mui/material";
import { defineCustomElements } from "@arcgis/map-components/dist/loader";
import { ArcgisMapCustomEvent } from "@arcgis/map-components/dist/types/components";
import Expand from "@arcgis/core/widgets/Expand";
import Zoom from "@arcgis/core/widgets/Zoom";
import LayerList from "@arcgis/core/widgets/LayerList";
import Legend from "@arcgis/core/widgets/Legend";
import MapView from "@arcgis/core/views/MapView";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { CIMSymbol, SimpleFillSymbol, SimpleLineSymbol } from "@arcgis/core/symbols";
import "@arcgis/core/assets/esri/css/main.css";
defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.30/assets" });

import { MapToolbox } from "./MapToolbox";
import { useARContext, useAuthorization } from "@/context";
import { ACCEPTED_MAP_FILE_EXTENSIONS, AR_MAP_UPLOAD_ERROR_BANNER } from "@/constants";
import { AddApprovalRequestMap, ApprovalRequestSensitivityAnalysis } from "@/interfaces";
import { useAddApprovalRequestMap } from "@/hooks";
import { UploadComponent } from "@/components/upload";
import { useSensitivityAnalysis } from "@/hooks/api/ApprovalRequestMapHooks";
import { concatGraphics, drawShapes, getGeometryOverlap, getLayerBuffers } from "@/utils/mapHelpers";

const { REACT_APP_ARCGIS_WEBMAP_ITEM_ID, REACT_APP_ARCGIS_RTIO_FEATURE_LAYER } = import.meta.env;
type WidgetComponent = typeof Zoom | typeof LayerList | typeof Legend;

interface MapRenderProps {
  mapLoaded: boolean;
  setMapLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MapWidgetProps {
  name: string;
  component: WidgetComponent;
  componentProps?: Record<string, unknown>;
  isExpandable: boolean;
}

const BOTTOM_RIGHT_WIDGETS: MapWidgetProps[] = [
  {
    name: "Zoom",
    component: Zoom,
    isExpandable: false,
    componentProps: { layout: "vertical" }
  },
  { name: "Layers", component: LayerList, isExpandable: true },
  { name: "Legends", component: Legend, isExpandable: true }
];

export function MapRender({ mapLoaded, setMapLoaded }: MapRenderProps) {
  const [fullScreen, setFullScreen] = useState(false);
  const { approvalRequest, approvalRequestId, setUploadInProgress, appendAlertBanner, removeAlertBanner, arMap } =
    useARContext();
  const { isRequestor } = useAuthorization();
  const { mutate: updateMapMutation } = useAddApprovalRequestMap(approvalRequestId);
  const { mutate: fetchSensitivityIssues } = useSensitivityAnalysis();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [mapView, setMapView] = useState<MapView>();
  const [mapPanel] = useState<HTMLDivElement>(document.createElement("div"));
  const [rootPanel] = useState<Root>(createRoot(mapPanel));
  const [inProgressButtons, setInProgressButtons] = useState<string[]>([]);
  const [viewExtent, setViewExtent] = useState<__esri.Extent | null>(null);
  // create the symbology for AR polygon shapes
  const [arGraphicsLayer] = useState<GraphicsLayer>(
    new GraphicsLayer({
      listMode: "hide"
    })
  );
  const [arIntersectionsLayer] = useState<GraphicsLayer>(
    new GraphicsLayer({
      listMode: "hide"
    })
  );
  // create the symbology for AR polygon shapes
  const [arSymbol] = useState<SimpleLineSymbol>(
    new SimpleLineSymbol({
      color: "yellow",
      width: 2
    })
  );
  const [sensitivitySymbol] = useState(
    new SimpleFillSymbol({
      color: [255, 0, 0, 0.5],
      outline: { width: 0 }
    })
  );
  const [intersectionSymbol] = useState(
    new CIMSymbol({
      data: {
        type: "CIMSymbolReference",
        symbol: {
          type: "CIMLineSymbol",
          symbolLayers: [
            {
              type: "CIMSolidStroke",
              effects: [
                {
                  type: "CIMGeometricEffectDashes",
                  dashTemplate: [4, 4],
                  lineDashEnding: "HalfPattern",
                  offsetAlongLine: 1
                }
              ],
              enable: true,
              colorLocked: true,
              capStyle: "Butt",
              joinStyle: "Round",
              miterLimit: 10,
              width: 1,
              color: [255, 255, 255, 255]
            },
            {
              type: "CIMSolidStroke",
              enable: true,
              capStyle: "Butt",
              joinStyle: "Miter",
              miterLimit: 10,
              width: 3.4,
              color: [202, 0, 32, 255]
            }
          ]
        }
      }
    })
  );

  const setMapScale = (view: MapView) => {
    // Set the minimum and maximum scale
    view.constraints = {
      minScale: 9000000,
      maxScale: 35
    };
  };

  const addMapToolboxPanel = (view: MapView) => {
    const expand = new Expand({
      view: view,
      content: mapPanel,
      expanded: true
    });

    view.ui.add(expand, "top-left");
  };

  const addMapWidgets = (view: MapView) => {
    BOTTOM_RIGHT_WIDGETS.map((item) => {
      const widgetPosition = "bottom-right";
      const WidgetComponent = new item.component({ view: view, ...item.componentProps });
      if (!item.isExpandable) view.ui.add(WidgetComponent, widgetPosition);
      else
        view.ui.add(
          new Expand({
            content: WidgetComponent,
            view: view,
            expandTooltip: `Open ${item.name}`,
            collapseTooltip: `Close ${item.name}`,
            autoCollapse: true
          }),
          widgetPosition
        );
    });
  };

  const renderMapToolboxPanel = () => {
    if (mapView)
      rootPanel.render(
        <MapToolbox
          setFullScreen={setFullScreen}
          mapView={mapView}
          approvalRequestStatus={approvalRequest.approvalRequestStatus}
          isRequestor={isRequestor}
          inProgressButtons={inProgressButtons}
          viewExtent={viewExtent}
          getSensitivityIssues={() => {
            runSensitivityAnalysis();
          }}
        />
      );
  };

  // define the AR Feature Layer we will use for rendering selected AR shapes
  const lams_ar_feature_layer = new FeatureLayer({
    url: REACT_APP_ARCGIS_RTIO_FEATURE_LAYER
  });

  const setZoomToARShape = (view: MapView) => {
    // clear existing shapes from the graphics layer
    arGraphicsLayer.removeAll();

    // select the AR Version we want using a SQL expression
    const query_expression: string = `AR_Shape_ID = '${arMap?.id}'`;

    const query = lams_ar_feature_layer.createQuery();
    query.where = query_expression;

    lams_ar_feature_layer.queryFeatures(query).then(function (response) {
      if (response.features.length > 0) {
        // get the selected features and add the geometry to the graphics layer
        response.features.map(function (feature) {
          arGraphicsLayer.add(
            new Graphic({
              geometry: feature.geometry,
              symbol: arSymbol
            })
          );
        });

        // zoom to the extent of the selected features
        lams_ar_feature_layer.queryExtent(query).then(function (response) {
          const extent = response.extent.expand(1.3);

          view.goTo(extent).then(() => {
            setViewExtent(extent);
          });
        });
      }
    });
  };

  const onArcgisViewReadyChange = (event: ArcgisMapCustomEvent<void>) => {
    const view = event.target.view;
    if (view) {
      view.map.add(arGraphicsLayer);
      view.map.add(arIntersectionsLayer);

      setMapLoaded(true);
      setMapScale(view);
      addMapToolboxPanel(view);
      addMapWidgets(view);
      setZoomToARShape(view);
      setMapView(view);
    }
  };

  const uploadFile = async (fileToUpload: File) => {
    setUploadInProgress(true);
    setInProgressButtons([...inProgressButtons, "Upload"]);

    const fileData = new FormData();
    fileData.append("file", fileToUpload);

    const addMapCommand: AddApprovalRequestMap = {
      approvalRequestId,
      fileData
    };

    updateMapMutation(addMapCommand, {
      onSettled: () => {
        setUploadInProgress(false);
        setInProgressButtons(inProgressButtons.filter((enabledLabel) => enabledLabel !== "Upload"));
      }
    });
  };

  const runSensitivityAnalysis = async () => {
    setInProgressButtons([...inProgressButtons, "Sensitivity Analysis"]);

    const sensitivityCommand: ApprovalRequestSensitivityAnalysis = {
      approvalRequestId,
      shapeId: arMap?.id
    };

    fetchSensitivityIssues(sensitivityCommand, {
      onSuccess: (sensitivityGeometry) => {
        /* Draw shapes from the sensitivity issues response.*/
        if (
          sensitivityGeometry.approvalRequestGeometry.length === 0 ||
          sensitivityGeometry.sensitivityIssues.length === 0 ||
          !mapView
        ) {
          return;
        }

        const approvalRequestGraphics = concatGraphics(sensitivityGeometry.approvalRequestGeometry);
        const sensitivityGraphics = concatGraphics(sensitivityGeometry.sensitivityIssues);
        const layerBuffers = getLayerBuffers(sensitivityGeometry.sensitivityIssues);

        const approvalRequestGeometries = approvalRequestGraphics.map((graphic) => graphic.geometry);
        const sensitivityGeometries = sensitivityGraphics.map((graphic) => graphic.geometry);

        const intersections = getGeometryOverlap(approvalRequestGeometries, sensitivityGraphics, layerBuffers);

        arIntersectionsLayer.removeAll();
        drawShapes(arIntersectionsLayer, sensitivityGeometries, sensitivitySymbol);
        drawShapes(arIntersectionsLayer, intersections, intersectionSymbol);

        mapView.goTo(viewExtent);
      },
      onSettled: () => {
        setInProgressButtons(inProgressButtons.filter((enabledLabel) => enabledLabel !== "Sensitivity Analysis"));
      }
    });
  };

  useEffect(() => {
    if (errorMessage) {
      appendAlertBanner({
        ...AR_MAP_UPLOAD_ERROR_BANNER,
        message: errorMessage
      });
    } else if (errorMessage === "") {
      removeAlertBanner([AR_MAP_UPLOAD_ERROR_BANNER.id]);
    }
  }, [errorMessage, appendAlertBanner]);

  useEffect(() => {
    renderMapToolboxPanel();
  }, [mapView, isRequestor, inProgressButtons, viewExtent]);

  useEffect(() => {
    if (mapView) {
      setZoomToARShape(mapView);
    }
  }, [arMap]);

  return (
    <Grid
      className={fullScreen ? "ar-map-fullscreen" : ""}
      data-testid="map-render-display"
      visibility={mapLoaded ? "visible" : "hidden"}
      sx={{ flexDirection: "column", flexGrow: 1, height: "100%" }}
      style={{ margin: "0 auto" }}
    >
      <UploadComponent
        handleUpload={uploadFile}
        acceptedFileExtensions={ACCEPTED_MAP_FILE_EXTENSIONS}
        allowMultipleFile={false}
        setErrorMessage={setErrorMessage}
        validateMapFile={true}
      />
      <ArcgisMap itemId={REACT_APP_ARCGIS_WEBMAP_ITEM_ID} onArcgisViewReadyChange={onArcgisViewReadyChange} />
    </Grid>
  );
}
