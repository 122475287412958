import { useAtom } from "jotai";
import { Alert, AlertTitle, Collapse } from "@mui/material";
import { adminTableErrorMessageAtom } from "@/stores";
import { AdminTableErrorMessage } from "@/interfaces";

export function AdminTableAlert() {
  const [adminTableErrorMessage, setAdminTableErrorMessage] = useAtom(adminTableErrorMessageAtom);

  return (
    <Collapse in={adminTableErrorMessage.open}>
      <Alert
        severity="error"
        onClose={() => {
          setAdminTableErrorMessage({
            open: false,
            title: "",
            message: ""
          } as AdminTableErrorMessage);
        }}
      >
        <AlertTitle>{adminTableErrorMessage.title}</AlertTitle>
        {adminTableErrorMessage.message}
      </Alert>
    </Collapse>
  );
}
