import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: import.meta.env.REACT_APP_AUTH_CLIENTID || "",
    authority: import.meta.env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: import.meta.env.REACT_APP_AUTH_REDIRECT_URI
  }
});

export const scopes = import.meta.env.REACT_APP_AUTH_APISCOPES?.split(",") || [];
