import { SUB_HEADERS, TITLES } from "@/constants";
import { InterceptConfigurationItemCardWithTab } from "./shared/InterceptConfigurationItemCardWithTab";

export const InterceptionAnalysisConfigurationItem = () => {
  return (
    <InterceptConfigurationItemCardWithTab
      title={TITLES.interception_analysis}
      subheader={SUB_HEADERS.interception_analysis}
    />
  );
};
