import { apiClient } from "@/api/client";
import {
  InterceptResultList,
  LookUpResponse,
  GetInterceptResultList,
  AddInterceptResultDecisionRequest,
  HubItem
} from "@/interfaces";

const interceptResultApiPath = "/interceptResult";
const siteApiPath = "/site";
const hubApiPath = "/hub";

export const getInterceptResult = (request: GetInterceptResultList) =>
  apiClient
    .get<InterceptResultList>(interceptResultApiPath, {
      params: {
        ...request
      }
    })
    .then((response) => response.data);

export const getSiteByHubId = (hubId: string) =>
  apiClient
    .get<LookUpResponse>(`${siteApiPath}/getByHubId`, {
      params: { hubId }
    })
    .then((response) => response.data);

export const getFieldSite = (isHistory: boolean) =>
  apiClient
    .get<LookUpResponse>(`${interceptResultApiPath}/getFieldSite`, {
      params: { isHistory }
    })
    .then((response) => response.data);

export const getFieldSiteType = (isHistory: boolean) =>
  apiClient
    .get<LookUpResponse>(`${interceptResultApiPath}/getFieldSiteType`, {
      params: { isHistory }
    })
    .then((response) => response.data);

export const getAllHubs = () => apiClient.get<HubItem[]>(`${hubApiPath}/all`).then((response) => response.data);

export const addInterceptResultDecision = (request: AddInterceptResultDecisionRequest) =>
  apiClient.post(`${interceptResultApiPath}/addInterceptResultDecision`, request).then((response) => response.data);
