import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  USER_HUB_MATRIX_QUERY_KEY,
  USER_PROFILE_PHOTO_QUERY_KEY,
  USER_GRAPH_CONFIG_QUERY_KEY,
  UPSERT_USER_HUB_MUTATION_KEY,
  EMPTY_GUID
} from "@/constants";
import { apiClient } from "@/api/client";
import { GraphUser, HubUsers, LAMSRole } from "@/interfaces";

const userPath = "/user";
const basePath = "/hub";

export function useProfilePhoto(userId: string, photoDimension: string) {
  return useQuery({
    queryKey: [USER_PROFILE_PHOTO_QUERY_KEY, userId],
    queryFn: () => apiClient.get(`user/${userId}/photo/${photoDimension}`).then((response) => response.data)
  });
}

export function useGraphUsers(roles: LAMSRole[]) {
  return useQuery({
    queryKey: [USER_GRAPH_CONFIG_QUERY_KEY, ...roles],
    queryFn: () =>
      apiClient
        .get<GraphUser[]>(userPath, {
          params: { roles },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    initialData: [],
    enabled: roles.length > 0
  });
}

export function useUserHubMatrix(hubId: string, onSuccessHandler: () => void) {
  const queryClient = useQueryClient();
  const {
    data: userHubMatrix,
    isLoading: isUserHubLoading,
    isSuccess: isUserHubSuccess
  } = useQuery({
    queryKey: [USER_HUB_MATRIX_QUERY_KEY, hubId],
    queryFn: () => apiClient.get<HubUsers[]>(`${basePath}/${hubId}/users`).then((response) => response.data),
    initialData: [],
    enabled: hubId !== "" && hubId !== EMPTY_GUID
  });

  const { mutate: upsertUserHub } = useMutation({
    mutationKey: [UPSERT_USER_HUB_MUTATION_KEY],
    mutationFn: (hubUsers: HubUsers[]) =>
      apiClient.post(`${basePath}/${hubId}/users`, { hubUsers: hubUsers }).then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries([USER_HUB_MATRIX_QUERY_KEY, hubId]);
      onSuccessHandler();
    }
  });

  return { userHubMatrix, isUserHubLoading, isUserHubSuccess, upsertUserHub };
}
