import { SvgIcon } from "@mui/material";

import { ApprovalRequestDisciplineStatus } from "@/interfaces";
import { AuthorisedStatusIcon, NotDistributedStatusIcon, NotStartedStatusIcon, WIPStatusIcon } from "@/assets/icons";

interface DisciplineStatusProps {
  status: ApprovalRequestDisciplineStatus;
}

export function DisciplineStatusIcon({ status }: DisciplineStatusProps) {
  let icon;
  switch (status) {
    case ApprovalRequestDisciplineStatus.NotDistributed:
      icon = NotDistributedStatusIcon;
      break;
    case ApprovalRequestDisciplineStatus.NotStarted:
      icon = NotStartedStatusIcon;
      break;
    case ApprovalRequestDisciplineStatus.WIP:
      icon = WIPStatusIcon;
      break;
    case ApprovalRequestDisciplineStatus.Authorised:
      icon = AuthorisedStatusIcon;
      break;
    default:
      return null;
  }

  return <SvgIcon component={icon} sx={{ mx: "auto" }} />;
}
