import { Avatar, Tooltip } from "@mui/material";
import { useAuthorization } from "@/context";
import { useProfilePhoto } from "@/hooks";

interface UserAvatarProps {
  fontSize: string;
  avatarWidth: string;
  avatarHeight: string;
  avatarName?: string;
  avatarUserId?: string;
  showTooltip?: boolean;
}

export function UserAvatar({
  avatarWidth,
  avatarHeight,
  fontSize,
  avatarUserId,
  avatarName,
  showTooltip
}: UserAvatarProps) {
  const { name: currentUserName, userId: currentUserId } = useAuthorization();

  const userId = avatarUserId ?? currentUserId ?? "";
  const userName = avatarName ?? currentUserName ?? "";

  const { data } = useProfilePhoto(userId, "120x120");

  return (
    <Tooltip title={(showTooltip ?? showTooltip) ? userName : ""}>
      <Avatar
        sx={{
          width: avatarWidth,
          height: avatarHeight,
          fontSize: fontSize
        }}
        alt={userName}
        src={data}
      >
        {userName?.split(" ")[0]?.[0] ?? ""}
        {userName?.split(" ")[1]?.[0] ?? ""}
      </Avatar>
    </Tooltip>
  );
}
