import { Fragment, useEffect, useState } from "react";
import { Alert, Button, Card, Modal, Snackbar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useAtom, useSetAtom } from "jotai";
import { AxiosError } from "axios";
import { useResetAtom } from "jotai/utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { AdminTableErrorMessage } from "@/interfaces";
import {
  AddArcGISServiceRequest,
  UpdateArcGISServiceRequest,
  ValidateNameRequest,
  ValidateUrlRequest
} from "@/interfaces/api";
import {
  createArcGisServiceRequest,
  getArcGisServiceById,
  getArcGisServices,
  updateArcGisServiceRequest,
  validateDuplicateName,
  validateDuplicateUrl,
  validateValidUrl
} from "@/services";
import {
  addIsActiveAtom,
  adminTablePageDetailsAtom,
  columnDataAtom,
  configurationItemSubheaderAtom,
  editIsActiveAtom,
  selectedRowIdAtom,
  selectedRowIdCountAtom,
  enableIsActiveAtom,
  disableIsActiveAtom
} from "@/stores";
import { ARCGIS_SERVICES_COLUMNS, SUB_HEADERS, TITLES } from "@/constants";
import { convertToGridRows } from "@/utils";
import { AppFormInput } from "@/components/fields";
import { ConfigurationItemCard } from "./shared/ConfigurationItemCard";

export function ArcGISServiceOverviewPage() {
  //TODO: move it to hooks next iteration
  const setColumn = useSetAtom(columnDataAtom);
  const [addIsActive, setAddIsActive] = useAtom(addIsActiveAtom);
  //TODO: move it to hooks next iteration
  const [editIsActive, setEditIsActive] = useAtom(editIsActiveAtom);
  const setEnableIsActive = useSetAtom(enableIsActiveAtom);
  const setDisableIsActive = useSetAtom(disableIsActiveAtom);
  const [rowId] = useAtom(selectedRowIdAtom);
  const setSelectedRowIdCount = useSetAtom(selectedRowIdCountAtom);

  const defaultValues: AddArcGISServiceRequest = {
    name: "",
    url: ""
  };

  const schema = yup
    .object()
    .shape({
      name: yup
        .string()
        .required(" ")
        .test(
          "name-duplication-validation",
          "The Name or URL exists with the entered Name or URL.",
          async (currentName) => {
            try {
              const name: ValidateNameRequest = {
                id: rowId === "" || addIsActive ? null : rowId,
                name: currentName
              };
              let result = false;
              await validateDuplicateName(name).then((response) => {
                result = response.data.isValid;
              });
              return result;
            } catch (error) {
              console.error(error);
              return false;
            }
          }
        ),
      url: yup
        .string()
        .required("The URL is not a valid ArcGIS Service.")
        .test("url-duplication-validation", "The Name or URL exists with the entered Name or URL.", async (value) => {
          try {
            const url: ValidateUrlRequest = {
              id: rowId === "" || addIsActive ? null : rowId,
              url: value
            };
            let result = false;
            await validateDuplicateUrl(url).then((resp) => {
              result = resp.data.isValid;
            });
            return result;
          } catch (error) {
            console.error(error);
            return false;
          }
        })
        .test("valid-url-check", "The URL is not a valid ArcGIS Service.", async (value) => {
          try {
            const url: ValidateUrlRequest = {
              id: rowId === "" || addIsActive ? null : rowId,
              url: value
            };
            let result = false;
            await validateValidUrl(url).then((resp1) => {
              result = resp1.data.isValid;
            });
            return result;
          } catch (error) {
            console.error(error);
            return false;
          }
        })
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues
  } = useForm<AddArcGISServiceRequest>({
    defaultValues,
    resolver: yupResolver(schema)
  });

  const setConfigurationItemSubheader = useSetAtom(configurationItemSubheaderAtom);
  const [adminTablePageDetails, setAdminTablePageDetails] = useAtom(adminTablePageDetailsAtom);
  const resetAdminTablePageDetails = useResetAtom(adminTablePageDetailsAtom);

  const updateAdminPageDetails = () => {
    setAdminTablePageDetails((old) => ({ ...old, isLoading: true }));
    getArcGisServices(adminTablePageDetails.page, adminTablePageDetails.pageSize).then(({ items, totalRecords }) => {
      setAdminTablePageDetails((old) => ({
        ...old,
        isLoading: false,
        data: convertToGridRows(items),
        total: totalRecords
      }));
    });
  };

  //set initial atom values for page.
  useEffect(() => {
    setAddIsActive(false);
    setEditIsActive(false);
    setEnableIsActive(null);
    setDisableIsActive(null);
    setConfigurationItemSubheader(SUB_HEADERS.arcgis_service);
    setColumn(ARCGIS_SERVICES_COLUMNS);
    resetAdminTablePageDetails();
    reset();
  }, []);

  useEffect(() => {
    if (!editIsActive || rowId === undefined || rowId === "") {
      return;
    }
    getArcgisService();
  }, [rowId, editIsActive]);

  useEffect(() => {
    if (adminTablePageDetails.isLoading) {
      return;
    }
    updateAdminPageDetails();
  }, [adminTablePageDetails.page, adminTablePageDetails.pageSize]);

  const getArcgisService = () => {
    getArcGisServiceById(rowId).then(({ name, url }) => {
      setValue("name", name);
      setValue("url", url);
    });
  };

  const [showSnackbar, setShowSnackbar] = useState<AdminTableErrorMessage>();
  const handleSnackbarClose = () => {
    setShowSnackbar(undefined);
  };
  const handleArcgisServiceSubmit = async () => {
    if (editIsActive) {
      const UpdateArcGISServiceRequest: UpdateArcGISServiceRequest = {
        id: rowId,
        name: getValues().name.trim(),
        url: getValues().url.trim(),
        isActive: true
      };
      await updateArcGisServiceRequest(UpdateArcGISServiceRequest)
        .then(() => {
          setSelectedRowIdCount(0);
          closeModal();
          updateAdminPageDetails();
          setShowSnackbar({
            open: true,
            message: "Updated Successfully",
            title: "",
            isInfo: true
          });
        })
        .catch((error: AxiosError) => {
          const errResp = error.response;
          if (errResp) {
            setShowSnackbar({
              open: true,
              message: errResp.data.toString(),
              title: ""
            });
          } else {
            setShowSnackbar({
              open: true,
              message: error.message.toString(),
              title: ""
            });
          }
        });
    } else {
      const AddArcGISServiceRequest: AddArcGISServiceRequest = {
        name: getValues().name.trim(),
        url: getValues().url.trim()
      };
      await createArcGisServiceRequest(AddArcGISServiceRequest)
        .then(() => {
          closeModal();
          updateAdminPageDetails();
          setShowSnackbar({
            open: true,
            message: "Created Successfully",
            title: "",
            isInfo: true
          });
        })
        .catch((error) => {
          setShowSnackbar({
            open: true,
            message: error.response.data.detail.toString(),
            title: ""
          });
        });
    }
  };

  const openModal = () => {
    if (editIsActive) {
      return editIsActive !== null ? editIsActive : false;
    } else {
      return addIsActive !== null ? addIsActive : false;
    }
  };

  const closeModal = () => {
    if (editIsActive) {
      //setRowId("");
      setEditIsActive(false);
    } else {
      setAddIsActive(false);
    }
    reset();
  };

  return (
    <Fragment>
      <ConfigurationItemCard title={TITLES.arcgis_service} subheader={SUB_HEADERS.arcgis_service} />
      <Modal
        open={openModal()}
        onClose={() => {
          closeModal();
        }}
        data-testid="AddArcGisServiceModalId"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30rem",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 2
          }}
        >
          <form onSubmit={handleSubmit(handleArcgisServiceSubmit)}>
            <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
              {editIsActive ? "Edit ArcGIS Service" : "New ArcGIS Service"}
            </Typography>
            <AppFormInput
              control={control}
              label={"Name"}
              name={"name"}
              error={errors.name}
              data-testid="nameInput"
              showError={false}
            />
            <AppFormInput
              control={control}
              label={"URL"}
              name={"url"}
              error={errors.url || errors.name}
              data-testid="urlInput"
              showError={true}
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" marginTop={2}>
              <Button variant="text" onClick={closeModal} data-testid="ModalCancelButtonId" color="secondary">
                Cancel
              </Button>
              <Button variant="text" type="submit" color="secondary" data-testid="AddArcGisServiceButtonId">
                {editIsActive ? "SAVE" : "ADD"}
              </Button>
            </Stack>
          </form>
        </Card>
      </Modal>
      {showSnackbar !== undefined ? (
        <Snackbar open={showSnackbar?.open} autoHideDuration={1300} onClose={handleSnackbarClose}>
          <Alert severity={!showSnackbar?.isInfo ? "error" : undefined}>{showSnackbar?.message}</Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </Fragment>
  );
}
