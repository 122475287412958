import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";

interface ExpandToggleProps {
  expanded: boolean;
  toggleExpansion: () => void;
}

export const ExpandToggle = styled(({ expanded, toggleExpansion }: ExpandToggleProps) => {
  return <IconButton onClick={toggleExpansion}>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>;
})(({ theme, expanded }) => ({
  transform: `rotate(${expanded ? `180` : `0`}deg)`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));
