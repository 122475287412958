import { AttachmentUpload } from "./AttachmentUpload";
import { AttachmentLink } from "./AttachmentLink";
import { Divider, Grid } from "@mui/material";
import { AttachmentLinkContextProvider } from "@/context";
export function AttachmentTab() {
  return (
    <Grid container direction={"row"} rowGap={1}>
      <Grid item xs={12}>
        <AttachmentUpload />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AttachmentLinkContextProvider>
          <AttachmentLink />
        </AttachmentLinkContextProvider>
      </Grid>
    </Grid>
  );
}
