import { GridRenderCellParams } from "@mui/x-data-grid";

import { DisciplineStatusIcon } from "@/components";

/**
 * Function that is used to take a grid row cell value and return a `DisciplineStatusIcon` component.
 * @param gridRenderCellParams An object of type `GridRenderCellParams` with a `value` property.
 * @returns A `DisciplineStatusIcon` component configured based on the value passed in.
 */
export function renderDisciplineStatusIcon({ value }: GridRenderCellParams) {
  return <DisciplineStatusIcon status={value} />;
}
