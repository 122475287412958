import { useState } from "react";
import { Grid } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";

import { ApprovalRequestDisciplineStatus, ApprovalRequestStatus, SMEDashboardFilterOptionsRequest } from "@/interfaces";
import { useSMEDashboardFilterOptions } from "@/hooks";
import { DashboardSelectWithGrid } from "@/components/shared";

export interface SMEDashboardFilterQuery {
  selectedHub: string;
  selectedSite: string;
  selectedApprovalRequestType: string;
}

interface SMEDashboardFilterToolbarProps {
  onFilterChange: (filterQuery: SMEDashboardFilterQuery) => void;
  approvalRequestStatusFilter: ApprovalRequestStatus[];
  disciplineStatusFilter: ApprovalRequestDisciplineStatus;
}

export function SMEDashboardFilterToolbar({
  onFilterChange,
  approvalRequestStatusFilter,
  disciplineStatusFilter
}: SMEDashboardFilterToolbarProps) {
  const [selectedHub, setSelectedHub] = useState<string>("all");
  const [selectedSite, setSelectedSite] = useState<string>("all");
  const [selectedApprovalRequestType, setSelectedApprovalRequestType] = useState<string>("all");

  const filterOptionsRequest: SMEDashboardFilterOptionsRequest = {
    hubId: selectedHub,
    siteId: selectedSite,
    approvalRequestTypeId: selectedApprovalRequestType,
    approvalRequestStatus: approvalRequestStatusFilter,
    disciplineStatus: disciplineStatusFilter
  };

  const { data: smeDashboardFilterOptions } = useSMEDashboardFilterOptions(filterOptionsRequest);

  const handleFilterChange = (name: keyof SMEDashboardFilterQuery, value: string) => {
    const newFilter = {
      selectedHub,
      selectedSite,
      selectedApprovalRequestType
    };

    newFilter[name] = value;

    onFilterChange(newFilter);
  };

  return (
    <GridToolbarContainer>
      <Grid container spacing={3} paddingBottom={2}>
        <DashboardSelectWithGrid
          id="hubId"
          label="Hub"
          options={smeDashboardFilterOptions.hubFilterOptionList}
          value={selectedHub}
          onChange={(event) => {
            setSelectedHub(event.target.value);
            handleFilterChange("selectedHub", event.target.value);
          }}
          data-testid="hub-filter-select"
        />

        <DashboardSelectWithGrid
          id="siteId"
          label="Site"
          options={smeDashboardFilterOptions.siteFilterOptionList}
          value={selectedSite}
          onChange={(event) => {
            setSelectedSite(event.target.value);
            handleFilterChange("selectedSite", event.target.value);
          }}
          data-testid="site-filter-select"
        />
        <DashboardSelectWithGrid
          id="approvalRequestTypeId"
          label="Type"
          options={smeDashboardFilterOptions.typeFilterOptionList}
          value={selectedApprovalRequestType}
          onChange={(event) => {
            setSelectedApprovalRequestType(event.target.value);
            handleFilterChange("selectedApprovalRequestType", event.target.value);
          }}
          data-testid="type-filter-select"
        />
      </Grid>
    </GridToolbarContainer>
  );
}
