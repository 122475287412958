import { atom, useAtom } from "jotai";
import {
  fieldSiteListAtom,
  fieldSiteTypeListAtom,
  hubListAtom,
  interceptResultListAtom,
  interceptResultListRequestAtom,
  selectedFieldSiteAtom,
  selectedFieldSiteTypeAtom,
  selectedHubIdAtom,
  selectedSiteIdAtom,
  siteListAtom
} from "@/stores";
import {
  getInterceptResult,
  getSiteByHubId,
  getFieldSite,
  getFieldSiteType,
  addInterceptResultDecision,
  getAllHubs
} from "@/services";
import { AddInterceptResultDecisionRequest, GetInterceptResultList, IOption } from "@/interfaces";
import { EMPTY_GUID } from "@/constants";

const emptyStringOptionId = "";
const defaultId = "-1";

// Pagination, Filtering and Sorting Result Api Derived Atom
const getInterceptResultListRequestAtom = atom(
  (get) => get(interceptResultListRequestAtom),
  (_get, set, isHistory: boolean, pageNumber?: number, pageSize?: number, sortField?: string) => {
    const selectedHubId = _get(selectedHubIdAtom);
    const selectedSiteId = _get(selectedSiteIdAtom);
    const selectedFieldSiteId = _get(selectedFieldSiteAtom);
    const selectedFieldSiteTypeId = _get(selectedFieldSiteTypeAtom);
    const currentInterceptResultListRequest = _get(interceptResultListRequestAtom);
    const request: GetInterceptResultList = {
      pageNumber: pageNumber ?? currentInterceptResultListRequest.pageNumber,
      pageSize: pageSize ?? currentInterceptResultListRequest.pageSize,
      sortField: sortField ?? currentInterceptResultListRequest.sortField, // Take back existing sortField
      hubId: selectedHubId === defaultId ? EMPTY_GUID : selectedHubId,
      siteId: selectedSiteId === defaultId ? EMPTY_GUID : selectedSiteId,
      fieldSite: selectedFieldSiteId === defaultId ? emptyStringOptionId : selectedFieldSiteId,
      fieldSiteType: selectedFieldSiteTypeId === defaultId ? emptyStringOptionId : selectedFieldSiteTypeId,
      isHistory
    };
    set(interceptResultListRequestAtom, request);
  }
);

export const useGetInterceptResultListRequestAtom = () => {
  return useAtom(getInterceptResultListRequestAtom);
};

const getInterceptResultListAtom = atom(
  (get) => get(interceptResultListAtom),
  (_get, set, isHistory: boolean, pageNumber?: number, pageSize?: number, sortField?: string) => {
    set(getInterceptResultListRequestAtom, isHistory, pageNumber, pageSize, sortField);
    const interceptResultListRequest = _get(getInterceptResultListRequestAtom);
    getInterceptResult(interceptResultListRequest).then((interceptResults) => {
      set(interceptResultListAtom, interceptResults);
    });
  }
);

export const useGetInterceptListResultAtom = () => {
  return useAtom(getInterceptResultListAtom);
};

const getHubListAtom = atom(
  (get) => get(hubListAtom),
  (_get, set, isHistory: boolean) => {
    getAllHubs().then((hubs) => {
      const hubsList: IOption[] = hubs.map((hub) => ({
        id: hub.id,
        value: hub.name
      }));

      if (isHistory) {
        set(selectedHubIdAtom, defaultId); // Default to All
        set(hubListAtom, [...hubsList]);
      } else {
        set(selectedHubIdAtom, hubsList[0].id ?? ""); // Default to first hub
        set(hubListAtom, hubsList);
      }
    });
  }
);

export const useGetHubList = () => {
  return useAtom(getHubListAtom);
};

// Site List Derived Atom
const getSiteListAtom = atom(
  (get) => get(siteListAtom),
  (_get, set, hubId: string | null) => {
    if (hubId === null || hubId === "") {
      return;
    }

    getSiteByHubId(hubId).then((sites) => {
      set(selectedSiteIdAtom, defaultId); // Default to All
      set(siteListAtom, [...sites.optionList]);
    });
  }
);

export const useGetSiteList = () => {
  return useAtom(getSiteListAtom);
};

// Field Site Derived Atom
const getfieldSiteListAtom = atom(
  (get) => get(fieldSiteListAtom),
  (_get, set, isHistory: boolean) => {
    getFieldSite(isHistory).then((fieldSites) => {
      set(selectedFieldSiteAtom, defaultId); // Default to All
      set(fieldSiteListAtom, [...fieldSites.optionList]);
    });
  }
);

export const useGetFieldSiteList = () => {
  return useAtom(getfieldSiteListAtom);
};

// Field Site Type Derived Atom
export const getFieldSiteTypeListAtom = atom(
  (get) => get(fieldSiteTypeListAtom),
  (_get, set, isHistory: boolean) => {
    getFieldSiteType(isHistory).then((siteTypes) => {
      set(selectedFieldSiteTypeAtom, defaultId); // Default to All
      set(fieldSiteTypeListAtom, [...siteTypes.optionList]);
    });
  }
);

export const useGetFieldSiteTypeList = () => {
  return useAtom(getFieldSiteTypeListAtom);
};

// Add Intercept Decision Derived Atom
const addInterceptDecisionAtom = atom(null, async (_get, _set, request: AddInterceptResultDecisionRequest) => {
  return addInterceptResultDecision(request);
});

export const useAddInterceptDecision = () => {
  const [, addInterceptDecision] = useAtom(addInterceptDecisionAtom);
  return addInterceptDecision;
};

export const useSelectedHubId = () => {
  return useAtom(selectedHubIdAtom);
};

export const useSelectedSiteId = () => {
  return useAtom(selectedSiteIdAtom);
};

export const useSelectedFieldSite = () => {
  return useAtom(selectedFieldSiteAtom);
};

export const useSelectedFieldSiteType = () => {
  return useAtom(selectedFieldSiteTypeAtom);
};
