import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { AR_COMMENTARIES_QUERY_KEY, AR_FORM, EMPTY_GUID, RETURN_AR_REQUEST } from "@/constants";
import { ARReturnFormFields } from "@/interfaces";
import { AppFormInput, AppFormMultiSelect } from "@/components/fields";
import { Dialog } from "@/components/dialogs";
import { returnApprovalRequestSchema } from "@/validations";
import { useReturnApprovalRequest } from "@/hooks/api/ApprovalRequestHooks";
import { useReturnReasons } from "@/hooks";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { snackBarAtom } from "@/stores";
import { useSetAtom } from "jotai";

interface ReturnARFormProps {
  isReturn: boolean;
  approvalRequestId: string;
  dismissModal: () => void;
}

export function ReturnARForm({ isReturn, dismissModal, approvalRequestId }: ReturnARFormProps) {
  const queryClient = useQueryClient();
  const setSnackBar = useSetAtom(snackBarAtom);
  const [returnIsDisabled, setReturnIsDisabled] = useState(true);

  const defaultValues: ARReturnFormFields = {
    approvalRequestId,
    reasons: [],
    comments: ""
  };

  const {
    control,
    getValues,
    watch,
    formState: { errors },
    reset
  } = useForm<ARReturnFormFields>({
    defaultValues
  });

  const returnFormValues = watch(["reasons", "comments"]);

  const { data: returnReasons } = useReturnReasons();

  const { mutate: returnApprovalRequestHook } = useReturnApprovalRequest(approvalRequestId, (_) => {
    queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
    dismissModal();
    reset();
    setSnackBar({
      message: AR_FORM.RETURN_AR_SUCCESSFUL,
      open: true
    });
  });

  const otherReturnReason = useMemo(
    () => returnReasons.find((reason) => reason.value === RETURN_AR_REQUEST.OTHER_REASON_LABEL)?.id ?? EMPTY_GUID,
    [returnReasons]
  );

  useEffect(() => {
    const formValues: ARReturnFormFields = getValues();
    const isCommentRequired = formValues.reasons.length > 0 ? formValues.reasons.includes(otherReturnReason) : false;

    returnApprovalRequestSchema(isCommentRequired)
      .validate(formValues)
      .then(() => {
        setReturnIsDisabled(false);
      })
      .catch(() => {
        setReturnIsDisabled(true);
      });
  }, [returnFormValues, getValues, returnReasons, otherReturnReason]);

  const handleReturnAR = () => {
    const formValues = getValues();
    returnApprovalRequestHook({
      approvalRequestId: formValues.approvalRequestId,
      returnReasonIds: formValues.reasons,
      comments: formValues.comments
    });
  };

  return (
    <Dialog
      open={isReturn}
      onClose={() => {
        dismissModal();
        reset();
      }}
      title="Return"
      actions={[
        {
          label: "Cancel",
          onClick: () => {
            dismissModal();
            reset();
          }
        },
        {
          label: "Return",
          disabled: returnIsDisabled,
          onClick: () => {
            handleReturnAR();
          },
          isFormSubmit: true
        }
      ]}
    >
      <Stack alignContent="center" sx={{ mt: "0.5rem" }}>
        <AppFormMultiSelect
          name={"reasons"}
          control={control}
          data-testid="return-reason-select-box"
          label={"Reasons"}
          options={returnReasons}
        />
        <AppFormInput
          control={control}
          label={"Comments"}
          name={"comments"}
          error={errors.comments}
          data-testid="commentsInput"
          showError={true}
          multiline={true}
          rows={6}
          maxLength={500}
        />
      </Stack>
    </Dialog>
  );
}
