import { GridPaginationModel } from "@mui/x-data-grid";
import { useState } from "react";

export const usePagination = (defaultPage: number = 0, defaultPageSize: number = 10) => {
  const [currentPage, setCurrentPage] = useState<number>(defaultPage);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const paginationModelChangeHandler = ({ page, pageSize }: GridPaginationModel) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const resetPagination = () => {
    setCurrentPage(defaultPage);
    setPageSize(defaultPageSize);
  };

  return {
    currentPage,
    pageSize,
    paginationModelChangeHandler,
    resetPagination
  };
};
