export const APPROVAL_REQUEST_MAP_QUERY_KEY = "approval-request-map";
export const APPROVAL_REQUEST_QUERY_KEY = "approval-request";
export const APPROVAL_REQUEST_TYPE_LIST_QUERY_KEY = "approval-request-type-list";
export const AR_COMMENTARIES_QUERY_KEY = "commentaries";
export const AR_DISCIPLINE_AUTHORISATION_COMMENTS_QUERY_KEY = "discipline-authorisation-comments";
export const AR_DISCIPLINE_CLEARING_ALLOCATION_QUERY_KEY = "discipline-clearing-allocations";
export const AR_DISCIPLINE_QUERY_KEY = "approval-request-disciplines";
export const AR_DISCIPLINE_TRACKING_COMMENTS_INTERNAL_DEPENDENCIES_QUERY_KEY = "internal-dependencies";
export const AR_DISCIPLINE_TRACKING_COMMENTS_QUERY_KEY = "discipline-tracking-comments";
export const AR_LIST_QUERY_KEY = "approval-request-list";
export const AR_STATUS_TIMELINE_QUERY_KEY = "approval-request-status-timeline";
export const ASSIGNED_AR_COUNTS_TO_SME_QUERY_KEY = "sme-assigned-ar-counts";
export const ASSIGNED_ARS_TO_SME_QUERY_KEY = "sme-assigned-ars";
export const ATTACHMENT_COUNT_QUERY_KEY = "attachment-count";
export const ATTACHMENT_LINKS_QUERY_KEY = "attachment-links";
export const AVAILABLE_MATRIX_DISCIPLINES_QUERY_KEY = "available-matrix-disciplines";
export const BUSINESS_UNIT_LIST_QUERY_KEY = "business-unit-list";
export const DELETE_TRACKING_COMMENT_QUERY_KEY = "delete-tracking-comment";
export const DISCIPLINE_USER_MATRIX_BY_ID_QUERY_KEY = "discipline-user-matrix-by-id";
export const HUB_ID_QUERY_KEY = "hub-id";
export const HUB_LIST_QUERY_KEY = "hub-list";
export const HUBS_DATA_QUERY_KEY = "hubs";
export const NOTIFICATIONS_QUERY_KEY = "notifications";
export const PAST_APPROVAL_REQUEST_QUERY_KEY = "past-approval-request";
export const PERMIT_QUERY_KEY = "permit";
export const PROJECT_LIST_QUERY_KEY = "project-list";
export const QA_ANSWERS_QUERY_KEY = "ar-questions-answers";
export const RETURN_REASON_QUERY_KEY = "return-reason";
export const SEARCH_RESULT_QUERY_KEY = "search-result";
export const SITE_LIST_QUERY_KEY = "site-list";
export const SME_DASHBOARD_FILTER_OPTIONS_QUERY_KEY = "sme-dashboard-filter-options";
export const USER_DISCIPLINE_MATRIX_QUERY_KEY = "user-discipline-matrix";
export const USER_GRAPH_CONFIG_QUERY_KEY = "user-graph-configuration";
export const USER_HUB_MATRIX_QUERY_KEY = "user-hub-matrix";
export const USER_PROFILE_PHOTO_QUERY_KEY = "profile-photo";
export const WC_ANSWERS_QUERY_KEY = "ar-work-category-answers";
export const WC_LIST_QUERY_KEY = "ar-work-categories";
export const WC_SUBCATEGORY_LIST_QUERY_KEY = "ar-work-sub-categories";
export const INSTRUMENT_ID_LIST_QUERY_KEY = "instrument-id-list";
export const RESTRICTED_CLEARING_AREA_LIST_QUERY_KEY = "restricted-clearing-area-list";
export const CLEARING_ALLOCATION_AVAILABLE_BALANCE_QUERY_KEY = "clearing-allocation-available-balance";
