import { IOption } from "@/interfaces";

/**
 * Function that alphanumerically sorts the value property of an IOption.
 * @param a IOption value to compare.
 * @param b IOption value to compare against
 * @returns 1 | 0 | -1 to depict greater than, equal or less than.
 */
export const IOptionSorter = (a: IOption, b: IOption) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
};
