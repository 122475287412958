import { Backdrop, Box, Card, LinearProgress, Modal, Typography } from "@mui/material";

import { useARContext } from "@/context";

export function BackdropLoader() {
  const { loader } = useARContext();

  return (
    <Modal
      data-testid="loader-modal"
      open={loader?.open}
      aria-labelledby="navigation-blocker-modal-title"
      aria-describedby="navigation-blocker-modal-description"
    >
      <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={loader?.open}>
        <Card
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30rem",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 2
          }}
        >
          <Typography id="navigation-blocker-modal-title" variant="h6" component="h2">
            {loader?.message}
          </Typography>
          <Box width={"100%"} mt="1rem" mb={"1rem"}>
            <LinearProgress />
          </Box>

          {/* TODO: Uncomment code below for future use when adding button */}
          {/*<Stack direction="row" justifyContent="flex-end" alignItems="flex-end" marginTop={2}>
          Button Placeholder
        </Stack> */}
        </Card>
      </Backdrop>
    </Modal>
  );
}
