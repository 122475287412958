import { apiClient } from "@/api/client";
import {
  EMPTY_GUID,
  EMPTY_PAGINATED_RESPONSE,
  USER_DISCIPLINE_MATRIX_QUERY_KEY,
  AVAILABLE_MATRIX_DISCIPLINES_QUERY_KEY,
  DISCIPLINE_USER_MATRIX_BY_ID_QUERY_KEY,
  ADD_DISCIPLINE_USER_MATRIX_MUTATION_KEY,
  UPDATE_DISCIPLINE_USER_MATRIX_MUTATION_KEY
} from "@/constants";
import {
  DisciplineUserMatrixRequestList,
  DisciplineUserMatrixResponse,
  IOption,
  PaginationRequest
} from "@/interfaces";
import { AddDisciplineUserMatrix, UpdateDisciplineUserMatrix } from "@/interfaces/api/DisciplineUserMatrix";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const disciplineUserMatrixPath = "/disciplineUserMatrix";

export function useDisciplineUserMatrix(
  params: {
    hubId: string;
  } & PaginationRequest
) {
  return useQuery({
    queryKey: [USER_DISCIPLINE_MATRIX_QUERY_KEY, params.hubId, params.pageNumber, params.pageSize],
    queryFn: () => {
      return apiClient
        .get<DisciplineUserMatrixRequestList>(`${disciplineUserMatrixPath}/assigned/paginated`, {
          params: params
        })
        .then((response) => response.data);
    },
    initialData: EMPTY_PAGINATED_RESPONSE as DisciplineUserMatrixRequestList,
    enabled: params.hubId !== "" && params.hubId !== EMPTY_GUID,
    keepPreviousData: true
  });
}

export function useAddDisciplineUserMatrix(params: PaginationRequest, onSuccessCallback: () => void) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [ADD_DISCIPLINE_USER_MATRIX_MUTATION_KEY],
    mutationFn: (request: AddDisciplineUserMatrix) =>
      apiClient.post<DisciplineUserMatrixResponse>(`${disciplineUserMatrixPath}`, request),
    onSuccess: (response) => {
      onSuccessCallback();
      queryClient.invalidateQueries([
        USER_DISCIPLINE_MATRIX_QUERY_KEY,
        response.data.hubId,
        params.pageNumber,
        params.pageSize
      ]);
    }
  });
}

export function useAvailableMatrixDisciplines(hubId: string, siteId?: string, businessUnitId?: string) {
  return useQuery({
    queryKey: [AVAILABLE_MATRIX_DISCIPLINES_QUERY_KEY, hubId, siteId, businessUnitId],
    queryFn: () => {
      return apiClient
        .get<IOption[]>(`${disciplineUserMatrixPath}/disciplines`, {
          params: {
            hubId,
            siteId: siteId ?? null,
            businessUnitId: businessUnitId ?? null
          }
        })
        .then((response) => response.data);
    },
    initialData: []
  });
}

export function useGetDisciplineUserMatrixById(id?: string) {
  return useQuery({
    queryKey: [DISCIPLINE_USER_MATRIX_BY_ID_QUERY_KEY, id],
    queryFn: () => {
      return apiClient
        .get<DisciplineUserMatrixResponse>(`${disciplineUserMatrixPath}/${id}`)
        .then((response) => response.data);
    },
    enabled: id !== undefined || id !== ""
  });
}

export function useUpdateDisciplineUserMatrix(params: PaginationRequest, onSuccessCallback: () => void) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [UPDATE_DISCIPLINE_USER_MATRIX_MUTATION_KEY],
    mutationFn: (request: UpdateDisciplineUserMatrix) =>
      apiClient.put<DisciplineUserMatrixResponse>(`${disciplineUserMatrixPath}`, request),
    onSuccess: (response) => {
      onSuccessCallback();
      queryClient.invalidateQueries([
        USER_DISCIPLINE_MATRIX_QUERY_KEY,
        response.data.hubId,
        params.pageNumber,
        params.pageSize
      ]);
      queryClient.invalidateQueries([DISCIPLINE_USER_MATRIX_BY_ID_QUERY_KEY]);
    }
  });
}
