import { GridColDef } from "@mui/x-data-grid";
import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

import {
  AddArcGisLayerRequest,
  AdminTableErrorMessage,
  AdminTablePageDetails,
  AdminTableSnackBar,
  IOption
} from "@/interfaces";

export const addIsActiveAtom = atom<boolean | null>(null);
export const editIsActiveAtom = atom<boolean | null>(null);
export const deleteIsActiveAtom = atom<boolean | null>(null);
export const enableIsActiveAtom = atom<boolean | null>(null);
export const disableIsActiveAtom = atom<boolean | null>(null);

export const columnDataAtom = atom<GridColDef[]>([]);

export const configurationItemSubheaderAtom = atom<string>("");

export const adminTablePageDetailsAtom = atomWithReset<AdminTablePageDetails>({
  page: 1,
  pageSize: 10
} as AdminTablePageDetails);

export const adminTableSnackBarAtom = atomWithReset<AdminTableSnackBar>({} as AdminTableSnackBar);

export const selectedRowIdCountAtom = atom<number>(0);
export const selectedRowIdAtom = atom<string>("");
export const selectedRowIdListAtom = atom<string[]>([]);
export const hubNameAtom = atom<string>("");

// This is for render the error messsage for the validation
export const adminTableErrorMessageAtom = atom<AdminTableErrorMessage>({} as AdminTableErrorMessage);

// this is for input invidual field validation error message.
export const errorMessageAtom = atom<string>("");

export const activeConfigurationItemAtom = atom<string>("");

export const openStatusSnackbarAtom = atom<boolean>(false);

export const initialArcGisLayerDataAtom = atom<AddArcGisLayerRequest[]>([]);
export const interceptionAnalysisTabAtom = atom<string>("1");

export const selectedLayerOptionAtom = atom<IOption[][]>([
  [{ id: "", value: "" }],
  [{ id: "", value: "" }],
  [{ id: "", value: "" }]
]);

export const selectedServiceAtom = atom<string[]>([]);

export const arcgisServiceOptionsAtom = atom<IOption[]>([{ id: "-1", value: "" }]);
