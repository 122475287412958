import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TextField, debounce } from "@mui/material";
import { attachmentLinkNameSchema, attachmentLinkSchema, attachmentLinkUrlSchema } from "@/validations";
import { AttachmentLinkModalMode } from "@/interfaces";
import { useAttachmentLinkContext } from "@/context";
import { Dialog, DialogAction } from "@/components/dialogs";
import { ValidationError, AnyObjectSchema } from "yup";

export interface AttachmentLinkInputModal {
  name?: string;
  url?: string;
}

interface AttachmentLinkDialogConfiguration {
  title: string;
  actions: DialogAction[];
}

export function AttachmentLinkModal() {
  const { addAttachmentLink, updateAttachmentLink, attachmentLinkModalConfig, setAttachmentLinkModalConfig } =
    useAttachmentLinkContext();

  const { mode, modalIsOpen } = attachmentLinkModalConfig;

  const [attachmentLinkInputValue, setAttachmentLinkInputValue] = useState<AttachmentLinkInputModal>({
    name: "",
    url: ""
  });
  const [attachmentLinkInputError, setAttachmentLinkInputError] = useState<Record<string, string>>({});

  useEffect(() => {
    switch (mode) {
      case "Add":
        setAttachmentLinkInputValue({
          name: "",
          url: ""
        });
        break;
      case "Edit":
        setAttachmentLinkInputValue({
          name: attachmentLinkModalConfig.attachmentLinkInEdit.name,
          url: attachmentLinkModalConfig.attachmentLinkInEdit.url
        });
        break;
    }
    setAttachmentLinkInputError({});
  }, [attachmentLinkModalConfig, mode]);

  const validateInput = debounce((input: AttachmentLinkInputModal, path: string, schema: AnyObjectSchema) => {
    schema
      .validateAt(
        path,
        {
          name: (input.name ?? "").trimEnd(),
          url: (input.url ?? "").trimEnd()
        },
        { abortEarly: false }
      )
      .then(() => {
        setAttachmentLinkInputError((prev) => {
          delete prev[path];
          return { ...prev };
        });
      })
      .catch((error: ValidationError) => {
        setAttachmentLinkInputError((prev) => ({ ...prev, [path]: error.message }));
      });
  }, 250);

  const cancelModal = useCallback(() => {
    setAttachmentLinkModalConfig((prev) => ({ ...prev, modalIsOpen: false }));
  }, [setAttachmentLinkModalConfig]);

  const configurations: Record<AttachmentLinkModalMode, AttachmentLinkDialogConfiguration> = useMemo(
    () => ({
      Add: {
        title: "Add Link",
        actions: [
          {
            label: "Cancel",
            onClick: cancelModal
          },
          {
            label: "Add",
            disabled: !attachmentLinkSchema.isValidSync(attachmentLinkInputValue),
            onClick: () => addAttachmentLink(attachmentLinkInputValue.name ?? "", attachmentLinkInputValue.url ?? ""),
            isFormSubmit: true
          }
        ]
      },
      Edit: {
        title: "Edit Link",
        actions: [
          {
            label: "Cancel",
            onClick: cancelModal
          },
          {
            label: "Apply",
            disabled: !attachmentLinkSchema.isValidSync(attachmentLinkInputValue),
            onClick: () => {
              if (mode === "Edit") {
                updateAttachmentLink({
                  ...attachmentLinkModalConfig.attachmentLinkInEdit,
                  ...attachmentLinkInputValue
                });
              }
            },
            isFormSubmit: true
          }
        ]
      }
    }),
    [addAttachmentLink, attachmentLinkModalConfig, attachmentLinkInputValue, cancelModal, mode, updateAttachmentLink]
  );

  return (
    <Dialog
      open={modalIsOpen}
      onClose={cancelModal}
      title={configurations[mode]?.title}
      actions={configurations[mode]?.actions}
    >
      <TextField
        sx={{ marginTop: 2, width: "100%" }}
        required
        error={attachmentLinkInputError["name"] !== undefined}
        helperText={attachmentLinkInputError["name"]}
        data-testid="link-name-input"
        label="Name"
        value={attachmentLinkInputValue.name}
        inputProps={{ maxLength: 250, "data-testid": "attachment-link-modal-input-name" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const input = {
            ...attachmentLinkInputValue,
            name: event.target.value
          };
          setAttachmentLinkInputValue((prev) => ({
            ...prev,
            name: event.target.value
          }));
          validateInput(input, "name", attachmentLinkNameSchema);
        }}
      />
      <TextField
        sx={{ marginTop: 2, width: "100%" }}
        required
        error={attachmentLinkInputError["url"] !== undefined}
        helperText={attachmentLinkInputError["url"]}
        data-testid="link-url-input"
        label="URL"
        value={attachmentLinkInputValue.url}
        inputProps={{ maxLength: 2048, "data-testid": "attachment-link-modal-input-url" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const input = {
            ...attachmentLinkInputValue,
            url: event.target.value
          };
          setAttachmentLinkInputValue((prev) => ({
            ...prev,
            url: event.target.value
          }));
          validateInput(input, "url", attachmentLinkUrlSchema);
        }}
      />
    </Dialog>
  );
}
