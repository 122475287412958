import { useCallback, useEffect, useMemo, useState } from "react";
import { useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { FieldError, useForm } from "react-hook-form";
import { Box, Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { ValidationError } from "yup";

import { GraphUser, HubUsers, IOption, LAMSRole } from "@/interfaces";
import { PartialRecord } from "@/types";
import { ADMIN_FORM, UNSAVED_CHANGES_MODAL } from "@/constants";
import { toTitleCaseFromCamelCase } from "@/utils";
import { userHubRoleSchema } from "@/validations";
import { navigationBlockerAtom, snackBarAtom } from "@/stores";
import { useGraphUsers, useUserHubMatrix } from "@/hooks";
import { AppFormSelect } from "@/components/fields";

import { UserHubMatrixConfigurationProps } from "./UserHubMatrixConfiguration";

type UserHubIdByRoles = {
  [LAMSRole.Endorser]: string;
  [LAMSRole.Coordinator]: string;
};

const USER_HUB_ROLES: LAMSRole[] = [LAMSRole.Endorser, LAMSRole.Coordinator] as const;

export function HubUserConfiguration({ hubId }: UserHubMatrixConfigurationProps) {
  const [errors, setErrors] = useState<Record<number, { message: string }>>({});

  const setNavigationBlocker = useSetAtom(navigationBlockerAtom);
  const resetNavigationBlockerAtom = useResetAtom(navigationBlockerAtom);

  const setSnackBar = useSetAtom(snackBarAtom);
  const { data: allGraphUsers, isFetched: isGraphUsersFetched } = useGraphUsers(USER_HUB_ROLES);

  const { userHubMatrix, isUserHubSuccess, upsertUserHub } = useUserHubMatrix(hubId, () => {
    setSnackBar({
      message: ADMIN_FORM.SAVE_USER_HUB_MATRIX_SUCCESSFUL,
      open: true
    });
  });

  const indexedGraphUserByRole = useMemo(() => {
    return allGraphUsers.reduce(
      (acc: { [key in GraphUser["role"]]: IOption[] }, user: GraphUser) => {
        const { role } = user;

        if (!acc[role]) {
          acc[role] = [];
        }

        acc[role].push({ id: user.id, value: user.displayName });

        return acc;
      },
      {} as { [key in GraphUser["role"]]: IOption[] }
    );
  }, [allGraphUsers]);

  const indexHubUsersByRole = useMemo(() => {
    const indexedUsers: PartialRecord<LAMSRole, string> = {};

    userHubMatrix.forEach(({ roleId, userId }) => {
      indexedUsers[roleId] = userId;
    });

    return indexedUsers;
  }, [userHubMatrix]);

  const defaultUserHubFields: UserHubIdByRoles = {
    [LAMSRole.Endorser]: indexHubUsersByRole[LAMSRole.Endorser] ?? "",
    [LAMSRole.Coordinator]: indexHubUsersByRole[LAMSRole.Coordinator] ?? ""
  };

  const {
    control,
    getValues,
    reset,
    formState: { isDirty }
  } = useForm<UserHubIdByRoles>({
    defaultValues: defaultUserHubFields
  });

  const initialiseForm = useCallback(() => {
    reset({
      [LAMSRole.Endorser]: indexHubUsersByRole[LAMSRole.Endorser],
      [LAMSRole.Coordinator]: indexHubUsersByRole[LAMSRole.Coordinator]
    });
    setErrors({});
  }, [indexHubUsersByRole, reset]);

  useEffect(() => {
    initialiseForm();
  }, [initialiseForm]);

  const validateAndUpdateUsers = useCallback(
    (onFail?: () => void) => {
      const request: UserHubIdByRoles = getValues();
      userHubRoleSchema
        .validate(
          {
            ...request
          },
          { abortEarly: false }
        )
        .then(() => {
          const hubUsers: HubUsers[] = USER_HUB_ROLES.map((key: LAMSRole) => ({
            roleId: key,
            userId: request[key as keyof UserHubIdByRoles]
          }));
          upsertUserHub(hubUsers);
        })
        .catch((errors: ValidationError) => {
          errors.inner.forEach(({ path, message }) => {
            setErrors((prev) => ({
              ...prev,
              [Number(path) as keyof UserHubIdByRoles]: {
                message: message
              }
            }));
          });
          onFail?.();
        });
    },
    [getValues, upsertUserHub]
  );

  useEffect(() => {
    setNavigationBlocker({
      title: UNSAVED_CHANGES_MODAL.TITLE,
      message: UNSAVED_CHANGES_MODAL.MESSAGE,
      isBlocked: isDirty,
      actionButtons: [
        {
          label: UNSAVED_CHANGES_MODAL.DISMISS_BUTTON,
          onClick: (blocker) => {
            initialiseForm();
            resetNavigationBlockerAtom();
            blocker.proceed?.();
          }
        },
        {
          label: UNSAVED_CHANGES_MODAL.CONFIRM_BUTTON,
          onClick: (blocker) => {
            validateAndUpdateUsers(blocker.reset);
          }
        }
      ]
    });
  }, [hubId, initialiseForm, isDirty, resetNavigationBlockerAtom, setNavigationBlocker, validateAndUpdateUsers]);

  function SaveAndCancelButtons() {
    return (
      <Box>
        <Button
          variant="text"
          onClick={() => {
            initialiseForm();
          }}
          disabled={!isDirty}
        >
          CANCEL
        </Button>
        <Button
          disabled={!isDirty}
          variant="text"
          onClick={() => {
            validateAndUpdateUsers();
          }}
        >
          SAVE
        </Button>
      </Box>
    );
  }

  return (
    <Card sx={{ padding: "0.5rem", overflow: "auto" }}>
      <CardHeader title="Approvals Delivery Team" action={<SaveAndCancelButtons />} />
      <CardContent>
        {!isGraphUsersFetched && !isUserHubSuccess ? (
          "Loading..."
        ) : (
          <Stack>
            {USER_HUB_ROLES.map((key: LAMSRole) => (
              <Stack sx={{ width: "35rem" }}>
                <AppFormSelect
                  key={key}
                  disabled={hubId === ""}
                  control={control}
                  label={toTitleCaseFromCamelCase(LAMSRole[key])}
                  name={String(key)}
                  options={indexedGraphUserByRole[key] ?? []}
                  error={errors?.[key] as FieldError}
                  data-testid={`select-user-hub-config-${LAMSRole[key]}`}
                  showError={true}
                  initialValue={indexHubUsersByRole[key]}
                  resetError={() => setErrors({})}
                  isOptional={true}
                />
              </Stack>
            ))}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
