export const PATH = {
  BASE: "/",
  MY_REQUESTS: "/my-requests",
  DETECTED_INTERCEPTS: "/detected-intercept",
  ADMIN_SETTINGS: "/settings",
  REPORTING: "/reporting",
  SEARCH: "/search"
} as const;

export const DETECTED_INTERCEPT_PATH = {
  TABLE: `${PATH.DETECTED_INTERCEPTS}`,
  HISTORY: `${PATH.DETECTED_INTERCEPTS}/history`,
  CARDS: `${PATH.DETECTED_INTERCEPTS}/cards`
} as const;

export const APPROVAL_REQUEST_PATH = {
  BASE: `${PATH.MY_REQUESTS}/:approvalRequestId/`,
  DETAILS: `${PATH.MY_REQUESTS}/:approvalRequestId/details`,
  ATTACHMENTS: `${PATH.MY_REQUESTS}/:approvalRequestId/attachments`,
  COLLABORATION: `${PATH.MY_REQUESTS}/:approvalRequestId/collaboration`,
  PERMIT_TAB: `${PATH.MY_REQUESTS}/:approvalRequestId/permit`,
  PERMIT_PREVIEW: `${PATH.MY_REQUESTS}/:approvalRequestId/permit-preview`,
  HISTORY: `${PATH.MY_REQUESTS}/:approvalRequestId/history`,
  DISCIPLINE_TAB: `${PATH.MY_REQUESTS}/:approvalRequestId/:approvalRequestDisciplineId`,
  MAP_TAB: `${PATH.MY_REQUESTS}/:approvalRequestId/map`
};

export const ADMIN_SETTINGS_PATH = {
  SETTINGS: `${PATH.ADMIN_SETTINGS}`,
  HUBS_AND_SITES: `${PATH.ADMIN_SETTINGS}/hubs-and-sites`,
  USER_MATRIX: `${PATH.ADMIN_SETTINGS}/user-matrix`,
  USER_HUB_MATRIX: `${PATH.ADMIN_SETTINGS}/user-matrix/:hubId`,
  AR_OWNERS: `${PATH.ADMIN_SETTINGS}/ar-owners`,
  ROLES_AND_PERMISSIONS: `${PATH.ADMIN_SETTINGS}/roles-and-permissions`,
  NOTIFICATIONS: `${PATH.ADMIN_SETTINGS}/notifications`,
  SENSITIVITY_ANALYSIS_RULES: `${PATH.ADMIN_SETTINGS}/sensitivity-analysis-rules`,
  INTERCEPTION_ANALYSIS: `${PATH.ADMIN_SETTINGS}/interception-analysis`,
  ARCGIS_SERVICES: `${PATH.ADMIN_SETTINGS}/arc-gis-services`
} as const;

export const ALL_PATHS = {
  ...PATH,
  ...DETECTED_INTERCEPT_PATH,
  ...APPROVAL_REQUEST_PATH,
  ...ADMIN_SETTINGS_PATH
} as const;

export const APPROVAL_REQUEST_TABS = {
  ATTACHMENTS: "Attachments",
  COLLABORATION: "Collaboration",
  DETAILS: "Details",
  HISTORY: "History",
  PERMIT: "Permit",
  MAP: "Map"
};
