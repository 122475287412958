import { Fragment } from "react";
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { AdminSettingsPath } from "@/interfaces";
import { ADMIN_SETTINGS_PATH } from "@/constants";

interface SettingsItemLink {
  label: string;
  path: AdminSettingsPath;
  dividerAbove?: boolean;
}

const settingsItemLinks: SettingsItemLink[] = [
  {
    label: "Hubs and Sites",
    path: ADMIN_SETTINGS_PATH.HUBS_AND_SITES,
    dividerAbove: false
  },
  {
    label: "User Matrix",
    path: ADMIN_SETTINGS_PATH.USER_MATRIX,
    dividerAbove: false
  },
  {
    label: "AR Owners",
    path: ADMIN_SETTINGS_PATH.AR_OWNERS,
    dividerAbove: false
  },
  {
    label: "Roles and Permissions",
    path: ADMIN_SETTINGS_PATH.ROLES_AND_PERMISSIONS,
    dividerAbove: false
  },
  {
    label: "Notifications",
    path: ADMIN_SETTINGS_PATH.NOTIFICATIONS,
    dividerAbove: false
  },
  {
    label: "Sensitivity Analysis Rules",
    path: ADMIN_SETTINGS_PATH.SENSITIVITY_ANALYSIS_RULES,
    dividerAbove: true
  },
  {
    label: "Interception Analysis",
    path: ADMIN_SETTINGS_PATH.INTERCEPTION_ANALYSIS,
    dividerAbove: false
  },
  {
    label: "ArcGIS Services",
    path: ADMIN_SETTINGS_PATH.ARCGIS_SERVICES,
    dividerAbove: true
  }
];

export function SettingItemList() {
  const navigate = useNavigate();

  return (
    <Drawer
      PaperProps={{
        style: {
          position: "unset",
          width: "16rem",
          boxSizing: "border-box",
          borderRight: 0
        }
      }}
      sx={{
        height: "100%",
        flexShrink: 0
      }}
      variant="permanent"
      anchor="left"
    >
      <Typography variant="h4" marginLeft="1rem">
        Settings
      </Typography>
      <List>
        {settingsItemLinks.map(({ label, path, dividerAbove }, index) => {
          const htmlFriendlyLabel = label.replaceAll(" ", "-");

          return (
            <Fragment key={index}>
              {dividerAbove && <Divider />}
              <ListItem key={`${htmlFriendlyLabel}-${index}`} disablePadding>
                <ListItemButton
                  data-testid={`${htmlFriendlyLabel}-button`}
                  onClick={() => {
                    navigate(path);
                  }}
                >
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    </Drawer>
  );
}
