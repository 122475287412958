import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ClearIcon from "@mui/icons-material/Clear";

import { colours } from "@/theme/colour";
import { Attachment, AttachmentUploadStatus, AttachmentSet } from "@/interfaces";
import "../styles/attachmentUploadAnimation.css";
import { useAuthorization } from "@/context";
import { downloadAttachment } from "@/services";

interface UploadedAttachmentsDisplayProps {
  handleDeleteClick: (fileId: string, attachment: Attachment) => void;
  attachmentSet: AttachmentSet;
  approvalRequestId: string;
}

interface AttachmentTitleProps {
  attachment: Attachment;
  approvalRequestId: string;
  attachmentId: string;
}

const getStatusColor = (attachment: Attachment) => {
  return attachment.uploadStatus === AttachmentUploadStatus.Failed ? "error" : "action";
};

function AttachmentTitle({ attachment, approvalRequestId, attachmentId }: AttachmentTitleProps) {
  const [attachmentNameIsHovered, setAttachmentNameIsHovered] = useState(false);

  const linkStyles = {
    textDecoration: "none",
    color: attachmentNameIsHovered ? colours.hyperlink : "inherit",
    transition: "color 0.2s ease-in-out",
    cursor: "pointer"
  };

  const disabledLinkStyles = {
    textDecoration: "none",
    color: "inherit",
    outline: "none",
    cursor: "inherit"
  };

  const toggleTitleOnHover = () => setAttachmentNameIsHovered((prev) => !prev);

  return (
    <a
      onClick={() => downloadAttachment(approvalRequestId, attachmentId)}
      style={attachment.uploadStatus === AttachmentUploadStatus.Successful ? linkStyles : disabledLinkStyles}
      onMouseEnter={toggleTitleOnHover}
      onMouseLeave={toggleTitleOnHover}
    >
      <Typography
        color={getStatusColor(attachment)}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        maxWidth="30rem"
      >
        {`${attachment.fileName}${attachment.contentType}`}
      </Typography>
    </a>
  );
}

export function UploadedAttachmentsDisplay({
  attachmentSet,
  handleDeleteClick,
  approvalRequestId
}: UploadedAttachmentsDisplayProps) {
  const attachments = Object.entries(attachmentSet);
  const { userId } = useAuthorization();

  const dateComparator = (attachmentA: Attachment, attachmentB: Attachment) => {
    if (attachmentA.dateChanged && attachmentB.dateChanged) {
      return attachmentB.dateChanged.getTime() - attachmentA.dateChanged.getTime();
    } else if (attachmentA.dateChanged) {
      return -1;
    } else if (attachmentB.dateChanged) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <div data-testid="attachment-upload-display">
      {attachments.length > 0 ? (
        <List sx={{ maxHeight: "30rem", overflow: "auto" }} data-testid="uploaded-files-list" dense>
          <TransitionGroup>
            {attachments
              .sort(([, attachmentA], [, attachmentB]) => dateComparator(attachmentA, attachmentB))
              .map(([fileId, attachment]) => (
                <CSSTransition
                  key={fileId}
                  timeout={500}
                  classNames="item"
                  in={attachment.uploadStatus === AttachmentUploadStatus.Processing}
                >
                  <ListItem
                    data-testid={`item-${attachment.fileName}`}
                    key={fileId}
                    className={attachment.uploadStatus === AttachmentUploadStatus.Processing ? "no-animation" : ""}
                    secondaryAction={
                      <Stack direction="row" spacing={1.5} alignItems="center">
                        {attachment.uploadStatus === AttachmentUploadStatus.Successful ? (
                          <ListItemText
                            primary={
                              <Typography color={"action"}>
                                {`${(attachment.fileSize / 1024).toFixed(2)} KB`}
                              </Typography>
                            }
                          />
                        ) : attachment.uploadStatus === AttachmentUploadStatus.Processing ? (
                          <>
                            <CircularProgress size="1.3rem" />
                            <Typography color="action">Loading</Typography>
                          </>
                        ) : (
                          <Typography color="error" data-testid={`item-error-${attachment.fileName}`}>
                            {attachment.errorMessage ? attachment.errorMessage : "Failed"}
                          </Typography>
                        )}

                        <ListItemButton
                          data-testid={`item-delete-${attachment.fileName}`}
                          onClick={() => {
                            handleDeleteClick(fileId, attachment);
                          }}
                          style={{
                            visibility: [userId, ""].includes(attachment.createdBy) ? "visible" : "hidden"
                          }}
                        >
                          <ClearIcon color="action" />
                        </ListItemButton>
                      </Stack>
                    }
                  >
                    <ListItemIcon>
                      {attachment.uploadStatus === AttachmentUploadStatus.Failed ? (
                        <ErrorOutlineIcon color={getStatusColor(attachment)} />
                      ) : (
                        <InsertDriveFileIcon color={getStatusColor(attachment)} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <AttachmentTitle
                          attachment={attachment}
                          approvalRequestId={approvalRequestId}
                          attachmentId={fileId}
                        />
                      }
                    />
                  </ListItem>
                </CSSTransition>
              ))}
          </TransitionGroup>
        </List>
      ) : null}
    </div>
  );
}
