import { QuestionAnswer } from "@/interfaces";
import { Grid } from "@mui/material";
import { QuestionRadioButton } from "./QuestionRadioButton";
import { HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";
import { QuestionNumericText } from "./QuestionNumericText";

export interface QuestionAnswerProps {
  questionAnswer: QuestionAnswer;
  questionHightlightId?: string | undefined;
  readOnly: boolean;
  handleQuestionAnswerChange: (questionAnswer: QuestionAnswer, value: string) => void;
  formHelperErrorClass?: string;
  error?: string;
}

export function QuestionComponent({
  questionAnswer,
  questionHightlightId,
  readOnly,
  handleQuestionAnswerChange,
  formHelperErrorClass,
  error
}: QuestionAnswerProps) {
  const QuestionComponentType = () => {
    switch (questionAnswer.componentName) {
      case "radioConfirm":
      case "radioPartial": {
        return (
          <QuestionRadioButton
            questionAnswer={questionAnswer}
            handleQuestionAnswerChange={handleQuestionAnswerChange}
            readOnly={readOnly ?? false}
            formHelperErrorClass={formHelperErrorClass}
            error={error}
          />
        );
      }
      case "numericText":
        return (
          <QuestionNumericText
            questionAnswer={questionAnswer}
            handleQuestionAnswerChange={handleQuestionAnswerChange}
            readOnly={readOnly ?? false}
            formHelperErrorClass={formHelperErrorClass}
            error={error}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid
      item
      xs={
        (questionAnswer.displayLevel === 1 &&
          questionAnswer.subQuestionAnswers.find((itm) => itm.displayLevel === 2)) ||
        questionAnswer.displayLevel === 2
          ? questionAnswer.displayLevel === 2
            ? 4
            : 8
          : 12
      }
      className={questionAnswer.questionId === questionHightlightId ? HIGHLIGHT_FIELD_CLASS_NAMES.BACKGROUND : ""}
    >
      {QuestionComponentType()}
    </Grid>
  );
}
