import { DisciplineAuthorisationCommentValue } from "@/interfaces";
import { Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getAuthCommentFieldsFromContent, indexedDisciplineAuthCommentValues } from "@/utils";
import { ConfigurableFieldComponent } from "./ConfigurableFieldComponent";

interface ConfigurableFormProps {
  disciplineAuthCommentValue: DisciplineAuthorisationCommentValue;
  childValueConfigurations: DisciplineAuthorisationCommentValue[] | undefined;
  disabled: boolean;
  onDelete: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  onUpdateChildAuthComment: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  isEditable: boolean;
  onActionHandler: (isEdit: boolean) => void;
}

export function ConfigurableForm({
  disciplineAuthCommentValue,
  childValueConfigurations,
  disabled,
  onDelete,
  onUpdateChildAuthComment,
  isEditable,
  onActionHandler
}: ConfigurableFormProps) {
  const formContents = getAuthCommentFieldsFromContent(disciplineAuthCommentValue.value);
  const indexedChildDisciplineAuthCommentValues = indexedDisciplineAuthCommentValues(childValueConfigurations!);
  const formComponents: JSX.Element[] = [];

  formContents.forEach((field) => {
    if (!indexedChildDisciplineAuthCommentValues[field]) return;
    const breakpointSize =
      indexedChildDisciplineAuthCommentValues[field].authorisationCommentField.componentType === "editableText"
        ? 12
        : 6;
    formComponents.push(
      <Grid item xs={breakpointSize} sx={{ pb: 2 }}>
        <ConfigurableFieldComponent
          key={`${indexedChildDisciplineAuthCommentValues[field].name}`}
          disciplineAuthCommentValue={indexedChildDisciplineAuthCommentValues[field]}
          onUpdate={onUpdateChildAuthComment}
          onDelete={onDelete}
          isEditable={isEditable}
          onActionHandler={onActionHandler}
          disabled={disabled}
          childValueConfigurations={
            indexedChildDisciplineAuthCommentValues[field].childDisciplineAuthorisationCommentValues
          }
        />
      </Grid>
    );
  });
  return (
    formComponents.length > 0 && (
      <Grid container direction="row">
        <Grid item xs={10}>
          <Grid container direction="row" justifyContent="flex-start">
            {formComponents}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="row" justifyContent="flex-end">
            <Button
              variant="text"
              disabled={disabled}
              onClick={(event) => {
                event.preventDefault();
                onDelete(disciplineAuthCommentValue);
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
