import { RouteObject } from "react-router-dom";

import { PATH, DETECTED_INTERCEPT_PATH } from "@/constants";
import { DetectedInterceptLayout, NavigationLayout } from "@/layouts";
import { CardViewComponent, TableViewComponent, HistoryViewComponent } from "@/features/home/components";

const DetectedInterceptNestedRoutes: RouteObject = {
  path: PATH.DETECTED_INTERCEPTS,
  element: <DetectedInterceptLayout />,
  children: [
    {
      index: true,
      element: <TableViewComponent />
    },
    {
      path: DETECTED_INTERCEPT_PATH.CARDS,
      element: <CardViewComponent />
    },
    {
      path: DETECTED_INTERCEPT_PATH.HISTORY,
      element: <HistoryViewComponent />
    }
  ]
};

export const DetectedInterceptRoutes: RouteObject = {
  path: PATH.DETECTED_INTERCEPTS,
  element: <NavigationLayout />,
  children: [DetectedInterceptNestedRoutes]
};
