import { RouteObject } from "react-router-dom";
import { PATH } from "@/constants";
import { HomePage, ARSearch } from "@/pages";
import { NavigationLayout } from "@/layouts";

export const HomeRoutes: RouteObject = {
  path: PATH.BASE,
  element: <NavigationLayout />,
  children: [
    {
      index: true,
      element: <HomePage />
    },
    {
      path: PATH.SEARCH,
      element: <ARSearch />
    }
  ]
};
