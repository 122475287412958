export const MANDATORY_FIELD = {
  DEFAULT: "*This field is mandatory",
  QA_SECTION: "*Every question must be answered.",
  WC_SECTION: "*Ensure work category has been filled correctly.",
  MANDATORY_DATE: "Date is mandatory",
  DISCIPLINE_DEPENDENCIES: "At least one dependency must be selected"
};

export const NUMERIC_FIELD_VALIDATION_MESSAGE = {
  MUST_BE_POSITIVE: "*Value must be greater than zero"
};

export const AR_BD_ERRORS = {
  COST_CODE: "Please enter a valid cost code, or leave it blank.",
  REQUIRED_BY_DATE: "Min. date is submission +4 months"
};

export const AR_QA_ERRORS = {
  INVALID_CLEARING_VALUE: "*Value must be greater than zero when 'Clearing' is 'Yes'"
};

export const AR_WC_ERRORS = {
  INVALID_AREA_VALUE: "Area cannot be 0 or less than 0"
};

export const AR_TC_ERRORS = {
  VALID_DATE: "Please enter a valid Expected By date."
};

export const ATTACHMENT_LINK_ERRORS = {
  MANDATORY_FIELD: "This field is mandatory", // Without asterisk compare with Mandatory field
  INVALID_URL: "Please enter a valid URL"
};
