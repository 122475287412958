import { useMutation, useQuery } from "@tanstack/react-query";
import {
  AR_DISCIPLINE_TRACKING_COMMENTS_INTERNAL_DEPENDENCIES_QUERY_KEY,
  AR_DISCIPLINE_TRACKING_COMMENTS_QUERY_KEY,
  AR_UNEXPECTED_ERROR_BANNER
} from "@/constants";
import { DisciplineTrackingComment, InternalDependency } from "@/interfaces";
import { useARContext } from "@/context";
import { useEffect } from "react";
import { apiClient } from "@/api/client";

const basePath = "/ApprovalRequestDiscipline";

export function useDisciplineTrackingComments(approvalRequestDisciplineId?: string) {
  const { appendAlertBanner } = useARContext();

  const { error, ...rest } = useQuery({
    queryKey: [AR_DISCIPLINE_TRACKING_COMMENTS_QUERY_KEY, approvalRequestDisciplineId],
    queryFn: () =>
      apiClient
        .get<DisciplineTrackingComment[]>(`${basePath}/${approvalRequestDisciplineId}/tracking-comments`)
        .then((response) => response.data),
    enabled: approvalRequestDisciplineId !== undefined,
    initialData: []
  });

  useEffect(() => {
    if (error) {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  }, [error, appendAlertBanner]);

  return { ...rest, error };
}

export function useUpdateDisciplineTrackingComment(
  onSuccessHandler: (updatedDisciplineTrackingComment: DisciplineTrackingComment) => void
) {
  const { appendAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (updatedComment: DisciplineTrackingComment) =>
      apiClient
        .put<DisciplineTrackingComment>(
          `${basePath}/${updatedComment.approvalRequestDisciplineId}/tracking-comments/${updatedComment.id}`,
          updatedComment
        )
        .then((response) => response.data),
    onSuccess: (data) => {
      onSuccessHandler(data);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}

export function useInternalDependencies(approvalRequestId: string) {
  return useQuery({
    queryKey: [AR_DISCIPLINE_TRACKING_COMMENTS_INTERNAL_DEPENDENCIES_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient
        .get<InternalDependency[]>(`${basePath}/${approvalRequestId}/tracking-comments-dependency`)
        .then((response) => response.data),
    initialData: []
  });
}
