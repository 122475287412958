export enum ApprovalRequestReturnStatus {
  Pending = 0,
  Accepted = 1,
  Declined = 2
}

export interface ARReturnFormFields {
  approvalRequestId: string;
  approvalRequestDisciplineId?: string;
  reasons: string[];
  comments: string;
}

export enum ReturnDraftPermitRecipient {
  Requestor,
  SME
}

export interface ARReturnPermitFormFields {
  returnTo: ReturnDraftPermitRecipient;
  disciplineToUnauthorise: string[];
  comments: string;
}
