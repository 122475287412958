import { ARDivider } from "@/components";
import { Grid } from "@mui/material";
import { ARShowDate } from "./ARShowDate";
import { ARShowPeopleComponent } from "./ARShowPeopleComponent";
import { ARStatusTimeline } from "./ARStatusTimeline";
import { useARContext } from "@/context";

export function ARDetailsOverview() {
  const { approvalRequestId, approvalRequest } = useARContext();

  return (
    <Grid item height="100%" px="1.5rem" py="1rem">
      <Grid container>
        <ARStatusTimeline approvalRequestId={approvalRequestId} />
      </Grid>
      <ARDivider />
      <Grid container>
        <Grid item>
          <ARShowDate permitExpiryDate={approvalRequest.permitExpiryDate} />
        </Grid>
      </Grid>
      <ARDivider />
      <Grid container>
        <Grid item>
          <ARShowPeopleComponent
            requestorName={approvalRequest?.requestorName ?? ""}
            ownerName={approvalRequest?.ownerName ?? ""}
            coordinatorName={approvalRequest?.coordinatorName ?? ""}
            endorserName={approvalRequest?.endorserName ?? ""}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
