import { apiClient } from "@/api/client";
import { AddCommentaryRequest, Commentary } from "@/interfaces";
import { GetCommentaryRequest, UpdateCommentaryRequest } from "@/interfaces/api/requests/Commentary";

const basePath = "/commentary";

export const addNewCommentary = (request: AddCommentaryRequest) =>
  apiClient.post<Commentary>(basePath, request).then((response) => response.data);

export const updateCommentary = (request: UpdateCommentaryRequest) =>
  apiClient.put<Commentary>(basePath, request).then((response) => response.data);

export const getCommentary = (request: GetCommentaryRequest) =>
  apiClient
    .get<Commentary[]>(basePath, {
      params: { ...request }
    })
    .then((response) => response.data);

export const deleteCommentary = (commentaryId: string) => apiClient.delete<Commentary[]>(`${basePath}/${commentaryId}`);
