import { JSX } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";

import { IOption } from "@/interfaces";

// Adapt from https://codesandbox.io/s/clz4ni?file=/demo.tsx:363-414
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

type DashboardSelectWithGridProps = SelectProps<string> & {
  "data-testid"?: string;
  options: IOption[];
  defaultOption?: IOption;
  addDefaultOption?: boolean;
};

export function DashboardSelectWithGrid({
  id,
  label,
  options,
  onChange,
  value = "all",
  "data-testid": dataTestId,
  defaultOption = { id: "all", value: "All" },
  addDefaultOption = true
}: DashboardSelectWithGridProps): JSX.Element {
  const generateOptions = () => {
    const menuOptions = [
      ...options.map((option: IOption, index: number) => (
        <MenuItem key={index} value={option.id} selected={index === 0}>
          {option.value}
        </MenuItem>
      ))
    ];

    if (addDefaultOption) {
      return [
        <MenuItem value={defaultOption.id} selected={value === "all"}>
          {defaultOption.value}
        </MenuItem>,
        ...menuOptions
      ];
    }

    return menuOptions;
  };

  return (
    <Grid item xs={6} sm={6} md={3} lg={2}>
      <FormControl size="small" fullWidth>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          labelId={id}
          id={id}
          fullWidth
          label={label}
          MenuProps={MenuProps}
          value={value}
          onChange={onChange}
          data-testid={dataTestId}
        >
          {generateOptions()}
        </Select>
      </FormControl>
    </Grid>
  );
}
