import { colours } from "@/theme/colour";
import { Card, CardActionArea, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { CHANGE_DETAILS_CLASS_NAME } from "@/constants";
import { TrackChangeConfigurations, TrackChangeProps } from "./ARDetailsChanges";

export function TrackChangeCard(
  trackChange: TrackChangeProps,
  trackChangeIndex: number,
  showTrackChangeValue: boolean,
  correspondArDetailHighlightKey: string,
  updateCorrespondArDetailFieldKey: Dispatch<SetStateAction<string>>,
  questionHighlightId: string,
  updateQuestionHighlightId: Dispatch<SetStateAction<string>>
) {
  return (
    <Card variant="outlined" sx={{ marginTop: "1rem" }} data-testid={"diff-card-" + trackChangeIndex}>
      <CardActionArea
        className={
          correspondArDetailHighlightKey === TrackChangeConfigurations[trackChange.fieldName].key &&
          trackChange.fieldReferenceId === questionHighlightId
            ? CHANGE_DETAILS_CLASS_NAME
            : ""
        }
        onClick={() => {
          updateCorrespondArDetailFieldKey(TrackChangeConfigurations[trackChange.fieldName].key);
          updateQuestionHighlightId(trackChange.fieldReferenceId ?? "");
        }}
      >
        <CardContent sx={{ color: colours.trackChange.mainColour }}>
          <Grid
            container
            rowSpacing={"0.5rem"}
            sx={{
              fontWeight: "400",
              fontFamily: "Roboto",
              fontSize: "0.87rem",
              lineHeight: "1.25rem",
              letterSpacing: "0.011rem"
            }}
          >
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "1.25rem",
                  lineHeight: "2rem",
                  letterSpacing: "0.009rem"
                }}
              >
                {trackChange.labelName}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {showTrackChangeValue && (
                <Grid container spacing={2}>
                  <Tooltip title={trackChange.currentValue ?? ""}>
                    <Grid item md={6}>
                      <Typography color={colours.trackChange.subtextColour}>New Value</Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "1rem",
                          lineHeight: "1.5rem",
                          letterSpacing: "0.009rem"
                        }}
                      >
                        {trackChange.currentValue}
                      </Typography>
                    </Grid>
                  </Tooltip>
                  <Tooltip title={trackChange.originalValue ?? ""}>
                    <Grid item md={6}>
                      <Typography color={colours.trackChange.subtextColour}>Original Value</Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "1rem",
                          lineHeight: "1.5rem",
                          letterSpacing: "0.009rem"
                        }}
                      >
                        {trackChange.originalValue}
                      </Typography>
                    </Grid>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  lineHeight: "1.125rem",
                  letterSpacing: "0.009rem"
                }}
                color={colours.trackChange.subtextColour}
              >
                Modified • {trackChange.updated} • {trackChange.updatedBy}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
