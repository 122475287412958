import {
  Typography,
  TextField,
  IconButton,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Add, AssignmentInd, Search } from "@mui/icons-material";
import { Reporting } from "@/assets/icons";
import { colours } from "@/theme/colour";

import "./HomePage.scss";
import { BUTTON_LABELS, PATH, SEARCH_TERM_MAX_LENGTH } from "@/constants";
import { useNavigate } from "react-router";
import { Dialog } from "@/components/dialogs";
import { useState } from "react";
import { useAuthorization } from "@/context";
import { toKebabCase } from "@/utils";
import { useCreateApprovalRequest } from "@/hooks";
import { useApprovalRequestTypes } from "@/hooks/api/ApprovalRequestTypesHooks";
import { createSearchParams } from "react-router-dom";

export const HomePage = () => {
  const navigate = useNavigate();
  const user = useAuthorization();
  const [newRequestDialogOpen, setNewRequestDialogOpen] = useState(false);
  const [selectedARTypeId, setSelectedARTypeID] = useState<string | undefined>();
  const { data: approvalRequestTypes } = useApprovalRequestTypes({ isEnabled: true });
  const { mutate: createNewRequest } = useCreateApprovalRequest();
  const showNewRequest = user.isRequestor || user.isCoordinator;
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleOnSearchFieldKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearchPageNavigation();
    }
  };

  const handleSearchPageNavigation = () => {
    navigate({
      pathname: PATH.SEARCH,
      search: `?${createSearchParams("q=" + searchTerm.trim())}`
    });
  };

  return (
    <Box id="homepage-wrapper">
      <Stack mx="auto" mt="12rem">
        <Stack width="100%" alignItems="center">
          <Typography sx={{ fontWeight: "400", fontSize: "4.5rem" }} color="white" letterSpacing="1rem" mr="-1rem">
            LAMS
          </Typography>
          <Typography sx={{ fontWeight: "400", fontSize: "1rem" }} color="white">
            Land Access Management System
          </Typography>
        </Stack>
        <TextField
          fullWidth
          sx={{
            marginTop: "4rem",
            mx: "auto",
            width: "20rem"
          }}
          color="info"
          placeholder="Search for a request..."
          InputProps={{
            startAdornment: (
              <IconButton sx={{ mr: 1 }} onClick={handleSearchPageNavigation}>
                <Search />
              </IconButton>
            ),
            sx: {
              borderRadius: "1.25rem",
              height: "2.5rem",
              width: "100%",
              background: "white"
            }
          }}
          inputProps={{
            "data-testid": "homepage-search-field",
            onKeyDown: handleOnSearchFieldKeyDown,
            maxLength: SEARCH_TERM_MAX_LENGTH
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value);
          }}
        />
        <Stack direction="row" justifyContent="space-around" mt="2rem" mx="auto" width="25rem">
          {showNewRequest ? (
            <Stack className="homepage-button">
              <IconButton
                data-testid="homepage-button-new-request"
                sx={{
                  width: "3rem",
                  height: "3rem",
                  background: colours.homePage.icon,
                  "&:hover": {
                    background: colours.homePage.iconHover
                  }
                }}
                size="large"
                color="info"
                onClick={() => setNewRequestDialogOpen(true)}
              >
                <Add />
              </IconButton>
              <Typography color="white">New Request</Typography>
            </Stack>
          ) : null}

          <Stack className="homepage-button">
            <IconButton
              data-testid="homepage-button-my-requests"
              sx={{
                width: "3rem",
                height: "3rem",
                background: colours.homePage.icon,
                "&:hover": {
                  background: colours.homePage.iconHover
                }
              }}
              size="large"
              color="info"
              onClick={() => {
                navigate(PATH.MY_REQUESTS);
              }}
            >
              <AssignmentInd />
            </IconButton>
            <Typography color="white">My Requests</Typography>
          </Stack>
          <Stack className="homepage-button">
            <IconButton
              data-testid="homepage-button-reporting"
              sx={{
                width: "3rem",
                height: "3rem",
                background: colours.homePage.icon,
                "&:hover": {
                  background: colours.homePage.iconHover
                },
                "&.Mui-disabled": {
                  background: colours.homePage.iconDisabled
                }
              }}
              size="large"
              color="info"
              disabled
            >
              <Reporting />
            </IconButton>
            <Typography color="#A5B5C5">Reporting</Typography>
          </Stack>
          <Dialog
            open={newRequestDialogOpen}
            onClose={() => {
              setNewRequestDialogOpen(false);
              setSelectedARTypeID(undefined);
            }}
            title={"New Request"}
            actions={[
              {
                label: BUTTON_LABELS.CANCEL,
                onClick: () => {
                  setNewRequestDialogOpen(false);
                  setSelectedARTypeID(undefined);
                }
              },
              {
                label: BUTTON_LABELS.CREATE,
                disabled: selectedARTypeId === undefined,
                onClick: () => createNewRequest({ approvalRequestTypeId: selectedARTypeId })
              }
            ]}
          >
            <Stack paddingTop={5} width={444}>
              <FormControl>
                <FormLabel sx={{ "&.Mui-focused": { color: "#000000" } }}>Request Type</FormLabel>
                <RadioGroup
                  aria-labelledby="request-type-group-label"
                  onChange={(event) => {
                    setSelectedARTypeID(event.target.value);
                  }}
                  name="radio-buttons-group"
                  sx={{ paddingLeft: "10px" }}
                >
                  {approvalRequestTypes.map((approvalRequestType) => (
                    <FormControlLabel
                      value={approvalRequestType.id}
                      control={<Radio />}
                      label={approvalRequestType.name}
                      data-testid={`radio-${toKebabCase(approvalRequestType.name)}`}
                      key={approvalRequestType.id}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Stack>
          </Dialog>
        </Stack>
      </Stack>
    </Box>
  );
};
