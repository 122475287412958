import dayjs, { Dayjs } from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import UpdateLocale from "dayjs/plugin/updateLocale";
import IsToday from "dayjs/plugin/isToday";
import IsYesterday from "dayjs/plugin/isYesterday";
import IsTomorrow from "dayjs/plugin/isTomorrow";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import UTC from "dayjs/plugin/utc";
import "dayjs/locale/en-au";

// strict thresholds
const config = {
  thresholds: [
    { l: "s", r: 50000, d: "milliseconds" },
    { l: "m", r: 120000, d: "milliseconds" },
    { l: "mm", r: 3598000, d: "milliseconds" }, // 59 minutes + 59 seconds - To cater for (X minutes Ago)
    { l: "h", r: 86400000, d: "milliseconds" }, // a day in second - To cater for (X hours Ago) or (yesterday at 5:05 pm)
    { l: "hh", r: 172800000, d: "milliseconds" }, // 2 day in second - To cater for (yesterday at 5:05 pm) or (X days ago)
    { l: "d", r: 6, d: "day" }, // within 7 days - To cater for  (X days ago)
    { l: "w", r: 7, d: "day" }, // on the 7 days To cater for (1 week ago)
    { l: "ww", d: "milliseconds" } // any day beyond 7 days (To cater for 8 August 2018)
  ]
};

dayjs.extend(RelativeTime, config);
dayjs.extend(LocalizedFormat);
dayjs.extend(IsSameOrAfter);
dayjs.extend(UpdateLocale);
dayjs.extend(IsYesterday);
dayjs.extend(IsTomorrow);
dayjs.extend(IsToday);
dayjs.extend(UTC);
dayjs.locale("en-au");

const PAST_SUFFIX = " ago";
const FUTURE_PREFIX = "in ";
const MINUTE_IN_MILLISECONDS = 60000;
const HOUR_IN_MILLISECONDS = 3600000;
const DAY_IN_MILLISECONDS = 86400000;

dayjs.updateLocale("en-au", {
  relativeTime: {
    future: "%s", // dayjs default future prefix or suffix
    past: "%s", // dayjs default past prefix or suffix (default suffix will be ignored if we override the value of property to %s)
    s: "just now",
    m: (_number: number, withoutSuffix: boolean, _key: string, isFuture: boolean) => {
      const displayDate = "1 minute";
      return formatDateWithPrefixOrSuffix(displayDate, withoutSuffix, isFuture);
    },
    mm: (number: number, withoutSuffix: boolean, _key: string, isFuture: boolean) => {
      const originalDate = getOriginalDate(number, "milliseconds", isFuture); // retrieve back the original input date and time
      if (originalDate.isToday()) {
        const displayDate = `${Math.round(number / MINUTE_IN_MILLISECONDS)} minutes`;
        return formatDateWithPrefixOrSuffix(displayDate, withoutSuffix, isFuture);
      } else {
        return formatBeforeNextDayString(originalDate, isFuture);
      }
    },
    h: (number: number, withoutSuffix: boolean, _key: string, isFuture: boolean) => {
      const originalDate = getOriginalDate(number, "milliseconds", isFuture); // retrieve back the original input date and time
      if (originalDate.isToday()) {
        const hours = Math.round(number / HOUR_IN_MILLISECONDS);
        const displayDate = `${hours} hour${hours > 1 ? "s" : ""}`;
        return formatDateWithPrefixOrSuffix(displayDate, withoutSuffix, isFuture);
      } else {
        return formatBeforeNextDayString(originalDate, isFuture);
      }
    },
    hh: (number: number, withoutSuffix: boolean, _key: string, isFuture: boolean) => {
      const originalDate = getOriginalDate(number, "milliseconds", isFuture); // retrieve back the original input date and time
      if (originalDate.isYesterday() || originalDate.isTomorrow()) {
        return formatBeforeNextDayString(originalDate, isFuture);
      } else {
        const displayDate = `${Math.round(number / DAY_IN_MILLISECONDS)} days`;
        return formatDateWithPrefixOrSuffix(displayDate, withoutSuffix, isFuture);
      }
    },
    d: (number: number, withoutSuffix: boolean, _key: string, isFuture: boolean) => {
      const displayDate = `${number} days`;
      return formatDateWithPrefixOrSuffix(displayDate, withoutSuffix, isFuture);
    },
    w: (_number: number, withoutSuffix: boolean, _key: string, isFuture: boolean) => {
      const displayDate = "1 week";
      return formatDateWithPrefixOrSuffix(displayDate, withoutSuffix, isFuture);
    },
    ww: (number: number, _withoutSuffix: boolean, _key: string, isFuture: boolean) => {
      const originalDate = getOriginalDate(number, "milliseconds", isFuture); // retrieve back the original input date and time
      return originalDate.format("ll");
    }
  }
});

const getOriginalDate = (number: number, key: dayjs.ManipulateType, isFuture: boolean) => {
  return isFuture ? dayjs().add(number, key) : dayjs().subtract(number, key);
};

const formatBeforeNextDayString = (originalDate: Dayjs, isFuture: boolean) => {
  const displayDayText = isFuture ? "tomorrow" : "yesterday";
  return `${displayDayText} at ${originalDate.format("h:mm a")}`;
};

const formatDateWithPrefixOrSuffix = (displayDate: string, withoutSuffix: boolean, isFuture: boolean) => {
  if (withoutSuffix) {
    return displayDate;
  } else {
    return isFuture ? FUTURE_PREFIX.concat(displayDate) : displayDate.concat(PAST_SUFFIX);
  }
};

export type DateValue = string | Date | Dayjs | null | undefined;

export const dateFormatter = (date: DateValue, format: string) => {
  if (!date) {
    return;
  }

  const convertedDate = dayjs(date);

  if (!convertedDate.isValid()) {
    return;
  }

  return format === "humanize" ? convertedDate.fromNow() : convertedDate.format(format);
};

/**
 * Function that takes a date value and formats it to the the format of "DD/MM/YYYY".
 * @param date date value to be formatted.
 * @returns String version of the date.
 */
export const formatDate = (date: DateValue) => dateFormatter(date, "DD/MM/YYYY");

/**
 * Function that takes a date value and formats it to the the format of "MMMM YYYY".
 * @param date date value to be formatted.
 * @returns String version of the date.
 */
export const formatMonthYear = (date: DateValue) => dateFormatter(date, "MMMM YYYY");

/**
 * Function that takes a date value and returns a humanized description of it relative to the current time.
 * @param date date value to be formatted.
 * @returns String version of the date.
 */
export const formatHumanizeDate = (date: DateValue) => dateFormatter(date, "humanize");

/**
 * Function that takes a date value and formats it to the the format of "MMM D, YYYY".
 * @param date date value to be formatted.
 * @returns String version of the date.
 */
export const formatLocalDate = (date: DateValue) => dateFormatter(date, "ll");

export const greaterThanOrEqualTo = (date: Date | Dayjs, minDate: Date | Dayjs) => {
  return dayjs(date).startOf("day").isSameOrAfter(dayjs(minDate).startOf("day"));
};

export const convertFromUTC = (dateToConvert: Date) => {
  return dayjs.utc(dateToConvert);
};
