import { apiClient } from "@/api/client";
import { AddArcGisLayerRequest, ArcGISLayerList } from "@/interfaces";

const basePath = "/arcGisLayer";

export const getArcGisLayers = (pageNumber: number, pageSize: number) =>
  apiClient
    .get<ArcGISLayerList>(`${basePath}`, {
      params: {
        pageNumber,
        pageSize
      }
    })
    .then((response) => response.data);

export const createArcGisLayer = (arcgisLayer: AddArcGisLayerRequest) =>
  apiClient.post(basePath, arcgisLayer).then((response) => response.data);
