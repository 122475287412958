import { Grid, Paper, SxProps } from "@mui/material";
import { InterceptResultTableComponent } from "./";

const paperContainerStyle: SxProps = {
  marginTop: { sm: "0.5em", md: "1.5em", lg: "2em" },
  marginLeft: { sm: "0.5em", md: "1.5em", lg: "2em" },
  marginRight: { sm: "0.5em", md: "1.5em", lg: "2em" },
  padding: { sm: "0", md: "1em", lg: "1.5em" }
};

export const HistoryViewComponent = () => {
  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Paper elevation={3} sx={paperContainerStyle}>
            <InterceptResultTableComponent isHistory={true} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
