import * as React from "react";
import { Box, ClickAwayListener, IconButton, Popper } from "@mui/material";
import { ProfileCard } from "./ProfileCard";
import { UserAvatar } from "./UserAvatar";

export function ProfilePopper() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <IconButton
          data-testid="ProfilePopoverOpenButtonId"
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="default"
          onClick={handleClick}
        >
          <UserAvatar avatarWidth={"2.5em"} avatarHeight={"2.5em"} fontSize={"1rem"} />
        </IconButton>
        <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 9999 }}>
          <ProfileCard />
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
