export const AR_BASIC_DETAILS_LOCATORS = {
  TITLE_FIELD: "title-input",
  HUB_FIELD: "hub-id-input",
  SITE_FIELD: "site-input",
  BUSINESS_UNIT_FIELD: "business-unit-input",
  PROJECT_FIELD: "project-input",
  COST_CODE_FIELD: "cost-code-input",
  REQUIRED_BY_DATE_FIELD: "required-by-date-input",
  ESTIMATED_DRAFT_PERMIT_DATE_FIELD: "estimated-draft-permit-date-input",
  DESCRIPTION_FIELD: "description-input",
  QUESTION_ANSWER_FIELD: "question-answer-{0}-input",
  WORK_CATEGORY_FIELD: "work-category-{0}-input"
};

export const AR_SHOW_PEOPLE_LOCATORS = {
  REQUESTOR_NAME_LABEL: "requestor-name-label",
  REQUESTOR_NAME_TEXT: "requestor-name-text",
  OWNER_NAME_LABEL: "owner-name-label",
  OWNER_NAME_VALUE: "owner-name-text",
  COORDINATOR_NAME_LABEL: "coordinator-name-label",
  COORDINATOR_NAME_TEXT: "coordinator-name-text",
  ENDORSER_NAME_LABEL: "endorser-name-label",
  ENDORSER_NAME_TEXT: "endorser-name-text"
};

export const AR_SHOW_DATE_LOCATORS = {
  PERMIT_EXPIRY_DATE_LABEL: "permit-expiry-date-label",
  PERMIT_EXPIRY_DATE_TEXT: "permit-expiry-date-text"
};

export const AR_STATUS_TIMELINE_LOCATORS = {
  STATUS_DATE_ITEM: "status-date-item",
  STATUS_DATE_LABEL: "status-date-label",
  STATUS_DATE_TEXT: "status-date-text",
  STATUS_DOT: "status-dot"
};
