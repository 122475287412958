import { useEffect, useState, useCallback, isValidElement } from "react";
import { IconButton, Theme } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  GridSortModel,
  GridValueFormatterParams,
  GridEventListener,
  GridRowParams
} from "@mui/x-data-grid-pro";
import { InterceptResultTableToolbar } from "./InterceptResultTableToolbar";
import {
  useGetInterceptListResultAtom,
  useInterceptResult,
  useSelectedFieldSite,
  useSelectedFieldSiteType,
  useSelectedHubId,
  useSelectedSiteId
} from "@/hooks";
import { DetailPanelContent } from "./InterceptResultTableMasterDetail";

const dataColumns: GridColDef[] = [
  {
    field: "site",
    headerName: "Site",
    flex: 1
  },
  {
    field: "arNumber",
    headerName: "AR Number",
    flex: 1
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1
  },
  {
    field: "fieldSiteNo",
    headerName: "Field Site",
    flex: 1
  },
  {
    field: "fieldSiteType",
    headerName: "Field Site Type",
    flex: 1
  },
  {
    field: "fieldSiteCategory",
    headerName: "Field Site Category",
    flex: 1
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1
  },
  {
    field: "permitIssuedDate",
    headerName: "Permit Issued",
    valueFormatter: (params) => formatDate(params),
    flex: 1
  },
  {
    field: "interceptDetectedDate",
    headerName: "Intercept Detected",
    valueFormatter: (params) => formatDate(params),
    flex: 1
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />
  }
];

const dataColumnsHistory: GridColDef[] = [
  {
    field: "site",
    headerName: "Site",
    flex: 1
  },
  {
    field: "arNumber",
    headerName: "AR Number",
    flex: 1
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1
  },
  {
    field: "fieldSiteNo",
    headerName: "Field Site",
    flex: 1
  },
  {
    field: "fieldSiteType",
    headerName: "Field Site Type",
    flex: 1
  },
  {
    field: "fieldSiteCategory",
    headerName: "Field Site Category",
    flex: 1
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1
  },
  {
    field: "permitIssuedDate",
    headerName: "Permit Issued",
    valueFormatter: (params) => formatDate(params),
    flex: 1
  },
  {
    field: "interceptDetectedDate",
    headerName: "Intercept Detected",
    valueFormatter: (params) => formatDate(params),
    flex: 1
  },
  {
    field: "decision",
    headerName: "Decision",
    flex: 1
  },
  {
    field: "determinedDate",
    headerName: "Determined",
    valueFormatter: (params) => formatDate(params),
    flex: 1
  },
  {
    field: "decisionByName",
    headerName: "Decision By",
    flex: 1
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />
  }
];

const formatDate = (params: GridValueFormatterParams) => new Date(params?.value).toLocaleDateString();

const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, "id" | "value">) => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton size="small" tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? "Close" : "Open"}>
      <ExpandMore
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme: Theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest
            })
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
};

interface InterceptResultTableComponentProps {
  isHistory: boolean;
}

export const InterceptResultTableComponent = ({ isHistory }: InterceptResultTableComponentProps) => {
  const [interceptResultList, getInterceptResultList] = useGetInterceptListResultAtom();
  const [selectedHubId] = useSelectedHubId();
  const [selectedSiteId] = useSelectedSiteId();
  const [selectedFieldSite] = useSelectedFieldSite();
  const [selectedFieldSiteType] = useSelectedFieldSiteType();
  const [isLoading, setIsLoading] = useState(false);
  const [, setInterceptResult] = useInterceptResult();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowCount, setRowCount] = useState(10);
  const [columnSort, setColumnSort] = useState<string>();

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps["getDetailPanelContent"]>>(
    ({ row }) => <DetailPanelContent row={row} isHistory={isHistory} />,
    [isHistory]
  );

  const handleRowClick: GridEventListener<"rowClick"> = (params: GridRowParams) => {
    const interceptData = interceptResultList.items.find((item) => item.id === params.row.id);

    if (interceptData) {
      setInterceptResult({
        ARNumber: interceptData.arNumber,
        fieldSiteNumber: interceptData.fieldSiteNo,
        fieldSiteType: interceptData.fieldSiteType,
        fieldSiteCategory: interceptData.fieldSiteCategory,
        arLayerId: interceptData.arLayerId,
        interceptLayerId: interceptData.interceptLayerId
      });
    }
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const sortClause = sortModel.map((sort) => `${sort.field} ${sort.sort}`).join(",");

    setColumnSort(sortClause);
  }, []);

  useEffect(() => {
    if (selectedHubId === "" || isLoading) return;
    setIsLoading(true);
    getInterceptResultList(isHistory, currentPage + 1, rowCount, columnSort);
    setIsLoading(false);
  }, [
    isLoading,
    isHistory,
    currentPage,
    rowCount,
    columnSort,
    getInterceptResultList,
    selectedHubId,
    selectedSiteId,
    selectedFieldSite,
    selectedFieldSiteType
  ]);

  return (
    <DataGridPro
      sx={{ border: 0 }}
      density="compact"
      autoHeight={true}
      slots={{ toolbar: InterceptResultTableToolbar }}
      slotProps={{ toolbar: { isHistory: isHistory } }}
      loading={isLoading}
      checkboxSelection={false}
      disableRowSelectionOnClick={isHistory}
      disableColumnFilter={true}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => "auto"}
      columns={isHistory ? dataColumnsHistory : dataColumns}
      rows={interceptResultList.items}
      paginationModel={{
        pageSize: interceptResultList.pageSize,
        page: interceptResultList.pageNumber - 1
      }}
      pagination={true}
      paginationMode="server"
      onPaginationModelChange={({ page, pageSize }) => {
        setCurrentPage(page);
        setRowCount(pageSize);
      }}
      rowCount={interceptResultList.totalRecords ?? 0}
      pageSizeOptions={[10, 25, 50, 100]}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      onRowClick={handleRowClick}
    />
  );
};
