import { useCallback, useMemo } from "react";
import { useNotificationsContext } from "@/context";

export const useUnreadIndicator = <T extends { id: string }>(approvalRequestList: T[]) => {
  const { unreadNotifications } = useNotificationsContext();

  const arIds = useMemo(() => approvalRequestList.map((ar) => ar.id), [approvalRequestList]);

  const unreadARIds = useMemo(
    () =>
      unreadNotifications.reduce((unread: string[], notification) => {
        if (arIds.includes(notification.approvalRequestId)) {
          unread.push(notification.approvalRequestId);
        }
        return unread;
      }, []),
    [unreadNotifications, arIds]
  );

  const isUnread = useCallback((approvalRequestId: string) => unreadARIds.includes(approvalRequestId), [unreadARIds]);

  return { isUnread };
};
