import { Button } from "@mui/material";

import { ApprovalRequestReturnStatus, Notification, NotificationEvent } from "@/interfaces";
import { useNotificationDecideReturnRequest } from "@/hooks";

interface NotificationActionButtonProps {
  notificationId: Notification["id"];
  approvalRequestId: Notification["approvalRequestId"];
  notificationEvent: Notification["event"];
}

export function NotificationActionButtons({
  notificationId,
  approvalRequestId,
  notificationEvent
}: NotificationActionButtonProps) {
  const { mutate: decideReturnRequest } = useNotificationDecideReturnRequest(approvalRequestId, notificationId);

  switch (notificationEvent) {
    case NotificationEvent.ARReturnRequested:
      return (
        <>
          <Button
            variant="outlined"
            fullWidth={true}
            color="inherit"
            onClick={(event) => {
              event.stopPropagation();
              decideReturnRequest(ApprovalRequestReturnStatus.Declined);
            }}
          >
            Decline
          </Button>
          <Button
            variant="outlined"
            fullWidth={true}
            color="inherit"
            onClick={(event) => {
              event.stopPropagation();
              decideReturnRequest(ApprovalRequestReturnStatus.Accepted);
            }}
          >
            Accept
          </Button>
        </>
      );
    default:
      return null;
  }
}
