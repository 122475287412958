import { ApprovalRequestReturnStatus } from "@/interfaces";

export enum CommentaryType {
  Comment = 0,
  ReturnRequest = 1
}

export interface Commentary {
  id: string;
  approvalRequestId: string;
  replyComments: Commentary[];
  comment: string;
  commentatorId: string;
  commentatorName: string;
  commentDate: Date;
  parentCommentaryId?: string;
  type: CommentaryType;
  approvalRequestReturnStatus: ApprovalRequestReturnStatus;
  isUnread: boolean;
  notificationIds: string[] | null;
}
