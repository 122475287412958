import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { DataGridPro, GridColDef, GridEventListener, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";

import { APPROVAL_REQUEST_PATH, DEFAULT_AR_FILTER_QUERY } from "@/constants";
import { ApprovalRequestStatus } from "@/interfaces";
import { formatDate, formatHumanizeDate } from "@/utils";
import { useApprovalRequestList, usePagination, useUnreadIndicator } from "@/hooks";
import { ARFilterToolbar, ARFilterQuery } from "@/components/toolbars";

export const DraftAR = () => {
  const navigate = useNavigate();

  const { currentPage, pageSize, paginationModelChangeHandler } = usePagination();

  const [sortField, setSortField] = useState<string>();
  const [filterQuery, setFilterQuery] = useState<ARFilterQuery>(DEFAULT_AR_FILTER_QUERY);

  const approvalRequestStatusFilter = [ApprovalRequestStatus.Draft];

  const { data: approvalRequestList, isLoading } = useApprovalRequestList({
    approvalRequestStatus: approvalRequestStatusFilter,
    pageNumber: currentPage + 1,
    pageSize,
    sortField,
    hubId: filterQuery.selectedHub,
    projectId: filterQuery.selectedProject,
    siteId: filterQuery.selectedSite
  });

  const { isUnread } = useUnreadIndicator(approvalRequestList.items);

  const dataColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      flex: 0.01,
      sortable: false,
      renderCell: ({ value }) => (
        <Box
          data-testid={`unread-indicator-${value}`}
          width="0.5rem"
          height="0.5rem"
          display="block"
          borderRadius="100%"
          sx={{ background: "red" }}
          visibility={isUnread(value) ? "visible" : "hidden"}
        />
      )
    },
    {
      field: "referenceNo",
      headerName: "AR Number",
      flex: 0.5
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2
    },
    {
      field: "site",
      headerName: "Site",
      flex: 1
    },
    {
      field: "hub",
      headerName: "Hub",
      flex: 1
    },
    {
      field: "created",
      headerName: "Created",
      valueFormatter: (params) => formatDate(params.value),
      flex: 1
    },
    {
      field: "lastModified",
      headerName: "Modified",
      valueFormatter: (params) => formatHumanizeDate(params.value),
      flex: 1
    },
    {
      field: "requiredByDate",
      headerName: "Required By",
      valueFormatter: (params) => formatHumanizeDate(params.value),
      flex: 1
    }
  ];

  const handleRowClick: GridEventListener<"rowClick"> = async ({ row: { id } }: GridRowParams) => {
    navigate(generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: id }));
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortClause = sortModel
      .map(({ field, sort }) => {
        if (["hub", "site"].includes(field)) {
          return `${field}.name ${sort}`;
        }
        return `${field} ${sort}`;
      })
      .join(",");

    setSortField(sortClause);
  };

  return (
    <DataGridPro
      autoHeight={true}
      columns={dataColumns}
      data-testid="draft-ar-table"
      density="compact"
      loading={isLoading}
      onPaginationModelChange={paginationModelChangeHandler}
      onRowClick={handleRowClick}
      onSortModelChange={handleSortModelChange}
      pageSizeOptions={[10, 25, 50, 100]}
      disableColumnMenu={true}
      pagination={true}
      paginationMode="server"
      paginationModel={{
        pageSize: approvalRequestList.pageSize,
        page: approvalRequestList.pageNumber - 1
      }}
      rowCount={approvalRequestList.totalRecords ?? 0}
      rows={approvalRequestList.items}
      slots={{ toolbar: ARFilterToolbar }}
      slotProps={{
        toolbar: {
          onFilterChange: (filterQuery: ARFilterQuery) => {
            setFilterQuery(filterQuery);
          },
          approvalRequestStatusFilter
        }
      }}
      sortingMode="server"
      sx={{ border: 0 }}
    />
  );
};
