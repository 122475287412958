import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { DisciplineTrackingComment, DisciplineTrackingCommentNote } from "@/interfaces";

interface EditTrackingCommentNoteProp {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  trackingComment: DisciplineTrackingComment["comment"];
  trackingCommentNote: DisciplineTrackingCommentNote;
  updateTrackingCommentNote: (updatedNote: DisciplineTrackingCommentNote) => void;
}

export function EditTrackingCommentNote({
  open,
  setOpen,
  trackingComment,
  trackingCommentNote,
  updateTrackingCommentNote
}: EditTrackingCommentNoteProp) {
  const [modifiedNote, setModifiedNote] = useState<string>(trackingCommentNote.note);

  useEffect(() => {
    setModifiedNote(trackingCommentNote.note);
  }, [trackingCommentNote.note]);

  const dismissOnClickHandler = () => {
    setOpen(false);
  };

  const confirmOnClickHandler = () => {
    updateTrackingCommentNote({
      ...trackingCommentNote,
      note: modifiedNote
    });
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={dismissOnClickHandler} fullWidth={true} maxWidth="md" sx={{ padding: "1.5rem" }}>
      <DialogTitle>Edit Note</DialogTitle>
      <DialogContentText sx={{ pl: "1.5rem" }}>{trackingComment}</DialogContentText>
      <DialogContent>
        <TextField
          fullWidth
          id="outlined-multiline-tracking-comment-note"
          label="Note"
          multiline
          rows={5}
          value={modifiedNote}
          inputProps={{ maxLength: 500 }}
          onChange={(event) => {
            setModifiedNote(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dismissOnClickHandler}>Cancel</Button>
        <Button
          disabled={modifiedNote.trim() === "" || modifiedNote === trackingCommentNote.note}
          onClick={confirmOnClickHandler}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
