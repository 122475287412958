import { AR_BASIC_DETAILS_LOCATORS, AR_STATUSES, HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";
import { useARContext } from "@/context";
import { usePastApprovalRequestById } from "@/hooks";
import { ApprovalRequestVersioned } from "@/interfaces/api/responses/ApprovalRequestVersioned";
import { colours } from "@/theme/colour";
import { dateFormatter, formatDate } from "@/utils";
import { Box, FormControl, InputLabel, MenuItem, MenuList, Select, SelectChangeEvent } from "@mui/material";
import { SetStateAction } from "jotai";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { TrackChangeCard } from "./TrackChangeCard";

interface ARDetailsChangesProps {
  correspondArDetailHighlightKey: string;
  updateCorrespondArDetailFieldKey: Dispatch<SetStateAction<string>>;
  updateDescriptionOriginalValue: Dispatch<SetStateAction<string>>;
  updateDescriptionCurrentValue: Dispatch<SetStateAction<string>>;
  questionHighlightId: string;
  updateQuestionHighlightId: Dispatch<SetStateAction<string>>;
}

export interface TrackChangeProps {
  fieldName: keyof ApprovalRequestVersioned;
  labelName?: string;
  fieldReferenceId?: string; // Cater for QuestionAnswer | WorkCategory
  originalValue: string;
  currentValue: string;
  updated: string;
  updatedBy: string;
}

export const TrackChangeConfigurations: Record<
  TrackChangeProps["fieldName"],
  { key: string; label: string; showComparison: boolean; render: boolean }
> = {
  title: { key: AR_BASIC_DETAILS_LOCATORS.TITLE_FIELD, label: "Title", showComparison: true, render: true },
  hub: { key: AR_BASIC_DETAILS_LOCATORS.HUB_FIELD, label: "Hub", showComparison: true, render: true },
  site: { key: AR_BASIC_DETAILS_LOCATORS.SITE_FIELD, label: "Site", showComparison: true, render: true },
  businessUnit: {
    key: AR_BASIC_DETAILS_LOCATORS.BUSINESS_UNIT_FIELD,
    label: "Business Unit",
    showComparison: true,
    render: true
  },
  project: { key: AR_BASIC_DETAILS_LOCATORS.PROJECT_FIELD, label: "Project", showComparison: true, render: true },
  description: {
    key: AR_BASIC_DETAILS_LOCATORS.DESCRIPTION_FIELD,
    label: "Description",
    showComparison: false,
    render: true
  },
  costCode: { key: AR_BASIC_DETAILS_LOCATORS.COST_CODE_FIELD, label: "Cost Code", showComparison: true, render: true },
  requiredByDate: {
    key: AR_BASIC_DETAILS_LOCATORS.REQUIRED_BY_DATE_FIELD,
    label: "Required By",
    showComparison: true,
    render: true
  },
  answerItems: { key: "", label: "", showComparison: true, render: true },
  // TODO: Figure out these
  lastModified: {
    key: "",
    label: "",
    showComparison: true,
    render: false
  },
  lastModifiedByName: {
    key: "",
    label: "",
    showComparison: true,
    render: false
  },
  id: { key: "", label: "", showComparison: false, render: false },
  workCategories: { key: "", label: "", showComparison: true, render: false }
};

export function ARDetailsChanges({
  correspondArDetailHighlightKey,
  updateCorrespondArDetailFieldKey,
  updateDescriptionOriginalValue,
  updateDescriptionCurrentValue,
  questionHighlightId,
  updateQuestionHighlightId
}: ARDetailsChangesProps) {
  const [selectedVersion, setSelectedVersion] = useState<number | undefined>(undefined);
  const dateTimeFormat: string = "h:mm A, D MMMM YYYY";
  const versionSelectLabel: string = "Comparing changes from";

  const { approvalRequest } = useARContext();

  const { data: currentAR } = usePastApprovalRequestById(approvalRequest.id, approvalRequest.currentVersion);
  const { data: pastAR } = usePastApprovalRequestById(approvalRequest.id, selectedVersion?.toString());

  const onSelectVersion = (event: SelectChangeEvent) => {
    setSelectedVersion(approvalRequest.pastApprovalRequests[Number(event.target.value)].approvalRequestVersion);
    updateCorrespondArDetailFieldKey("");
  };

  const trackChanges = useMemo(() => {
    const diffs: TrackChangeProps[] = [];
    updateQuestionHighlightId("");

    if (pastAR === undefined) return diffs;

    for (const key in currentAR) {
      if (currentAR[key as keyof ApprovalRequestVersioned] !== pastAR[key as keyof ApprovalRequestVersioned]) {
        let pastARValue = pastAR[key as keyof ApprovalRequestVersioned];
        let currentARValue = currentAR[key as keyof ApprovalRequestVersioned];

        if (typeof pastARValue === "string" && typeof currentARValue === "string") {
          if (key === "lastModified" || key === "requiredByDate") {
            pastARValue = formatDate(pastARValue) ?? "";
            currentARValue = formatDate(currentARValue) ?? "";

            if (pastARValue === currentARValue) continue;
          }

          if (key === "description") {
            updateDescriptionOriginalValue(pastARValue);
            updateDescriptionCurrentValue(currentARValue);
          }

          diffs.push({
            fieldName: key as keyof ApprovalRequestVersioned,
            labelName: TrackChangeConfigurations[key as keyof ApprovalRequestVersioned].label,
            fieldReferenceId: undefined,
            originalValue: pastARValue,
            currentValue: currentARValue,
            updatedBy: pastAR.lastModifiedByName,
            updated: dateFormatter(pastAR.lastModified, dateTimeFormat) ?? ""
          });
        }

        if (key === "answerItems") {
          currentAR.answerItems.forEach((currARAnswerItem) => {
            const pastAnswerItem = pastAR.answerItems.find((p) => p.questionId === currARAnswerItem.questionId);
            if (!pastAnswerItem) {
              diffs.push({
                fieldName: key as keyof ApprovalRequestVersioned,
                labelName: currARAnswerItem.question,
                fieldReferenceId: currARAnswerItem.questionId,
                originalValue: "N/A",
                currentValue: currARAnswerItem.answer,
                updatedBy: pastAR.lastModifiedByName,
                updated: dateFormatter(pastAR.lastModified, dateTimeFormat) ?? ""
              });
            } else {
              if (pastAnswerItem.answer !== currARAnswerItem.answer) {
                diffs.push({
                  fieldName: key as keyof ApprovalRequestVersioned,
                  labelName: currARAnswerItem.question,
                  fieldReferenceId: currARAnswerItem.questionId,
                  originalValue: pastAnswerItem.answer,
                  currentValue: currARAnswerItem.answer,
                  updatedBy: pastAR.lastModifiedByName,
                  updated: dateFormatter(pastAR.lastModified, dateTimeFormat) ?? ""
                });
              }
            }
          });
        }

        // TEMPORARY skip since it's not part of current US's focus
        if (key === "workCategories") continue;
      }
    }

    return diffs;
  }, [currentAR, pastAR, updateDescriptionOriginalValue, updateDescriptionCurrentValue, updateQuestionHighlightId]);

  useEffect(() => {
    if (correspondArDetailHighlightKey === "") return;

    const highlightedFields =
      document.getElementsByClassName(HIGHLIGHT_FIELD_CLASS_NAMES.BACKGROUND) ||
      document.getElementsByClassName(HIGHLIGHT_FIELD_CLASS_NAMES.BORDER);

    if (highlightedFields.length > 0) {
      highlightedFields[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, [correspondArDetailHighlightKey]);

  return (
    <Box bgcolor={colours.sidePanel} sx={{ height: "100%", p: "1rem" }} overflow="auto">
      <FormControl fullWidth sx={{ marginTop: "1rem" }} data-testid="version-select">
        <InputLabel>{versionSelectLabel}</InputLabel>
        <Select onChange={onSelectVersion} label={versionSelectLabel}>
          {approvalRequest.pastApprovalRequests.map((pastArVersion, index) => (
            <MenuItem value={index}>
              Version {pastArVersion.approvalRequestVersion} - {AR_STATUSES[pastArVersion.status]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MenuList>
        {selectedVersion !== undefined &&
          (trackChanges.length > 0
            ? trackChanges.map(
                (trackChange, index) =>
                  TrackChangeConfigurations[trackChange.fieldName].render &&
                  TrackChangeCard(
                    trackChange,
                    index,
                    TrackChangeConfigurations[trackChange.fieldName].showComparison,
                    correspondArDetailHighlightKey,
                    updateCorrespondArDetailFieldKey,
                    questionHighlightId,
                    updateQuestionHighlightId
                  )
              )
            : "No changes made")}
      </MenuList>
    </Box>
  );
}
