import { useMemo } from "react";
import { Control, Controller } from "react-hook-form";
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Switch, Typography } from "@mui/material";

import { NVCPInstrument } from "@/interfaces";
import { formatLocalDate } from "@/utils";

interface NVCPInstrumentDropdownProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Disabled since this is the library type.
  control: Control<any>;
  label: string;
  options: NVCPInstrument[];
  onChangeHandler: (value: string) => void;
  "data-testid"?: string;
  readOnly?: boolean;
  isLoading?: boolean;
}

export function NVCPInstrumentDropdown({
  name,
  control,
  label,
  options,
  onChangeHandler,
  "data-testid": dataTestId,
  readOnly,
  isLoading
}: NVCPInstrumentDropdownProps) {
  return (
    <FormControl fullWidth data-testid={dataTestId}>
      <InputLabel id="clearing-instrument-label" data-testid={`${dataTestId}-label`}>
        {label}
      </InputLabel>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <NVCPInstrumentSelect
            label={label}
            value={value}
            options={options}
            onChange={(value) => {
              onChange(value);
              onChangeHandler(value);
            }}
            data-testid={dataTestId}
            readOnly={readOnly}
            isLoading={isLoading ?? false}
          />
        )}
        name={name}
      />
    </FormControl>
  );
}

interface NVCPInstrumentSelectProps {
  label: string;
  value: string;
  options: NVCPInstrument[];
  onChange: (value: string) => void;
  "data-testid"?: string;
  readOnly?: boolean;
  isLoading: boolean;
}

function NVCPInstrumentSelect({
  label,
  value,
  options,
  onChange,
  "data-testid": dataTestId,
  readOnly,
  isLoading
}: NVCPInstrumentSelectProps) {
  const instrumentsById = useMemo(
    () =>
      options.reduce((acc: Record<string, NVCPInstrument>, curr) => {
        acc[curr.referenceNo] = curr;
        return acc;
      }, {}),
    [options]
  );

  return (
    <Select
      label={label}
      value={value}
      readOnly={readOnly}
      renderValue={(value) => {
        return <Typography>{instrumentsById[value]?.referenceNo ?? ""}</Typography>;
      }}
      data-testid={`${dataTestId}-select`}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        sx: {
          p: 0
        },
        PaperProps: {
          sx: {
            maxHeight: "15rem",
            maxWidth: "40rem",
            padding: 0
          }
        }
      }}
    >
      <Grid container rowGap={1}>
        <Grid item container xs={12} px={1} py={1} justifyContent="center" alignItems="center">
          <Switch onClick={(e) => e.stopPropagation()} />
          <Typography>Only instruments that intersect with request boundaries</Typography>
        </Grid>
        <Grid item container xs={12} px={1}>
          <Grid item xs={3} px={0.5}>
            <Typography fontWeight="bold">Instrument</Typography>
          </Grid>
          <Grid item xs={3} px={0.5}>
            <Typography fontWeight="bold">Purpose</Typography>
          </Grid>
          <Grid item xs={3} px={0.5}>
            <Typography fontWeight="bold">Expiry date</Typography>
          </Grid>
          <Grid item xs={3} px={0.5}>
            <Typography fontWeight="bold" textAlign="right">
              Available balance
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} px={1}>
          <Divider />
        </Grid>
        <Grid item container xs={12}>
          {isLoading ? (
            <>
              <MenuItem
                sx={{ width: "100%" }}
                onClick={(e) => e.stopPropagation()}
                data-testid={`nvcp-instrument-select-option-is-loading`}
              >
                <Grid item container xs={12}>
                  <Typography>Fetching records</Typography>
                  <Skeleton sx={{ width: "100%" }} />
                </Grid>
              </MenuItem>
            </>
          ) : (
            options.map((option) => (
              <MenuItem
                key={option.referenceNo}
                sx={{ width: "100%" }}
                onClick={() => onChange(option.referenceNo)}
                selected={option.referenceNo === value}
                data-testid={`nvcp-instrument-select-option-${option.referenceNo}`}
              >
                <Grid item container xs={12}>
                  <Grid xs={3} px={0.5}>
                    <Typography overflow="hidden" textOverflow="ellipsis" title={option.referenceNo}>
                      {option.referenceNo}
                    </Typography>
                  </Grid>
                  <Grid xs={3} px={0.5}>
                    <Typography overflow="hidden" textOverflow="ellipsis" title={option.purpose}>
                      {option.purpose}
                    </Typography>
                  </Grid>
                  <Grid xs={3} px={0.5}>
                    <Typography overflow="hidden" textOverflow="ellipsis" title={formatLocalDate(option.expiry)}>
                      {formatLocalDate(option.expiry)}
                    </Typography>
                  </Grid>
                  <Grid xs={3} px={0.5}>
                    <Typography
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="right"
                      title={`${option.availableBalance} ha`}
                    >
                      {`${option.availableBalance} ha`}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))
          )}
        </Grid>
      </Grid>
    </Select>
  );
}
