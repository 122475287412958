import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { Dialog } from "@/components/dialogs";
import { AppFormSelect } from "@/components/fields";
import { AmendPermitFormFields, Cycle } from "@/interfaces";
import { Controller, useForm } from "react-hook-form";
import { useMemo } from "react";
import { toKebabCase } from "@/utils";
import { AR_FORM, BUTTON_LABELS, EXTEND_BY_OPTIONS } from "@/constants";
import { useAmendPermit } from "@/hooks/api/ApprovalRequestHooks";
import { useSetAtom } from "jotai";
import { snackBarAtom } from "@/stores";
import { colours } from "@/theme/colour";

interface AmendARFormProps {
  approvalRequestId: string;
  isAmend: boolean;
  dismissModal: () => void;
}

export function AmendPermitForm({ approvalRequestId, isAmend, dismissModal }: AmendARFormProps) {
  const { control, reset, watch, getValues, resetField } = useForm<AmendPermitFormFields>();
  const { mutate: amendPermit } = useAmendPermit();
  const setSnackBar = useSetAtom(snackBarAtom);
  const amendPermitFormValues = watch(["cycle", "extendBy"]);

  const [cycle, extendBy] = amendPermitFormValues;
  const cycleEnum = Number(cycle);

  const isAmendButtonDisabled = useMemo(() => {
    if (cycleEnum === Cycle.Amendment || (cycleEnum === Cycle.Extension && extendBy !== undefined)) {
      return false;
    }
    return true;
  }, [cycleEnum, extendBy]);

  const handleDialogClose = () => {
    dismissModal();
    reset();
  };

  const handleAmendAR = () => {
    const formValues = getValues();
    amendPermit(
      {
        approvalRequestId: approvalRequestId,
        cycle: formValues.cycle,
        extendBy: formValues.extendBy
      },
      {
        onSuccess: () => {
          setSnackBar({
            message: AR_FORM.MODIFY_AR_SUCCESSFUL,
            open: true
          });
          handleDialogClose();
        }
      }
    );
  };

  return (
    <Dialog
      open={isAmend}
      onClose={() => {
        dismissModal();
        reset();
      }}
      title="Amend this Request?"
      actions={[
        {
          label: BUTTON_LABELS.CANCEL,
          onClick: () => {
            dismissModal();
            reset();
          }
        },
        {
          label: BUTTON_LABELS.AMEND,
          disabled: isAmendButtonDisabled,
          onClick: () => {
            handleAmendAR();
          }
        }
      ]}
    >
      <Stack alignContent="center">
        <FormControl>
          <FormLabel
            sx={{ color: colours.dialog.subtextColour, "&.Mui-focused": { color: colours.dialog.subtextColour } }}
          >
            Reason for amendment
          </FormLabel>
          <Controller
            name="cycle"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <FormControlLabel
                  value={Cycle.Amendment}
                  control={<Radio />}
                  label="Changing the scope"
                  data-testid={`radio-${toKebabCase("change scope")}`}
                  onChange={() => resetField("extendBy")}
                  key={Cycle.Amendment}
                  sx={{ m: 0 }}
                />
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <FormControlLabel
                    value={Cycle.Extension}
                    control={<Radio />}
                    label="Extending permit expiry date"
                    data-testid={`radio-${toKebabCase("extend")}`}
                    key={Cycle.Extension}
                    sx={{ m: 0 }}
                  />
                  <AppFormSelect
                    disabled={!(cycleEnum === Cycle.Extension)}
                    control={control}
                    label={"Extend By"}
                    name={"extendBy"}
                    isOptional={true}
                    options={EXTEND_BY_OPTIONS}
                    margin="none"
                    size="small"
                    data-testid="extendby-dropdown"
                    sx={{ flex: 1 }}
                    hideHelperText
                  />
                </Stack>
              </RadioGroup>
            )}
          ></Controller>
        </FormControl>
      </Stack>
    </Dialog>
  );
}
